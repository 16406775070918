import { yup } from '@driscollsinc/style-guide-web2.0';

export const UserManagementSchema = yup.object().shape({
    Warehouse: yup.array().min(1, 'Warehouse is required!'),
    Email: yup
        .string()
        .trim()
        .email('Please enter a valid email!')
        .required('Email is required!')
        .matches(/^[a-zA-Z0-9](?!.*[._-]{2})[a-zA-Z0-9._-]*@driscolls\.com$/, 'Please enter a valid Driscolls Email')
});
