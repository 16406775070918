import { useState } from 'react';
import ApplicationDataContext from './ApplicationDataContext';
import { initialBypassQuartileData } from '../pages/Bypass/AddBypassQuartile';
import { initialBypassOverrideData } from '../pages/Bypass/AddBypassOverride';
import { BYPASS_LOCATION_CONSTANTS } from '../constants/bypassConstants';

const ApplicationDataProvider = (props: any) => {
    const [bypassQuartileData, setBypassQuartileData] = useState(initialBypassQuartileData);
    const [selectedBerryType, setSelectedBerryType] = useState(BYPASS_LOCATION_CONSTANTS.BERRY_TYPE_BLACK);
    const [userEmail, setUserEmail] = useState('');
    const [bypassOverrideData, setBypassOverrideData] = useState(initialBypassOverrideData);

    return (
        <ApplicationDataContext.Provider
            value={{
                bypassQuartileData,
                setBypassQuartileData,
                selectedBerryType,
                setSelectedBerryType,
                userEmail,
                setUserEmail,
                bypassOverrideData,
                setBypassOverrideData
            }}
        >
            {props.children}
        </ApplicationDataContext.Provider>
    );
};

export default ApplicationDataProvider;
