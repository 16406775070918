import { BYPASS_LOCATION_CONSTANTS } from '../constants/bypassConstants';
import { BYPASS_QUARTILE_CONSTANTS } from '../constants/bypassQuartile';
import { useGetStaticData } from './useGetStaticData';

const useGetPayloadForBypassQuartile = () => {
    const { getVarietyListFilteredByCommodity, getEstimateGroupList } = useGetStaticData();

    const createPayload = (data, user, currentDate) => {
        const { WarehouseNbr, BerryType, Percentage, Variety, EstimateGroup } = data;
        const varietyList = getVarietyListFilteredByCommodity(BerryType, true).filter((item) => item.VarietyCode !== BYPASS_QUARTILE_CONSTANTS.ALL);
        const estimateGroupList = getEstimateGroupList();
        const isAllVarieties = Variety === BYPASS_QUARTILE_CONSTANTS.ALL;
        const isAllEstimateGroups = EstimateGroup === BYPASS_QUARTILE_CONSTANTS.ALL;
        const shouldBypass = Percentage.toUpperCase() !== BYPASS_QUARTILE_CONSTANTS.NONE;
        const inspections = [];
        // Helper function to create an inspection object
        const createInspection = (variety, estimateGroup) => ({
            [BYPASS_QUARTILE_CONSTANTS.BERRY_TYPE]: BerryType,
            [BYPASS_QUARTILE_CONSTANTS.WAREHOUSE_NBR]: WarehouseNbr,
            [BYPASS_QUARTILE_CONSTANTS.ESTIMATED_GROUP]: estimateGroup,
            [BYPASS_QUARTILE_CONSTANTS.VARIETY]: variety,
            [BYPASS_LOCATION_CONSTANTS.BYPASS]: shouldBypass,
            [BYPASS_LOCATION_CONSTANTS.PERCENTAGE]: Percentage,
            [BYPASS_LOCATION_CONSTANTS.BUSINESS_UNIT]: BYPASS_LOCATION_CONSTANTS.DEMEA
        });

        if (isAllVarieties && isAllEstimateGroups) {
            varietyList.forEach((variety) => {
                estimateGroupList.forEach((estimateGroup) => {
                    inspections.push(createInspection(variety.VarietyCode, estimateGroup));
                });
            });
        } else if (isAllVarieties) {
            varietyList.forEach((variety) => {
                inspections.push(createInspection(variety.VarietyCode, EstimateGroup));
            });
        } else if (isAllEstimateGroups) {
            estimateGroupList.forEach((estimateGroup) => {
                inspections.push(createInspection(Variety, estimateGroup));
            });
        } else {
            inspections.push(createInspection(Variety, EstimateGroup));
        }

        return {
            [BYPASS_LOCATION_CONSTANTS.INSPECTIONS]: inspections,
            [BYPASS_LOCATION_CONSTANTS.LAST_UPDATED_BY]: user,
            [BYPASS_LOCATION_CONSTANTS.LAST_UPDATED_DATE]: currentDate.toISOString()
        };
    };

    return { createPayload };
};

export default useGetPayloadForBypassQuartile;
