import { useAuth } from "@driscollsinc/login-module-ui";
import { isDemeaAdminFn, isDemeaUserFn, isDotaUserFn, isDemeaManagerFn } from "../../utils/checkUserGroups";

const useCheckUserGroup = () => {
    const { authState } = useAuth();
    const accessToken = authState?.accessToken?.accessToken || '';


    const isOnlyDemeaUser = () => isDemeaUserFn(accessToken) && !isDotaUserFn(accessToken);
    const isDemeaAdminUser = () => isDemeaAdminFn(accessToken);
    const isDemeaManagerUser = () => isDemeaManagerFn(accessToken);

    return {
        isDemeaAdminUser,
        isOnlyDemeaUser,
        isDemeaManagerUser
    }
}

export default useCheckUserGroup;