import { FC, memo } from 'react';
import { DriscollsSelect, DriscollsTranslatedValue } from '@driscollsinc/style-guide-web2.0';
import { convertToLabelValue } from '../../../../utils/format';
import { NAMESPACE } from '../../../../i18n';
import { useGetStaticData } from '../../../hooks/useGetStaticData';
interface EstimateGroupSelectProps {
    styles: any;
    onChange?: (selectedOption: any) => void;
    error?: any;
    defaultValue?: any;
    isDisabled?: boolean;
    withAllOption?: boolean;
}

const EstimateGroupSelect: FC<EstimateGroupSelectProps> = memo(function EstimateGroupSelect({ styles, isDisabled, withAllOption, ...rest }) {
    const { getEstimateGroupList } = useGetStaticData();
    const estimateGroup = getEstimateGroupList();
    if (withAllOption) {
        estimateGroup.unshift('All');
    }

    return (
        <DriscollsSelect
            name="estimateGroup"
            className={styles.selectField}
            containerClassName={styles.selectContainer}
            options={convertToLabelValue(estimateGroup)}
            placeholder={<DriscollsTranslatedValue nameSpacing={NAMESPACE.POINT_MATRIX} value="Growing Method" />}
            isDisabled={isDisabled}
            {...rest}
        />
    );
});

export default EstimateGroupSelect;
