type oktaGroup = string;



export const getAdminGroups = (): oktaGroup[] => JSON.parse(window.config.OKTA_ADMIN_GROUPS) || [];
export const getManagerGroups = (): oktaGroup[] => JSON.parse(window.config.OKTA_REGULAR_GROUPS) || [];
export const getAnalystGroups = (): oktaGroup[] => JSON.parse(window.config.OKTA_READ_ONLY_GROUPS) || [];
export const getDemeaAdminGroups = (): oktaGroup[] => JSON.parse(window.config.OKTA_DEMEA_ADMIN_GROUPS) || [];
export const getDemeaManagerGroups = (): oktaGroup[] => JSON.parse(window.config.OKTA_DEMEA_REGULAR_GROUPS) || [];

// All demea groups - currently there are only two Demea Admin and Demea Manager
export const getDemeaGroups = (): oktaGroup[] => [...getDemeaAdminGroups()].concat(getDemeaManagerGroups());

export const getManagerGroupsWithAdmin = (): oktaGroup[] => [...getAdminGroups()].concat(getManagerGroups());
export const getAnalystGroupsWithAdmin = (): oktaGroup[] => [...getAdminGroups()].concat(getAnalystGroups());

// Admins with demea groups
export const getDemeaGroupsWithAdmin = (): oktaGroup[] => [...getAdminGroups()].concat(getDemeaGroups());

export const getAllDotaGroups = (): oktaGroup[] => [...getAdminGroups()].concat(getManagerGroups(), getAnalystGroups());
