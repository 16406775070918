import { Helmet } from 'react-helmet';
import { withStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import DrcMain from '../Components/DrcMain';
import DrcPanel from '../Components/DrcPanel';
import Warning from '@material-ui/icons/Warning';

const styles = (theme) => ({
    heading: {
        display: 'inline-block',
        margin: '20px 20px 0 20px',
        position: 'relative',
        top: '-15px'
    },
    icon: {
        fontSize: '4rem'
    }
});


const DrcPageNotAuthorized = ({ logOutUrl = '/LogOut/', logInUrl = '/', classes, authState }) => {
    return (
        <DrcMain transparent>
            <Helmet>
                <title>Not Authorized</title>
            </Helmet>
            <DrcPanel error maxWidth="500px" style={{ textAlign: 'center' }}>
                <Warning className={classes.icon} />
                <h1 className={classes.heading}>You are not authorized!</h1>
                <Warning className={classes.icon} />
                <br />
                {authState?.isAuthenticated ? (
                    <p>
                        Please <NavLink to={logOutUrl}>Sign-Out</NavLink> and try with better credentials.
                    </p>
                ) : (
                    <p>
                        Please go back to the <NavLink to={logInUrl}>Sign-In</NavLink> page and try again.
                    </p>
                )}
            </DrcPanel>
        </DrcMain>
    );
};

export default withStyles(styles)(DrcPageNotAuthorized);
