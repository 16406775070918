import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import { DrcPageDataMaintenance, DrcPanel, DrcSelect, DrcButton, DrcDialog, DrcTooltip } from 'driscolls-react-components';
import DrcMultiSelect from '../../components/DrcMultiSelect';
import { Middleware } from 'one-ring';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setProducingAreaRegionData, setUserProducingArea, setMLookUpData } from '../../actions/ProducinAreaAction';
import { PRODUCING_AREA, PRODUCING_AREA_PLACEHOLDER, PRODUCINGAREAREGIONMAPPING_DELETE } from '../../language/english';
import APIEndPoints from '../../services/api';
import { DuDateUtilities } from 'driscolls-react-utilities';
import { getProducingAreaRegionPayload, getProducingAreaRegionEditPayload } from '../../util/utils';
import { withAuth } from '@driscollsinc/login-module-ui';
import { EventConstants, logEvent } from '../../../utils/analytics';

const PAGE_TITLE = 'Producing Area Region';
const PAGE_TYPE = 'ProducingAreaRegion';
let styles = (theme) => ({
    main: {
        margin: 0,
        paddingBottom: '0px !important'
    },
    grid: {
        '& .selected > div': {
            backgroundColor: '#cfc3deba !important'
        },
        '& .nonSelected > div': {
            backgroundColor: '#d9d0e4 !important'
        },
        '& .react-grid-Toolbar .btn:after': {
            background: 'none !important',
            backgroundColor: '#6f5091 !important',
            height: '27px',
            color: 'white'
        },
        '& .react-grid-Header': {
            background: 'none !important',
            backgroundColor: '#6f5091 !important'
        }
    },
    success: {
        color: theme.palette.primary.main
    },
    gridContainer: {
        paddingLeft: '24px'
    },
    layout: {
        width: '97%'
    },
    dialog: {
        '& .MuiDialog-paperWidthSm': {
            minWidth: '600px',
            width: '100%'
        },
        '& .p-datatable-thead': {
            background: 'none !important',
            backgroundColor: '#6f5091 !important',
            color: '#fff'
        },
        '& .p-datatable-row': {
            fontWeight: 600
        },
        '& .p-datatable-scrollable-wrapper': {
            maxHeight: 600
        }
    }
});
const ProducingAreaRegion = (props) => {
    const [producingAreaFilter, setProducingAreaFilter] = useState([]);
    const [isValid, setIsValid] = useState(false);
    const [AddMode, setAddMode] = useState(false);
    const [producingAreaOption, setProducingAreaByUser] = useState([]);
    const [regionOptions, setRegionOptions] = useState([]);
    const [messageDialog, setMessageDialog] = useState({
        showMessage: false,
        message: '',
        severity: 'success'
    });
    const [formattedData, setFormattedData] = useState([]);
    const [region, setRegion] = useState([]);
    const [ProducingAreaDialog, setProducingAreaDialog] = useState([]);
    const [regionValue, setRegionValue] = useState([]);

    // const dateTimeFormatter = (value) => {
    //     return <span>{value ? DuDateUtilities.ToPrettyDateTime(value) : ''}</span>;
    // };
    const dateTimeFormatter = (value, row) => {
        getColumn(value, row);
        return <span>{value ? DuDateUtilities.ToPrettyDateTime(value) : ''}</span>;
    };
    const columns = [
        {
            key: 'Region',
            name: 'Region',
            addDisabled: false,
            isRequired: true,
            editDisabled: false,
            inputType: 'Select',
            selectOptions: regionOptions,
            columnTemplate: (row) => row.Region
        },
        {
            key: 'ProducingAreaCode',
            name: 'Producing Area',
            editDisabled: false,
            addDisabled: false,
            isRequired: true,
            inputType: 'Select',
            selectOptions: producingAreaOption,
            width: '225px',
            columnTemplate: (row) => row.ProducingAreaCode
        },
        {
            key: 'ModifiedBy',
            name: 'Modified By',
            isHidden: true,
            isDisabled: true
        },
        {
            key: 'ModifiedDateTime',
            name: 'Modified Date Time',
            isDisabled: true,
            isHidden: true,
            columnTemplate: (row) => dateTimeFormatter(row.ModifiedDateTime, row)
        },
        {
            key: 'CreatedBy',
            name: 'Created By',
            isHidden: true,
            isDisabled: true,
            columnTemplate: (row) => row.CreatedBy
        },
        {
            key: 'CreatedDateTime',
            name: 'Created Date',
            isHidden: true,
            isDisabled: true,
            columnTemplate: (row) => dateTimeFormatter(row.CreatedDateTime, row)
        }
    ];
    const getColumn = (value, row) => {
        for (let key in row) {
            if (row[key] === value) {
                return key;
            }
        }
    };
    useEffect(() => {
        logEvent(EventConstants.PAGE_VIEW, {page_title: `${EventConstants.DOTA} - ${EventConstants.PRODUCING_AREA_REGION}`})
        getProducingArea((data) => {
            if (data) {
                let procuingArea = data.map((item) => {
                    return { value: item.ProducingArea, label: item.ProducingAreaCode };
                });
                setProducingAreaByUser(
                    procuingArea.sort(function (a, b) {
                        return Number(a.value - b.value);
                    })
                );
            } else {
                setProducingAreaByUser([]);
            }
        });
        getData();
    }, []);

    const getMLookupData = () => {
        props.auth.getAccessToken().then((token) => {
            Middleware.Send(PAGE_TYPE, token, APIEndPoints.GET_M_LOOKUP('REGION'), 'GET', { Type: PAGE_TYPE }, { loadingMessage: '' })
                .then((data) => {
                    let regionSort = data.map((item) => {
                        return { value: item.LookupCode, label: item.Description };
                    });
                    setRegionOptions(
                        regionSort.sort(function (a, b) {
                            return Number(a.value - b.value);
                        })
                    );
                })
                .catch((error) => {
                    let message = 'Something happened, but I am not sure if it is good or bad!';
                    setRegionOptions([]);
                    setMessageDialog({
                        showMessage: true,
                        message: message,
                        severity: 'error'
                    });
                });
        });
    };
    useEffect(() => {
        const formatData = (items) => {
            let data = [];
            data = items.map((item) => {
                item.ProducingAreaCode = item.ProducingAreaCode + '-' + item.ProducingAreaName;
                return item;
            });
            setFormattedData(data);
        };
        formatData(props.producinaAreaRegionList);
    }, [props.producinaAreaRegionList]);
    useEffect(() => {
        getMLookupData();
    }, []);
    useEffect(() => {
        let prodArea = null;
        let selectedProducingAreas = '';
        producingAreaFilter.map((item) => {
            selectedProducingAreas = selectedProducingAreas + item.value + ',';
            return selectedProducingAreas;
        });
        prodArea = selectedProducingAreas.substring(0, selectedProducingAreas.length - 1);
        getData(prodArea, region ? region.value : null);
    }, [region, producingAreaFilter]);

    const getProducingArea = (callback) => {
        props.auth.getAccessToken().then((token) => {
            Middleware.Send(
                'managerMaintenance',
                token,
                APIEndPoints.GET_USER_PRODUCING_AREAS(props.loggedInUser),
                'GET',
                { Type: 'managerMaintenance' },
                { loadingMessage: '' }
            )
                .then((data) => {
                    if (data) {
                        props.setUserProducingArea(data);
                        callback(data);
                    } else {
                        props.setUserProducingArea([]);
                        callback([]);
                    }
                })
                .catch((error) => {
                    let message = 'Something happened, but I am not sure if it is good or bad!';
                    callback([]);
                    setMessageDialog({
                        showMessage: true,
                        message: message,
                        severity: 'error'
                    });
                });
        });
    };
    const getData = (producingAreaCode, region) => {
        props.auth.getAccessToken().then((token) => {
            Middleware.Send(
                PAGE_TYPE,
                token,
                APIEndPoints.GET_PRODUCING_AREA_REGION(producingAreaCode, region),
                'GET',
                { Type: PAGE_TYPE },
                { loadingMessage: 'Fetching Producing Area Region...' }
            )
                .then((data) => {
                    if (data) {
                        props.setProducingAreaRegionData(data);
                    } else {
                        props.setProducingAreaRegionData([]);
                    }
                })
                .catch((error) => {
                    let message = 'Something happened, but I am not sure if it is good or bad!';

                    props.setProducingAreaRegionData([]);
                    setMessageDialog({
                        showMessage: true,
                        message: message,
                        severity: 'error'
                    });
                });
        });
    };

    const handleClose = (_event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMessageDialog({ showMessage: false });
    };

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleOnChangeOfProducingArea = (option) => {
        if (option === null) {
            setProducingAreaFilter([]);
        } else {
            setProducingAreaFilter(option);
        }
    };

    const handleRegionChangeFilter = (option) => {
        if (option === null) {
            setRegion([]);
        } else {
            setRegion(option);
        }
    };

    const onCancel = () => {
        setAddMode(false);
        setProducingAreaDialog([]);
        setRegionValue([]);
        setProducingAreaFilter([]);
        setRegion([]);
        setIsValid(false);
    };

    const handleProducingAreaDialogChange = (option) => {
        if (option === null) {
            setProducingAreaDialog([]);
        } else {
            setProducingAreaDialog(option);
        }
    };
    const handleRegionChange = (option) => {
        if (option === null) {
            setRegionValue([]);
        } else {
            setRegionValue(option);
        }
    };

    const onAdd = () => {
        logEvent(EventConstants.ADD_ACTION, {event_info: `${EventConstants.DOTA} - ${EventConstants.ADD} ${EventConstants.PRODUCING_AREA_REGION}`});
        props.auth.getAccessToken().then((token) => {
            let apiCalls = [];
            ProducingAreaDialog.forEach((producingArea) => {
                let middlewareCall = Middleware.CreateSendCall(
                    PAGE_TYPE,
                    token,
                    `${APIEndPoints.POST_PRODUCING_AREA_REGION}`,
                    'POST',
                    getProducingAreaRegionPayload(regionValue, producingArea, props.loggedInUser),
                    {
                        overrideRequestMapping: true,
                        loadingMessage: 'Adding new Producing area region...',
                        overrideResponseMapping: true
                    }
                );

                apiCalls.push(
                    Middleware.AttachReturnFunction(middlewareCall, 0, () => {
                        console.log('done');
                    })
                );
            });

            Middleware.SendMultiple(PAGE_TYPE, apiCalls, () => {})
                .then((res) => {
                    setMessageDialog({
                        showMessage: true,
                        message: 'Producing area region added successfully!',
                        severity: 'success'
                    });
                    getData();
                })
                .catch((error) => {
                    let message = error.ErrorMessage || 'Something happened, but I am not sure if it is good or bad!';
                    setMessageDialog({
                        showMessage: true,
                        message: message,
                        severity: 'error'
                    });
                });
        });
        onCancel();
    };

    const onEdit = (oldValue, editedValue) => {
        logEvent(EventConstants.EDIT_ACTION, {event_info: `${EventConstants.DOTA} - ${EventConstants.EDIT} ${EventConstants.PRODUCING_AREA_REGION}`});
        let payload = getProducingAreaRegionEditPayload(oldValue, editedValue, props.loggedInUser);
        props.auth.getAccessToken().then((token) => {
            Middleware.Send(PAGE_TYPE, token, APIEndPoints.POST_PRODUCING_AREA_REGION, 'POST', payload, {
                overrideRequestMapping: true,
                loadingMessage: 'Updating producing area region...'
            })
                .then((_res) => {
                    getData(producingAreaFilter.value, region ? region.value : null);
                })
                .catch((error) => {
                    let message = 'Something happened. but I am not sure if it is good or bad!';
                    setMessageDialog({
                        showMessage: true,
                        message: message,
                        type: '',
                        severity: 'error'
                    });
                });
        });
        onCancel();
    };

    const onDelete = (deleteVal) => {
        logEvent(EventConstants.DELETE_ACTION, {event_info: `${EventConstants.DOTA} - ${EventConstants.DELETE} ${EventConstants.PRODUCING_AREA_REGION}`});
        let payload = {
            ProducingAreaRegionId: deleteVal.ProducingAreaRegionId
        };
        props.auth.getAccessToken().then((token) => {
            Middleware.Send(PAGE_TYPE, token, APIEndPoints.POST_PRODUCING_AREA_REGION, 'Delete', payload, {
                overrideRequestMapping: true,
                loadingMessage: 'Deleting Producing Area Mapping...'
            })
                .then((_res) => {
                    let updatedData = props.producinaAreaRegionList.filter((item) => item.ProducingAreaRegionId !== deleteVal.ProducingAreaRegionId);
                    props.setProducingAreaRegionData(updatedData);
                    setMessageDialog({
                        showMessage: true,
                        message: PRODUCINGAREAREGIONMAPPING_DELETE,
                        type: '',
                        severity: 'success'
                    });
                    getData();
                    setRegion([]);
                })
                .catch((error) => {
                    let message = error.ErrorMessage || 'Something happened. but I am not sure if it is good or bad!';
                    setMessageDialog({
                        showMessage: true,
                        message: message,
                        type: '',
                        severity: 'error'
                    });
                });
        });
    };
    const { classes } = props;

    return (
        <DrcPanel
            style={{ maxWidth: '100%', border: 'none', boxShadow: '0 2px 4px 0 #C3C3C3', marginBottom: '0px' }}
            className={props.classes.layout}
        >
            <Grid container spacing={1} className={classes.gridContainer}>
                <Grid item xs={6} sm={5} md={4} lg={3}>
                    <DrcSelect
                        label="Region"
                        options={regionOptions}
                        isClearable={true}
                        value={region}
                        placeholder="Please select Region"
                        onChange={(e) => handleRegionChangeFilter(e)}
                    />
                </Grid>
                <Grid item xs={6} sm={5} md={4} lg={3}>
                    <DrcMultiSelect
                        options={producingAreaOption}
                        label={PRODUCING_AREA}
                        placeholder={PRODUCING_AREA_PLACEHOLDER}
                        isMulti={true}
                        onChange={handleOnChangeOfProducingArea}
                        name="producingArea"
                    ></DrcMultiSelect>
                </Grid>
            </Grid>

            <DrcPageDataMaintenance
                columns={columns}
                className={classes.dialog}
                data={formattedData}
                onEdit={onEdit}
                onDelete={onDelete}
                type={PAGE_TITLE}
                settings={{
                    EnableExport: false,
                    EnableAdd: true,
                    EnableDelete: true,
                    EnableEdit: true,
                    OverrideAdd: () => {
                        setAddMode(true);
                    }
                }}
                textOptions={{
                    AddBtn: 'Add New'
                }}
                resultCount={props.producinaAreaRegionList.length}
            />

            <DrcDialog
                open={AddMode}
                title={'Add: Producing Area Region'}
                buttons={
                    <div>
                        <DrcButton isSecondary onClick={onCancel} floatRight>
                            Cancel
                        </DrcButton>
                        <DrcButton isPrimary floatRight onClick={onAdd} disabled={!regionValue.value || ProducingAreaDialog.length === 0}>
                            Save
                        </DrcButton>
                    </div>
                }
            >
                <hr />
                <div className="row" style={{ margin: '1rem', padding: ' 0 .5rem' }}>
                    <div style={{ minWidth: '20rem', padding: '0 .5rem' }}>
                        <DrcSelect
                            label="Region"
                            options={regionOptions}
                            value={regionValue}
                            placeholder="Please select Region"
                            onChange={(e) => handleRegionChange(e)}
                        />
                    </div>
                    <div style={{ minWidth: '20rem', padding: '0 .5rem' }}>
                        <DrcMultiSelect
                            options={producingAreaOption}
                            label="Producing Area"
                            placeholder={PRODUCING_AREA_PLACEHOLDER}
                            isMulti={true}
                            onChange={handleProducingAreaDialogChange}
                            name="producingArea"
                        ></DrcMultiSelect>
                    </div>
                </div>
            </DrcDialog>

            <Snackbar open={messageDialog.showMessage} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={messageDialog.severity}>
                    {messageDialog.message}
                </Alert>
            </Snackbar>
        </DrcPanel>
    );
};

function mapStateToProps(state) {
    return {
        userGroup: state.masterReducer.userGroup,
        loggedInUser: state.masterReducer.loggedInUser,
        producinaAreaRegionList: state.ProducingAreaRegionReducer.producinaAreaRegionList,
        userProducingArea: state.ProducingAreaRegionReducer.userProducingArea
    };
}

export default connect(mapStateToProps, { setProducingAreaRegionData, setUserProducingArea, setMLookUpData })(
    withAuth(withStyles(styles)(ProducingAreaRegion))
);
