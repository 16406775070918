import { POINT_MATRIX_SET_CONSTANTS } from './constants/pointMatrixConstants';

export const urls = {
    POINT_MATRIX: 'pointmatrix',
    ADD_USER_MANAGEMENT: 'users',
    WAREHOUSES: 'master/demea/warehouses',
    BYPASS_LOCATION: 'inspectionbypass/quartile',
    RANCHES: 'master/demea/ranches',
    BYPASS_OVERRIDE: 'ranch/override'
};

class APIEndPoints {
    static POINT_MATRIX_POST = () => {
        return `/${urls.POINT_MATRIX}`;
    };

    static POINT_MATRIX_GET = (
        page: number,
        size: number,
        districtGroup?: string,
        commodityCode?: string,
        estimateGroup?: string,
        variety?: string,
        attributeType?: string,
        status?: string,
        modifiedBy?: string
    ) => {
        const baseUrl = `/${urls.POINT_MATRIX}`;
        const params = new URLSearchParams({
            page: page.toString(),
            size: size.toString(),
            businessunit: 'DEMEA'
        });

        if (districtGroup) {
            params.append('districtgroup', districtGroup);
        }

        if (commodityCode) {
            params.append('commoditycode', commodityCode);
        }

        if (estimateGroup) {
            params.append('estimategroup', estimateGroup);
        }

        if (attributeType) {
            params.append('attributetype', attributeType);
        }

        if (status) {
            params.append('status', status);
        } else {
            params.append('status', POINT_MATRIX_SET_CONSTANTS.ALL);
        }

        if (variety) {
            params.append('varietycode', variety);
        }

        if (modifiedBy) {
            params.append('modifiedby', modifiedBy);
        }

        return `${baseUrl}?${params.toString()}`;
    };

    static USER_MANAGEMENT_GET = (page?: number, size?: number, warehouse?: string, email?: string, createdby?: string, createddate?: string) => {
        const baseUrl = `/users`;
        const params = new URLSearchParams({
            page: page ? page.toString() : '1',
            size: size ? size.toString() : '50'
        });

        if (warehouse) {
            params.append('warehouse', warehouse);
        }

        if (email) {
            params.append('email', email);
        }

        if (createdby) {
            params.append('createdby', createdby);
        }

        if (createddate) {
            params.append('createddate', createddate);
        }

        return `${baseUrl}?${params.toString()}`;
    };

    static USER_MANAGEMENT_POST = () => {
        return `/${urls.ADD_USER_MANAGEMENT}`;
    };

    static GET_WAREHOUSES = () => {
        return `/${urls.WAREHOUSES}`;
    };

    static GET_RANCHES = (warehousenbr?: string, ranchnbr?: string, size?: number, page?: number) => {
        const params = new URLSearchParams({
            warehousenbr: warehousenbr ?? '',
            ranchnbr: ranchnbr ?? '',
            size: '5000',
            page: '1'
        });
        return `${urls.RANCHES}?${params.toString()}`;
    };

    static BYPASS_LOCATION_POST = () => {
        return `/${urls.BYPASS_LOCATION}`;
    };

    static BYPASS_LOCATION_GET = (warehousenbr: string, berrytype: string, percentage: string) => {
        const baseUrl = urls.BYPASS_LOCATION;
        const params = new URLSearchParams({
            warehousenbr: warehousenbr,
            berrytype: berrytype,
            percentage: percentage,
            overallstatus: 'true'
        });
        return `${baseUrl}?${params.toString()}`;
    };

    static BYPASS_QUARTILE_GET = (
        warehousenbr: string,
        berrytype: string,
        estimategroup: string,
        variety: string,
        percentage: string,
        createdby: string,
        createdDate: string,
        size: number,
        page: number,
        overallstatus?: string
    ) => {
        const baseUrl = urls.BYPASS_LOCATION;
        const params = new URLSearchParams({
            warehousenbr: warehousenbr,
            berrytype: berrytype,
            size: size.toString(),
            page: page.toString()
        });

        if (estimategroup) {
            params.append('estimategroup', estimategroup);
        }
        if (variety) {
            params.append('variety', variety);
        }
        if (percentage) {
            params.append('percentage', percentage);
        }
        if (createdby) {
            params.append('createdby', createdby);
        }
        if (createdDate) {
            params.append('createddate', createdDate);
        }
        if (overallstatus) {
            params.append('overallstatus', overallstatus);
        }
        return `${baseUrl}?${params.toString()}`;
    };

    static BYPASS_OVERRIDE = (
        size?: number,
        page?: number,
        warehouse?: string,
        isdeleted?: string,
        ranchnumber?: string,
        modifiedby?: string,
        filterdate?: string
    ) => {
        const baseUrl = urls.BYPASS_OVERRIDE;
        const params = new URLSearchParams({
            size: size.toString(),
            page: page.toString(),
            businessunit: 'DEMEA'
        });

        if (warehouse) {
            params.append('warehousenbr', warehouse);
        }
        if (isdeleted) {
            params.append('isdeleted', isdeleted);
        }
        if (ranchnumber) {
            params.append('ranchnbr', ranchnumber);
        }
        if (modifiedby) {
            params.append('modifiedby', modifiedby);
        }
        if (filterdate) {
            params.append('filterdate', filterdate);
        }
        return `${baseUrl}?${params.toString()}`;
    };

    static BYPASS_OVERRIDE_POST = () => {
        return `/${urls.BYPASS_OVERRIDE}`;
    };
}

export default APIEndPoints;
