import { BYPASS_LOCATION_CONSTANTS } from '../demea/constants/bypassConstants';

export const convertLabelValueObj = <T extends string>(arr: T[], label: string, key: string) => {
    const formattedArr = arr.map((item) => ({
        label: item[label],
        value: item[key]
    }));

    return formattedArr;
};

export const convertToLabelValue = <T extends string>(arr: T[]): { label: T; value: T }[] => {
    const formattedArr = arr.map((item) => ({
        label: item,
        value: item
    }));

    return formattedArr;
};

export const mapOptions = (options, key) => options?.map((item) => item[key]) || [];

export const transformArrayToLabelValue = (arr, labelKey, valueKey) => {
    let formattedArr = [];
    if (arr) {
        formattedArr = arr.map((item) => ({
            label: item[labelKey],
            value: item[valueKey]
        }));
    }
    return formattedArr;
};

export const convertToStringArr = (arr, stringValue) => {
    if (!Array.isArray(arr)) {
        return [];
    }
    return arr.map((item) => item[stringValue]);
};

export const findSelectedWarehouses = (itemList1, itemList2) => {
    const selectedWarehouses = itemList1.filter((item) => item.Percentage === BYPASS_LOCATION_CONSTANTS.NONE);
    const selectedWarehouseSet = new Set(convertToStringArr(selectedWarehouses, BYPASS_LOCATION_CONSTANTS.WAREHOUSE_NBR));
    const checkedWarehouses = itemList2.filter((item) => selectedWarehouseSet.has(item.WarehouseNbr));
    return checkedWarehouses;
};

export const sortAndRemoveDuplicatesFromArray = (itemArray) => {
    const computedArray = itemArray.filter((item, index) => itemArray.indexOf(item) === index).sort();
    return computedArray;
};
