import { DriscollsImplicitCallback } from '@driscollsinc/login-module-ui';
import { routes, routesName } from '../../demea/routes/routes';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import styles from './QMPLoginCallback.module.scss';
import { DriscollsHContainer } from '@driscollsinc/style-guide-web2.0';
import { useLang } from '@driscollsinc/style-guide-web2.0';
import { NAMESPACE } from '../../i18n';

const QMPLoginCallback = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { getTextTranslated } = useLang([NAMESPACE.COMMON]);

    return (
        <DriscollsHContainer className={styles.callbackContainer}>
            <DriscollsImplicitCallback
                errorMessage={getTextTranslated(
                    'An unknown error has occurred, please try to log in again. If the issue persists contact the Admin',
                    NAMESPACE.COMMON
                )}
                redirectUrl={routes[routesName.HOME].path}
                className={styles.loginCallback}
                Navigate={Navigate}
                navigate={navigate}
                location={location}
            />
        </DriscollsHContainer>
    );
};

export default QMPLoginCallback;
