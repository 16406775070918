import { FC, memo } from 'react';
import { DriscollsSelect, DriscollsTranslatedValue } from '@driscollsinc/style-guide-web2.0';
import { convertToLabelValue } from '../../../../utils/format';
import { NAMESPACE } from '../../../../i18n';
import { useGetStaticData } from '../../../hooks/useGetStaticData';
interface BerryTypesSelectProps {
    styles: any;
    onChange?: (selectedOption: any) => void;
    error?: any;
    defaultValue?: any;
    isClearable?: boolean;
    isDisabled?: boolean;
    isCompact?: boolean;
}

const BerryTypesSelect: FC<BerryTypesSelectProps> = memo(function BerryTypesSelect({ styles, isClearable = true, isCompact, isDisabled, ...rest }) {
    const { getCommodityList } = useGetStaticData();
    const berryTypes = getCommodityList();

    return (
        <DriscollsSelect
            name="berryType"
            className={isCompact ? styles.compactSelectField : styles.selectField}
            containerClassName={styles.selectContainer}
            options={convertToLabelValue(berryTypes)}
            placeholder={<DriscollsTranslatedValue nameSpacing={NAMESPACE.POINT_MATRIX} value="Berry Type" />}
            isClearable={isClearable}
            isDisabled={isDisabled}
            {...rest}
        />
    );
});

export default BerryTypesSelect;
