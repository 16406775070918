import { createContext, Dispatch, SetStateAction } from 'react';
import { BypassLocationData, BypassOverrideData } from '../pages/Bypass/Types';
import { initialBypassQuartileData } from '../pages/Bypass/AddBypassQuartile';
import { initialBypassOverrideData } from '../pages/Bypass/AddBypassOverride';

export interface IAppDataContext {
    bypassQuartileData: BypassLocationData;
    setBypassQuartileData: Dispatch<SetStateAction<BypassLocationData | null>>;
    selectedBerryType: string;
    setSelectedBerryType: Dispatch<SetStateAction<string | null>>;
    bypassOverrideData: BypassOverrideData
    setBypassOverrideData: Dispatch<SetStateAction<BypassOverrideData | null>>;
    userEmail: string;
    setUserEmail: Dispatch<SetStateAction<string | ''>>;
}
const ApplicationDataContext = createContext<IAppDataContext>({
    bypassQuartileData: initialBypassQuartileData,
    setBypassQuartileData: null,
    selectedBerryType: '',
    setSelectedBerryType: null,
    bypassOverrideData: initialBypassOverrideData,
    setBypassOverrideData: null,
    userEmail: '',
    setUserEmail: null
});

export default ApplicationDataContext;
