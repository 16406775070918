import { DriscollsHContainer, DriscollsCard, DriscollsCheckbox, FaIcons, IconSize } from '@driscollsinc/style-guide-web2.0';
import styles from './WarehouseList.module.scss';
import { useHttp } from '../../hooks/useHttp';
import { WarehouseData, WarehouseResponse } from '../userManagement/warehouse/Types';
import APIEndPoints from '../../api';
import { useEffect, useState } from 'react';
import { convertToStringArr, findSelectedWarehouses } from '../../../utils/format';
import { BYPASS_LOCATION_CONSTANTS } from '../../constants/bypassConstants';
import { BypassLocationData } from '../../pages/Bypass/Types';
import { useApplicationContext } from '../../hooks/useApplicationContext';

interface WarehouseListProps {
    handleWarehouseChange: (selectedWarehouseData: WarehouseData[]) => void;
    warehouseSelectedListData: BypassLocationData[];
    isManager: boolean;
    loadingWarehouseSelectedListData: boolean;
}

const WarehouseList = ({ handleWarehouseChange, warehouseSelectedListData, isManager, loadingWarehouseSelectedListData }: WarehouseListProps) => {
    const { getData } = useHttp<WarehouseResponse>(APIEndPoints.GET_WAREHOUSES());
    const [warehouseData, setWarehouseData] = useState<WarehouseData[]>([]);
    const [warehouseDataLoading, setWarehouseDataLoading] = useState<boolean>(false);
    const [warehouseList, setWarehouseList] = useState<WarehouseResponse>();
    const { showError } = useApplicationContext();

    const manageAdmin = () => {
        let initiallyCheckedWarehouses;
        if (warehouseList?.Data.length > 0) {
            initiallyCheckedWarehouses = findSelectedWarehouses(warehouseSelectedListData, warehouseList.Data ?? []);
        }
        let checkedWarehouses = [];
        if (initiallyCheckedWarehouses && initiallyCheckedWarehouses.length) {
            checkedWarehouses = convertToStringArr(initiallyCheckedWarehouses, BYPASS_LOCATION_CONSTANTS.WAREHOUSE_NBR);
            const warehouseUpdatedList = warehouseList?.Data.map((item) => {
                if (checkedWarehouses.includes(item.WarehouseNbr)) {
                    return {
                        ...item,
                        Bypass: true,
                        Checked: true
                    };
                }
                return { ...item };
            });
            setWarehouseData(warehouseUpdatedList);
        } else {
            setWarehouseData(warehouseList?.Data);
        }
    };

    const fetchData = async () => {
        setWarehouseDataLoading(true);
        try {
            const response = await getData();
            setWarehouseList(response);
        } catch (error) {
            console.log(error.message);
            showError(error);
        } finally {
            setWarehouseDataLoading(false);
        }
    };

    useEffect(() => {
        if (!isManager) {
            fetchData();
        }
    }, [isManager]);

    useEffect(() => {
        if (!isManager) {
            manageAdmin();
        } else {
            const warehouseListForManager =
                warehouseSelectedListData &&
                warehouseSelectedListData.map((item) => {
                    return {
                        WarehouseNbr: item.WarehouseNbr
                    };
                });
            setWarehouseData(warehouseListForManager);
        }
    }, [warehouseList, warehouseSelectedListData]);

    const handleCheckedItems = (selectedWarehouse) => {
        const updatedWarehouseData = warehouseData.map((item) => {
            if (item.WarehouseNbr === selectedWarehouse) {
                const transformedWarehouseItem = {
                    ...item,
                    Checked: item.Checked ? false : true
                };
                if (item.hasOwnProperty(BYPASS_LOCATION_CONSTANTS.BYPASS)) {
                    transformedWarehouseItem.Bypass = transformedWarehouseItem.Checked;
                }
                return transformedWarehouseItem;
            }
            return item;
        });
        setWarehouseData((currVal) => {
            const warehouseSelectedList = updatedWarehouseData.filter(
                (i) => i.Bypass === false || (!i.hasOwnProperty(BYPASS_LOCATION_CONSTANTS.BYPASS) && i.Checked === true)
            );
            handleWarehouseChange(warehouseSelectedList);
            return updatedWarehouseData;
        });
    };

    if (loadingWarehouseSelectedListData || warehouseDataLoading) {
        return <DriscollsHContainer className={`${styles.warehouseList} ${styles.noData}`}>Loading Warehouses...</DriscollsHContainer>;
    }

    return warehouseData?.length > 0 ? (
        <>
            <DriscollsHContainer className={styles.warehouseList}>
                {warehouseData?.map((item, index) => {
                    return (
                        <DriscollsCard className={styles.cardWrapper} key={index}>
                            <DriscollsHContainer className={styles.cardBody}>
                                <DriscollsHContainer className={styles.item}>
                                    <FaIcons.FaWarehouse className={styles.icon} size={IconSize.SMALL} />
                                    {item.WarehouseNbr}
                                </DriscollsHContainer>
                                {!isManager && (
                                    <DriscollsCheckbox
                                        size={'large'}
                                        className={styles.checkbox}
                                        label=""
                                        initiallyChecked={item.Checked}
                                        onChange={() => handleCheckedItems(item.WarehouseNbr)}
                                    />
                                )}
                            </DriscollsHContainer>
                        </DriscollsCard>
                    );
                })}
            </DriscollsHContainer>
        </>
    ) : (
        <DriscollsHContainer className={`${styles.warehouseList} ${styles.noData}`}>{'No data to display'}</DriscollsHContainer>
    );
};

export default WarehouseList;
