import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import { DrcPageDataMaintenance, DrcInput, DrcPanel, DrcSelect, DrcButton, DrcDialog } from 'driscolls-react-components';
import DrcMultiSelect from '../../components/DrcMultiSelect';
import { Middleware } from 'one-ring';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setThresholdData, setUserProducingAreaThreshold, setMLookUpData } from '../../actions/RejectionThresholdActions';
import { PRODUCING_AREA, PRODUCING_AREA_PLACEHOLDER, BERRY_TYPE, BERRY_TYPE_PLACEHOLDER } from '../../language/english';
import APIEndPoints from '../../services/api';
import BerryTypeSelect from '../../components/BerryTypeSelect';
import { withAuth } from '@driscollsinc/login-module-ui';
import { EventConstants, logEvent } from '../../../utils/analytics';

const PAGE_TITLE = 'Rejection Threshold';
const PAGE_TYPE = 'RejectionThreshold';
let styles = (theme) => ({
    main: {
        margin: 0,
        paddingBottom: '0px !important'
    },
    grid: {
        '& .selected > div': {
            backgroundColor: '#cfc3deba !important'
        },
        '& .nonSelected > div': {
            backgroundColor: '#d9d0e4 !important'
        },
        '& .react-grid-Toolbar .btn:after': {
            background: 'none !important',
            backgroundColor: '#6f5091 !important',
            height: '27px',
            color: 'white'
        },
        '& .react-grid-Header': {
            background: 'none !important',
            backgroundColor: '#6f5091 !important'
        }
    },
    success: {
        color: theme.palette.primary.main
    },
    gridContainer: {
        paddingLeft: '24px'
    },
    layout: {
        width: '97%'
    },
    dialog: {
        '& .MuiDialog-paperWidthSm': {
            maxWidth: '600px',
            width: '100%'
        },
        '& .p-datatable-thead': {
            background: 'none !important',
            backgroundColor: '#6f5091 !important',
            color: '#fff'
        },
        '& .p-datatable-row': {
            fontWeight: 600
        },
        '& .p-datatable-scrollable-wrapper': {
            maxHeight: 600
        }
    }
});

const RejectionThreshold = (props) => {
    const [BerryType, setBerryType] = useState(null);
    const [producingAreaFilter, setProducingAreaFilter] = useState([]);
    const [isValid, setIsValid] = useState(false);
    const [AddMode, setAddMode] = useState(false);
    const [ErrorMessage, setErrorMessage] = useState('');
    const [producingAreaByUser, setProducingAreaByUser] = useState([]);
    const [messageDialog, setMessageDialog] = useState({
        showMessage: false,
        message: '',
        severity: 'success'
    });
    const [formattedData, setFormattedData] = useState([]);
    const [invalidRejection, setInvalidRejection] = useState(false);
    const [ProducingAreaDialog, setProducingAreaDialog] = useState('');
    const [FruitConditionDialog, setFruitConditionDialog] = useState('');
    const [BerryTypeDialog, setBerryTypeDialog] = useState('');
    const [RejectionThresholdDialog, setRejectionThresholdDialog] = useState('');
    const cellFormatter = (value, row) => {
        getColumn(value, row);
        return <span title={value}>{value} %</span>;
    };
    const columns = [
        {
            key: 'ProducingAreaCode',
            name: 'Producing Area',
            editDisabled: true,
            addDisabled: false,
            isRequired: true
        },
        {
            key: 'FruitCondition',
            name: 'Berry Condition',
            editDisabled: true,
            addDisabled: false,
            isRequired: true,
            columnTemplate: (row) => row.FruitCondition
        },
        {
            key: 'BerryType',
            name: 'Berry Type',
            editDisabled: true,
            addDisabled: false,
            isRequired: true,
            columnTemplate: (row) => row.BerryType
        },
        {
            key: 'RejectionThreshold',
            name: 'Rejection Threshold',
            validationType: 'number',
            minValue: 1,
            maxValue: 100,
            isRequired: true,
            columnTemplate: (row) => cellFormatter(row.RejectionThreshold, row)
        }
    ];
    useEffect(() => {
        logEvent(EventConstants.PAGE_VIEW,{page_title: `${EventConstants.DOTA} - ${EventConstants.REJECTION_THRESHOLD}`});
        getProducingArea((data) => {
            if (data) {
                let procuingArea = data.map((item) => {
                    return { value: item.ProducingArea, label: item.ProducingAreaCode };
                });
                setProducingAreaByUser(
                    procuingArea.sort(function (a, b) {
                        return Number(a.value - b.value);
                    })
                );
            } else {
                setProducingAreaByUser([]);
            }
        });
        getData();
    }, []);

    useEffect(() => {
        let prodArea = null;
        let selectedProducingAreas = '';
        producingAreaFilter.map((item) => {
            selectedProducingAreas = selectedProducingAreas + item.value + ',';
            return selectedProducingAreas;
        });
        prodArea = selectedProducingAreas.substring(0, selectedProducingAreas.length - 1);
        getData(prodArea, BerryType ? BerryType.label : null);
    }, [BerryType, producingAreaFilter]);

    useEffect(() => {
        const formatData = (items) => {
            let data = [];
            data = items.map((item) => {
                item.RejectionThreshold = (item.RejectionThreshold * 100).toFixed(0);
                item.ProducingAreaCode = item.ProducingAreaCode + '-' + item.ProducingAreaDescription;
                return item;
            });
            setFormattedData(data);
        };
        formatData(props.thresholdList);
    }, [props.thresholdList]);

    const getProducingArea = (callback) => {
        props.auth.getAccessToken().then((token) => {
            Middleware.Send(
                'managerMaintenance',
                token,
                APIEndPoints.GET_USER_PRODUCING_AREAS(props.loggedInUser),
                'GET',
                { Type: 'managerMaintenance' },
                { loadingMessage: '' }
            )
                .then((data) => {
                    if (data) {
                        props.setUserProducingAreaThreshold(data);
                        callback(data);
                    } else {
                        props.setUserProducingAreaThreshold([]);
                        callback([]);
                    }
                })
                .catch((error) => {
                    let message = error.data.response.data.message || 'Something happened, but I am not sure if it is good or bad!';
                    callback([]);
                    setMessageDialog({
                        showMessage: true,
                        message: message,
                        severity: 'error'
                    });
                });
        });
    };

    const getData = (producingAreaCode, berryType) => {
        props.auth.getAccessToken().then((token) => {
            Middleware.Send(
                PAGE_TYPE,
                token,
                APIEndPoints.GET_RJECTION_THRESHOLD(props.loggedInUser, producingAreaCode, berryType),
                'GET',
                { Type: PAGE_TYPE },
                { loadingMessage: 'Fetching Rejection Threshold...' }
            )
                .then((data) => {
                    if (data) {
                        props.setThresholdData(data);
                    } else {
                        props.setThresholdData([]);
                    }
                })
                .catch((error) => {
                    let message = error.data.response.data.message || 'Something happened, but I am not sure if it is good or bad!';

                    props.setThresholdData([]);
                    setMessageDialog({
                        showMessage: true,
                        message: message,
                        severity: 'error'
                    });
                });
        });
    };

    const onEdit = (oldValue, newValue) => {
        logEvent(EventConstants.EDIT_ACTION, {event_info: `${EventConstants.DOTA} - ${EventConstants.EDIT} ${EventConstants.REJECTION_THRESHOLD}`});
        let payload = {
            ProducingAreaCode: oldValue.ProducingAreaCode,
            BerryType: oldValue.BerryType[0],
            FruitCondition: oldValue.FruitCondition,
            RejectionThreshold: newValue.RejectionThreshold / 100,
            isActive: true,
            ModifiedDateTime: newValue.ModifiedDateTime,
            ModifiedBy: newValue.ModifiedBy
        };
        props.auth.getAccessToken().then((token) => {
            Middleware.Send(PAGE_TYPE, token, APIEndPoints.POST_RJECTION_THRESHOLD, 'POST', payload, {
                overrideRequestMapping: true,
                loadingMessage: 'Updating RejectionThreshold...'
            })
                .then((res) => {
                    setMessageDialog({
                        showMessage: true,
                        message: res.Message,
                        type: '',
                        severity: 'success'
                    });
                    let prodArea = null;
                    let selectedProducingAreas = '';
                    producingAreaFilter.map((item) => {
                        selectedProducingAreas = selectedProducingAreas + item.value + ',';
                        return selectedProducingAreas;
                    });
                    prodArea = selectedProducingAreas.substring(0, selectedProducingAreas.length - 1);
                    getData(prodArea, BerryType ? BerryType.label : null);
                })
                .catch((error) => {
                    let message = error.data.response.data.message || 'Something happened. but I am not sure if it is good or bad!';
                    setMessageDialog({
                        showMessage: true,
                        message: message,
                        type: '',
                        severity: 'error'
                    });
                });
        });
    };

    const onAdd = () => {
        logEvent(EventConstants.ADD_ACTION, {event_info:`${EventConstants.DOTA} - ${EventConstants.ADD} ${EventConstants.REJECTION_THRESHOLD}`});
        let payload = {
            ProducingAreaCode: ProducingAreaDialog.value,
            BerryType: BerryTypeDialog.label,
            FruitCondition: FruitConditionDialog.value,
            RejectionThreshold: RejectionThresholdDialog / 100,
            isActive: true,
            CreatedDatetime: new Date().toISOString(),
            ModifiedDateTime: new Date().toISOString(),
            CreatedBy: props.loggedInUser,
            ModifiedBy: props.loggedInUser
        };

        props.auth.getAccessToken().then((token) => {
            Middleware.Send(PAGE_TYPE, token, APIEndPoints.POST_RJECTION_THRESHOLD, 'POST', payload, {
                overrideRequestMapping: true,
                loadingMessage: 'Adding Rejection Threshold...'
            })
                .then((res) => {
                    setMessageDialog({
                        showMessage: true,
                        message: res.Message,
                        type: '',
                        severity: 'success'
                    });
                    setAddMode(false);
                    setProducingAreaDialog(producingAreaFilter[0] || '');
                    setFruitConditionDialog('');
                    setBerryTypeDialog(BerryType || '');
                    setRejectionThresholdDialog('');

                    setIsValid(false);

                    let prodArea = null;
                    let selectedProducingAreas = '';
                    producingAreaFilter.map((item) => {
                        selectedProducingAreas = selectedProducingAreas + item.value + ',';
                        return selectedProducingAreas;
                    });
                    prodArea = selectedProducingAreas.substring(0, selectedProducingAreas.length - 1);
                    getData(prodArea, BerryType ? BerryType.label : null);
                })
                .catch(() => {
                    let message = 'Something happened. but I am not sure if it is good or bad!';
                    setMessageDialog({
                        showMessage: true,
                        message: message,
                        type: '',
                        severity: 'error'
                    });
                });
        });
    };

    const onDelete = (deleteVal) => {
        logEvent(EventConstants.DELETE_ACTION, {event_info: `${EventConstants.DOTA} - ${EventConstants.DELETE} ${EventConstants.REJECTION_THRESHOLD}`});
        let payload = {
            RejectionThresholdId: deleteVal.RejectionThresholdId
        };
        props.auth.getAccessToken().then((token) => {
            Middleware.Send(PAGE_TYPE, token, APIEndPoints.POST_RJECTION_THRESHOLD, 'DELETE', payload, {
                overrideRequestMapping: true,
                loadingMessage: 'Deleting Rejection...'
            })
                .then(() => {
                    let updatedData = formattedData.filter((item) => item.RejectionThresholdId !== deleteVal.RejectionThresholdId);
                    setFormattedData(updatedData);
                    setMessageDialog({
                        showMessage: true,
                        message: 'Rejection Threshold Deleted',
                        type: '',
                        severity: 'success'
                    });
                })
                .catch((error) => {
                    let message = error.data.response.data.message || 'Something happened. but I am not sure if it is good or bad!';
                    setMessageDialog({
                        showMessage: true,
                        message: message,
                        type: '',
                        severity: 'error'
                    });
                });
        });
    };

    const handleClose = (_event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMessageDialog({ showMessage: false });
    };

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    const handleBerryTypeChange = (berryType) => {
        setBerryType(berryType);
        setBerryTypeDialog(berryType);
    };

    const handleOnChangeOfProducingArea = (option) => {
        if (option === null) {
            setProducingAreaFilter([]);
        } else {
            setProducingAreaFilter(option);
            setProducingAreaDialog(option[0]);
            setProducingAreaDialog(option[0]);
        }
    };

    const gridDataFormatter = (data) => {
        const modifiedData = [...data];
        if (modifiedData.length) {
            if (!modifiedData[0].language) {
                modifiedData[0].language = 'English';
            }
            modifiedData[0].ModifiedDateTime = new Date().toISOString();
            modifiedData[0].ModifiedBy = props.loggedInUser;
            return modifiedData;
        } else {
            return [];
        }
    };

    const onCancel = () => {
        setAddMode(false);
        setProducingAreaDialog(producingAreaFilter[0] || '');
        setFruitConditionDialog('');
        setBerryTypeDialog(BerryType || '');
        setRejectionThresholdDialog('');
        setIsValid(false);
        setInvalidRejection(false);
    };

    const handleProducingAreaDialogChange = (option) => {
        if (FruitConditionDialog && BerryTypeDialog && RejectionThresholdDialog) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
        setProducingAreaDialog(option);
    };
    const handleFruitConditionDialogChange = (option) => {
        setFruitConditionDialog(option);
        if (ProducingAreaDialog && BerryTypeDialog && RejectionThresholdDialog) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    };
    const handleBerryTypeDialogChange = (option) => {
        setBerryTypeDialog(option);
        if (ProducingAreaDialog && FruitConditionDialog && RejectionThresholdDialog) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    };
    const handleRejectionThresholdDailogChanges = (event) => {
        const allowedNumberValuesRegex = RegExp(/^[0-9]*$/);
        setRejectionThresholdDialog(event.target.value);
        if (!allowedNumberValuesRegex.test(event.target.value) || isNaN(Number(event.target.value))) {
            setInvalidRejection(true);
            setIsValid(false);
            setErrorMessage('Please Remove Illegal Characters');
        } else if (event.target.value === '') {
            setInvalidRejection(true);
            setIsValid(false);
            setErrorMessage('Please enter value');
        } else {
            if (Number(event.target.value) < 0 || Number(event.target.value) > 100) {
                setErrorMessage('Please Use a Value between 1 to 100');
                setIsValid(false);
                setInvalidRejection(true);
            } else {
                setInvalidRejection(false);
                if (ProducingAreaDialog && FruitConditionDialog && BerryTypeDialog) {
                    setIsValid(true);
                } else {
                    setIsValid(false);
                }
            }
        }
    };
    const getColumn = (value, row) => {
        for (let key in row) {
            if (row[key] === value) {
                return key;
            }
        }
    };
    const { classes } = props;

    return (
        <DrcPanel
            style={{ maxWidth: '100%', border: 'none', boxShadow: '0 2px 4px 0 #C3C3C3', marginBottom: '0px' }}
            className={props.classes.layout}
        >
            <Grid container spacing={1} className={classes.gridContainer}>
                <Grid item xs={6} sm={5} md={4} lg={3}>
                    <DrcMultiSelect
                        options={producingAreaByUser}
                        label={PRODUCING_AREA}
                        placeholder={PRODUCING_AREA_PLACEHOLDER}
                        isMulti={true}
                        onChange={handleOnChangeOfProducingArea}
                        name="producingArea"
                    ></DrcMultiSelect>
                </Grid>
                <Grid item xs={6} sm={5} md={4} lg={3}>
                    <BerryTypeSelect
                        isClearable={true}
                        label={BERRY_TYPE}
                        options={props.berryType}
                        value={BerryType}
                        placeholder={BERRY_TYPE_PLACEHOLDER}
                        onChange={handleBerryTypeChange}
                    />
                </Grid>
            </Grid>

            <DrcPageDataMaintenance
                classes={classes}
                className={classes.dialog}
                addBtn={true}
                fullHeightOffset={10}
                fullWidth={true}
                data={formattedData}
                type={PAGE_TITLE}
                readOnly={false}
                columns={columns}
                hideCount={false}
                addText="Create New"
                // onAdd={onAdd}
                onEdit={onEdit}
                onDelete={onDelete}
                settings={{
                    EnableExport: false,
                    EnableEdit: true,
                    EnableDelete: true,
                    OverrideAdd: () => {
                        setAddMode(true);
                    }
                }}
                textOptions={{
                    AddBtn: 'Add New'
                }}
                gridDataFormatter={gridDataFormatter}
                resultCount={formattedData.length}
            />

            <DrcDialog
                open={AddMode}
                title={'Add: Rejection Threshold'}
                buttons={
                    <div>
                        <DrcButton isSecondary onClick={onCancel} floatRight>
                            Cancel
                        </DrcButton>
                        <DrcButton isPrimary onClick={onAdd} floatRight disabled={!isValid}>
                            Save
                        </DrcButton>
                    </div>
                }
            >
                <hr />
                <div className="row" style={{ margin: '1rem', padding: ' 0 .5rem' }}>
                    <div style={{ minWidth: '20rem', padding: '0 .5rem' }}>
                        <DrcSelect
                            label="Producing Area"
                            options={producingAreaByUser}
                            value={ProducingAreaDialog}
                            placeholder={PRODUCING_AREA_PLACEHOLDER}
                            onChange={handleProducingAreaDialogChange}
                        />
                    </div>
                    <div style={{ minWidth: '20rem', padding: '0 .5rem' }}>
                        <DrcSelect
                            label="Fruit Condition"
                            options={props.mLookupList}
                            value={FruitConditionDialog}
                            placeholder="Please select Fruit Condition"
                            onChange={handleFruitConditionDialogChange}
                        />
                    </div>
                </div>
                <div className="row" style={{ margin: '1rem', padding: ' 0 .5rem' }}>
                    <div style={{ minWidth: '20rem', padding: '0 .5rem' }}>
                        <DrcSelect
                            label={BERRY_TYPE}
                            options={props.berryType}
                            value={BerryTypeDialog}
                            placeholder={BERRY_TYPE_PLACEHOLDER}
                            onChange={handleBerryTypeDialogChange}
                        />
                    </div>
                    <div style={{ minWidth: '20rem', padding: '0 .5rem' }}>
                        <DrcInput
                            label="Rejection Threshold"
                            value={RejectionThresholdDialog}
                            helperText={invalidRejection ? ErrorMessage : ''}
                            onChange={(e) => handleRejectionThresholdDailogChanges(e)}
                            InputLabelProps={{ shrink: true }}
                            placeholder={'Enter Rejection Threshold value'}
                            inputProps={{ maxLength: 3 }}
                        />
                    </div>
                </div>
            </DrcDialog>

            <Snackbar open={messageDialog.showMessage} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={messageDialog.severity}>
                    {messageDialog.message}
                </Alert>
            </Snackbar>
        </DrcPanel>
    );
};

function mapStateToProps(state) {
    return {
        pageTitle: state.rootReducer.pageTitle,
        producingArea: state.overrideReducer.producingArea,
        userGroup: state.masterReducer.userGroup,
        loggedInUser: state.masterReducer.loggedInUser,
        berryType: state.pointMatrixReducer.berryType,
        thresholdList: state.RejectionThresholdReducer.thresholdList,
        userProducingArea: state.RejectionThresholdReducer.userProducingArea,
        mLookupList: state.RejectionThresholdReducer.mLookupList
    };
}

export default connect(mapStateToProps, { setThresholdData, setUserProducingAreaThreshold, setMLookUpData })(
    withAuth(withStyles(styles)(RejectionThreshold))
);
