import { DrcButton, DrcPanel } from 'driscolls-react-components';
import { connect } from 'react-redux';
import { Middleware } from 'one-ring';
import APIEndPoints from '../../services/api';
import { CONFIRM, POINT, SCORE } from '../../language/english';
import { lookUpValues, resetHeader } from '../../actions/PointMatrixAction';
import Grid from '@material-ui/core/Grid';
import classes from './PointMatrixScoreTable.module.css';
import { DriscollsDataTable } from '@driscollsinc/style-guide-web2.0';
import { DrcTooltip } from 'driscolls-react-components';
import { withAuth } from '@driscollsinc/login-module-ui';
const PAGE_TYPE = 'pointMatrix';

const columns = [
    {
        accessorKey: 'Score',
        header: (
            <DrcTooltip tipText={SCORE}>
                <span>{SCORE}</span>
            </DrcTooltip>
        ),
        cell: (data) => {
            const value = data.getValue();
            return (
                <span style={{ fontWeight: '500', fontSize: '13px', color: value > 0 ? '#3ab517' : '#f93d22' }} title={value}>
                    {value}
                </span>
            );
        }
    },
    {
        accessorKey: 'Point',
        header: (
            <DrcTooltip tipText={POINT}>
                <span>{POINT}</span>
            </DrcTooltip>
        ),
        cell: (data) => {
            const value = data.getValue();
            return (
                <span style={{ fontWeight: '500', fontSize: '13px' }} title={value}>
                    {value}
                </span>
            );
        }
    }
];

const PointMatrixScoreTable = (props) => {
    const { lookUp, rows, className, preview } = props;

    const handleConfirm = () => {
        const { lookupDataPayload } = props;
        lookupDataPayload.Operation = 'Create';
        props.auth.getAccessToken().then((token) => {
            Middleware.Send(PAGE_TYPE, token, APIEndPoints.POST_POINT_MATRIX, 'POST', lookupDataPayload, {
                overrideResponseMapping: true,
                loadingMessage: 'Creating point matrix lookup...'
            })
                .then((response) => {
                    if (response && response['PointMatrixId']) {
                        props.lookUpValues([]);
                        props.handleDisplayMessage('Point Matrix lookup created sucessfully', 'success');
                        props.resetHeader(true);
                    } else {
                        let message = response.message || 'Something happened. but I am not sure if it is good or bad!';
                        props.handleDisplayMessage(message, 'error');
                    }
                })
                .catch((error) => {
                    let message = error.data.response.data.message || 'Something happened. but I am not sure if it is good or bad!';
                    props.handleDisplayMessage(message, 'error');
                });
        });
    };

    return (
        <Grid container spacing={3} justifyContent="space-between" alignItems="flex-start">
            <Grid style={{ padding: '12px' }} item xs={12} sm={12} md={12} lg={12}>
                <DrcPanel className={`${classes.panel} ${className ?? ''}`}>
                    <DriscollsDataTable
                        tableClassName={classes.table}
                        isLoading={false}
                        columns={columns}
                        hidePagination={true}
                        itemsPerPage={10}
                        data={rows ?? lookUp}
                        textFilterPlaceholder={true}
                    />
                    {!preview && (
                        <DrcButton className={classes.button} isPrimary onClick={handleConfirm}>
                            {CONFIRM}
                        </DrcButton>
                    )}
                </DrcPanel>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => {
    return {
        lookUp: state.pointMatrixReducer.lookUp
    };
};

export default connect(mapStateToProps, { lookUpValues, resetHeader })(withAuth(PointMatrixScoreTable));
