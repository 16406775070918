import React from 'react';
import { DuAuthenticationUtilities } from 'driscolls-react-utilities';
import DrcHeader from './DrcHeader';
import { withAuth } from '@driscollsinc/login-module-ui';
import { getAdminGroups, getAnalystGroups, getManagerGroups } from '../../../../utils/getConfigData';

class DrcSecureHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            allowedLinks: this.props.allowAllLinks ? this.props.allLinks || [] : []
        };

        this.checkAuthentication = this.checkAuthentication.bind(this);
    }

    checkAuthentication() {
        if (!this.props.auth) {
            if (this.state.allowedLinks.length > 0) {
                this.setState({ allowedLinks: [] });
            }

            return;
        }

        this.props.auth.getAccessToken().then((token) => {
            if (!token || token.length <= 0) {
                if (this.state.allowedLinks.length > 0) {
                    this.setState({ allowedLinks: [] });
                }
            } else {
                var allowedLinks = [];

                var matchedLinks = this.props.allLinks.filter((l) => {
                    if ((l.requiresGroups || []).length > 0) {
                        const userGroups = DuAuthenticationUtilities.GetGroups(token);
                        const matches = [];
                        for (const group of l.requiresGroups) {
                            if (userGroups.includes(group)) {
                                matches.push(group);
                            }
                        }
                        return matches.length ? true : false;
                    }

                    return true;
                });

                if (DuAuthenticationUtilities.IsInGroup(token, getAdminGroups())) {
                    allowedLinks = matchedLinks;
                } else if (DuAuthenticationUtilities.IsInGroup(token, getManagerGroups())) {
                    allowedLinks = matchedLinks.filter((link) => !link.requiresAdmin);
                    allowedLinks = allowedLinks.map((link) => {
                        link.subLinks = (link.subLinks || []).filter((subLink) => !subLink.requiresAdmin);
                        return link;
                    });
                } else if (DuAuthenticationUtilities.IsInGroup(token, getAnalystGroups())) {
                    allowedLinks = matchedLinks.filter((link) => !link.requiresAdmin && !link.requiresRegular);
                    allowedLinks = allowedLinks.map((link) => {
                        link.subLinks = (link.subLinks || []).filter((subLink) => !subLink.requiresAdmin && !subLink.requiresRegular);
                        return link;
                    });
                }

                if (this.state.allowedLinks.length !== allowedLinks.length) {
                    this.setState({ allowedLinks });
                }
            }
        });
    }

    componentDidMount() {
        this.checkAuthentication();
    }

    componentDidUpdate() {
        this.checkAuthentication();
    }

    render() {
        const { children, title, noLogo, style, fullWidth, logo, className, showFilterControl, showSavedFiltersPopup, anchor } = this.props;

        return (
            <DrcHeader
                title={title}
                noLogo={noLogo}
                showSavedFiltersPopup={showSavedFiltersPopup}
                allLinks={this.state.allowedLinks}
                style={style}
                showFilterControl={showFilterControl}
                fullWidth={fullWidth}
                logo={logo}
                className={className}
                anchor={anchor}
            >
                {children}
            </DrcHeader>
        );
    }
}

export default withAuth(DrcSecureHeader);
