import { FC, memo, useEffect, useState } from 'react';
import { DriscollsSelect, DriscollsTranslatedValue } from '@driscollsinc/style-guide-web2.0';
import { convertToLabelValue, transformArrayToLabelValue } from '../../../../utils/format';
import { NAMESPACE } from '../../../../i18n';
import { useEagerHttp, useHttp } from '../../../hooks/useHttp';
import APIEndPoints from '../../../api';
import { WarehouseResponse } from './Types';
import { USER_MANAGEMENT_CONSTANTS } from '../../../constants/userManagementConstants';

interface WarehouseSelectProps {
    styles: any;
    onChange?: (selectedOption: any) => void;
    error?: any;
    defaultValue?: any;
    isMulti?: boolean;
    options?: string[];
    isDisabled?: boolean;
    loadingWarehouseOptions?: boolean;
    defaultOptions: boolean;
}

const WarehouseSelect: FC<WarehouseSelectProps> = memo(function WarehouseSelect({
    styles,
    isMulti,
    options,
    loadingWarehouseOptions,
    defaultOptions,
    ...rest
}) {
    const { getData } = useHttp<WarehouseResponse>(APIEndPoints.GET_WAREHOUSES());
    const [warehouseOptions, setWarehouseOptions] = useState<{ label: string; value: string }[]>();

    useEffect(() => {
        if (options && !defaultOptions) {
            const warehouseOptions = convertToLabelValue(options);
            setWarehouseOptions(warehouseOptions);
        }
    }, [options]);

    useEffect(() => {
        if (defaultOptions) {
            fetchData();
        }
    }, []);

    const fetchData = async () => {
        try {
            const response = await getData();
            const options = transformArrayToLabelValue(
                response?.Data,
                USER_MANAGEMENT_CONSTANTS.WAREHOUSE_NBR,
                USER_MANAGEMENT_CONSTANTS.WAREHOUSE_NBR
            );
            setWarehouseOptions(options);
        } catch (error) {
            console.log(error.message);
        }
    };

    if (loadingWarehouseOptions) return <div className={styles.loadingOptions}>Loading options...</div>;

    return (
        <DriscollsSelect
            name="warehouse"
            className={styles.selectField}
            containerClassName={styles.selectContainer}
            isMulti={isMulti ?? true}
            options={warehouseOptions}
            placeholder={<DriscollsTranslatedValue nameSpacing={NAMESPACE.USER_MANAGEMENT} value="Warehouse" />}
            {...rest}
        />
    );
});

export default WarehouseSelect;
