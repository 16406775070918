import {
    ApplicationPageTitle,
    ApplicationPage,
    DriscollsHContainer,
    DriscollsButton,
    DriscollsTranslatedValue,
    ButtonSize,
    useForm,
    yupResolver
} from '@driscollsinc/style-guide-web2.0';
import { NAMESPACE } from '../../../i18n';
import PageContainer from '../../components/container/PageContainer';
import styles from './index.module.scss';
import { useNavigate } from 'react-router-dom';
import { routes, routesName } from '../../routes/routes';
import BerryTypesSelect from '../../components/pointMatrix/berryType';
import { useEffect, useState } from 'react';
import WarehouseList from '../../components/bypass/WarehouseList';
import { BYPASS_LOCATION_CONSTANTS } from '../../constants/bypassConstants';
import { convertToStringArr } from '../../../utils/format';
import { BypassLocationSchema } from './FormSchema';
import { useHttp } from '../../hooks/useHttp';
import { BypassLocationData, BypassLocationPost, BypassLocationResponse } from './Types';
import APIEndPoints from '../../api';
import { useApplicationContext } from '../../hooks/useApplicationContext';
import { DriscollsAuthenticationUtilities, useAuth } from '@driscollsinc/login-module-ui';
import useCheckUserGroup from '../../hooks/useCheckUserGroup';
import { TUserManagementList } from '../../types/userManagement';
import { useApplicationDataContext } from '../../hooks/useApplicationDataContext';
import { EventConstants, logEvent } from '../../../utils/analytics';

const Bypass = () => {
    const navigate = useNavigate();
    const { getAccessToken } = useAuth();
    const { showSuccess, showError, setShowLoading } = useApplicationContext();
    const { isDemeaAdminUser } = useCheckUserGroup();
    const isDemeaAdminUserFlag = isDemeaAdminUser();
    const { selectedBerryType, setSelectedBerryType } = useApplicationDataContext();

    const {
        handleSubmit,
        setValue,
        formState: { errors, isValid },
        reset
    } = useForm({
        resolver: yupResolver(BypassLocationSchema),
        mode: 'onSubmit',
        defaultValues: { BerryType: selectedBerryType, WarehouseNbr: '' }
    });

    const [data, setData] = useState<BypassLocationData[]>();
    const [warehouse, setWarehouse] = useState<string>();
    const [loadingWarehouseData, setLoadingWarehouseData] = useState<boolean>(false);
    const [userEmail, setUserEmail] = useState<string>();

    const { getData } = useHttp<BypassLocationResponse>(
        APIEndPoints.BYPASS_LOCATION_GET(warehouse, selectedBerryType, BYPASS_LOCATION_CONSTANTS.NONE)
    );
    const { getData: getUserWarehouseData } = useHttp<TUserManagementList>(
        APIEndPoints.USER_MANAGEMENT_GET(BYPASS_LOCATION_CONSTANTS.DEFAULT_PAGE, BYPASS_LOCATION_CONSTANTS.DEFAULT_PAGE_SIZE, '', userEmail)
    );
    const { postData } = useHttp<BypassLocationPost>(APIEndPoints.BYPASS_LOCATION_POST());

    useEffect(() => {
        logEvent(EventConstants.PAGE_VIEW, { page_title: `${EventConstants.DEMEA} - ${EventConstants.BYPASS_LOCATIONS}` });
    }, []);

    useEffect(() => {
        reset((prevValues) => ({
            ...prevValues,
            BerryType: selectedBerryType
        }));
    }, [selectedBerryType, reset]);

    useEffect(() => {
        if (!isDemeaAdminUserFlag) {
            const fetchAccessToken = async () => {
                const token = await getAccessToken();
                const user = DriscollsAuthenticationUtilities.GetEmail(token);
                setUserEmail(user);
            };
            fetchAccessToken();
        } else {
            setWarehouse(BYPASS_LOCATION_CONSTANTS.ALL);
        }
    }, [isDemeaAdminUserFlag]);

    useEffect(() => {
        if (userEmail && !isDemeaAdminUserFlag) {
            fetchUserData();
        }
    }, [userEmail, isDemeaAdminUserFlag]);

    useEffect(() => {
        if (warehouse) {
            fetchData();
        }
    }, [warehouse, selectedBerryType]);

    const fetchData = async () => {
        setShowLoading(true);
        setLoadingWarehouseData(true);
        try {
            const response = await getData();
            setData(response?.Data);
        } catch (error) {
            console.log(error.message);
            showError(error);
        } finally {
            setShowLoading(false);
            setLoadingWarehouseData(false);
        }
    };

    const fetchUserData = async () => {
        try {
            const response = await getUserWarehouseData();
            const stringArr = convertToStringArr(response?.Data, BYPASS_LOCATION_CONSTANTS.WAREHOUSE_NBR);
            setWarehouse(stringArr.join(','));
        } catch (error) {
            console.log(error.message);
            showError(error);
        }
    };

    const handleBerryTypeChange = (selectedOption) => {
        reset();
        setSelectedBerryType(selectedOption?.value);
        setValue(BYPASS_LOCATION_CONSTANTS.BERRY_TYPE, selectedOption?.value, { shouldValidate: true });
    };

    const handleWarehouseChange = (selectedWarehousesArr) => {
        setValue(BYPASS_LOCATION_CONSTANTS.WAREHOUSE_NBR, selectedWarehousesArr, {
            shouldValidate: true
        });
    };

    const onSubmit = async (data) => {
        const token = await getAccessToken();
        const user = DriscollsAuthenticationUtilities.GetEmail(token);
        const currentDate = new Date();
        const payload = {
            [BYPASS_LOCATION_CONSTANTS.INSPECTIONS]: data.WarehouseNbr.map((warehouse) => ({
                [BYPASS_LOCATION_CONSTANTS.BERRY_TYPE]: data.BerryType,
                [BYPASS_LOCATION_CONSTANTS.WAREHOUSE_NBR]: warehouse.WarehouseNbr,
                [BYPASS_LOCATION_CONSTANTS.BYPASS]: warehouse.hasOwnProperty(BYPASS_LOCATION_CONSTANTS.BYPASS) ? warehouse.Bypass : true,
                [BYPASS_LOCATION_CONSTANTS.PERCENTAGE]: BYPASS_LOCATION_CONSTANTS.NONE,
                [BYPASS_LOCATION_CONSTANTS.BUSINESS_UNIT]: BYPASS_LOCATION_CONSTANTS.DEMEA
            })),
            [BYPASS_LOCATION_CONSTANTS.LAST_UPDATED_BY]: user,
            [BYPASS_LOCATION_CONSTANTS.LAST_UPDATED_DATE]: currentDate.toISOString()
        };

        try {
            await postData(payload as BypassLocationPost);
            showSuccess('Warehouses have been updated successfully!');
            await fetchData();
            reset();
        } catch (error) {
            showError(error?.message || 'Something went wrong!');
        }
    };

    return (
        <PageContainer>
            <ApplicationPage className={styles.bypassPage}>
                <DriscollsHContainer noPadding className={styles.header}>
                    <ApplicationPageTitle
                        title={{ text: BYPASS_LOCATION_CONSTANTS.BYPASS, nameSpace: NAMESPACE.BYPASS }}
                        description={{ text: BYPASS_LOCATION_CONSTANTS.LOCATION, nameSpace: NAMESPACE.BYPASS }}
                    />
                    <DriscollsHContainer className={styles.rightAlign} noPadding>
                        <DriscollsButton
                            className={styles.btnRounded}
                            label={<DriscollsTranslatedValue value={BYPASS_LOCATION_CONSTANTS.QUARTILE} nameSpacing={NAMESPACE.BYPASS} />}
                            onClick={() => {
                                navigate(`${routes[routesName.BYPASS_QUARTILE].path}`);
                            }}
                        />
                        <DriscollsButton
                            className={styles.btnRounded}
                            label={<DriscollsTranslatedValue value={BYPASS_LOCATION_CONSTANTS.OVERRIDE} nameSpacing={NAMESPACE.BYPASS} />}
                            onClick={() => {
                                navigate(`${routes[routesName.BYPASS_OVERRIDE].path}`);
                            }}
                        />
                    </DriscollsHContainer>
                </DriscollsHContainer>
                <form className={styles.bypassLocationForm} onSubmit={handleSubmit(onSubmit)}>
                    <BerryTypesSelect
                        defaultValue={{
                            label: selectedBerryType,
                            value: selectedBerryType
                        }}
                        styles={styles}
                        onChange={handleBerryTypeChange}
                        error={errors.BerryType?.message}
                        isClearable={false}
                        isCompact={true}
                    />
                    <WarehouseList
                        handleWarehouseChange={handleWarehouseChange}
                        warehouseSelectedListData={data ?? []}
                        isManager={!isDemeaAdminUserFlag}
                        loadingWarehouseSelectedListData={loadingWarehouseData}
                    />
                    {isDemeaAdminUserFlag && (
                        <DriscollsHContainer className={styles.rightAlign}>
                            <DriscollsButton
                                className={styles.btnRounded}
                                label={<DriscollsTranslatedValue value={BYPASS_LOCATION_CONSTANTS.SAVE} />}
                                onClick={handleSubmit(onSubmit)}
                                buttonSize={ButtonSize.SMALL}
                                disabled={!isValid}
                            />
                        </DriscollsHContainer>
                    )}
                </form>
            </ApplicationPage>
        </PageContainer>
    );
};

export default Bypass;
