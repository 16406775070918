import { NAMESPACE } from '../../../i18n';
import {
    DriscollsCard,
    DriscollsCardBody,
    DriscollsCardHeader,
    DriscollsTypography,
    DriscollsTypographyEnum,
    DriscollsTypographyStyles,
    useLang
} from '@driscollsinc/style-guide-web2.0';
import styles from './index.module.scss';

const ErrorFallback = ({ error }) => {
    const { getTextTranslated } = useLang([NAMESPACE.COMMON]);
    console.log(error);
    return (
        <DriscollsCard className={styles.errorFallback}>
            <DriscollsCardHeader>
                <DriscollsTypography
                    className={styles.title2}
                    typographyStyle={DriscollsTypographyStyles.TITLE2}
                    type={DriscollsTypographyEnum.H4}
                >
                    {getTextTranslated('Something went wrong', NAMESPACE.COMMON)}:
                </DriscollsTypography>
            </DriscollsCardHeader>
            <DriscollsCardBody>
                <DriscollsTypography
                    typographyStyle={DriscollsTypographyStyles.SUBTITLE4}
                    type={DriscollsTypographyEnum.P}
                >
                    {error.message}
                </DriscollsTypography>
                <details>
                    <summary>
                        <DriscollsTypography typographyStyle={DriscollsTypographyStyles.BODY} type="span">
                            {getTextTranslated('Error Details', NAMESPACE.COMMON)}:
                        </DriscollsTypography>
                    </summary>
                    {error.stack && (
                        <DriscollsTypography
                            typographyStyle={DriscollsTypographyStyles.SUBTITLE5}
                            type={DriscollsTypographyEnum.P}
                        >
                            {error.stack}:
                        </DriscollsTypography>
                    )}
                </details>
            </DriscollsCardBody>
        </DriscollsCard>
    );
};

export default ErrorFallback;
