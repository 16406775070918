export enum routesName {
    HOME = '/',
    LOGIN = 'login',
    INITIALIZE_APPLICATION = 'initializeApplication',
    DOTA_BYPASS_LOCATION = 'bypassLocation',
    DEMEA = 'demea',
    USER_MANAGEMENT = 'users',
    USER_MANAGEMENT_ADD = 'add',
    SITES = 'sites',
    CREATE_SITE_MAPPING = 'createSiteMapping',
    EDIT_SITE_MAPPING = 'editSite',
    POINT_MATRIX = 'pointMatrix',
    POINT_MATRIX_CREATE = 'pointMatrixCreate',
    POINT_MATRIX_SET = 'pointMatrixSet',
    POINT_MATRIX_CONFIRM = 'pointMatrixConfirm',
    BYPASS = 'bypass',
    BYPASS_QUARTILE = 'bypassQuartile',
    BYPASS_ADD_QUARTILE = 'bypassAddQuartile',
    BYPASS_EDIT_QUARTILE = 'bypassEditQuartile',
    BYPASS_EDIT_OVERRIDE = 'bypassEditOverride',
    BYPASS_OVERRIDE = 'bypassOverride',
    BYPASS_ADD_OVERRIDE = 'bypassAddOverride'
}

export const routes = {
    [routesName.HOME]: {
        path: '/',
        getRoute: () => '/'
    },

    [routesName.LOGIN]: {
        path: '/Login/',
        getRoute: () => '/Login/'
    },

    [routesName.INITIALIZE_APPLICATION]: {
        path: '/InitializeApplication/',
        getRoute: () => '/InitializeApplication/'
    },

    [routesName.DOTA_BYPASS_LOCATION]: {
        path: '/Bypass/Location/',
        getRoute: () => '/Bypass/Location/'
    },

    [routesName.DEMEA]: {
        path: '/demea/',
        getRoute: () => '/demea/'
    },

    [routesName.USER_MANAGEMENT]: {
        path: 'users',
        getRoute: () => 'users'
    },
    [routesName.USER_MANAGEMENT_ADD]: {
        path: 'users/add',
        getRoute: () => 'users/add'
    },
    [routesName.SITES]: {
        path: 'sites',
        getRoute: () => 'sites'
    },
    [routesName.CREATE_SITE_MAPPING]: {
        path: 'createSite',
        getRoute: () => 'createSite'
    },
    [routesName.EDIT_SITE_MAPPING]: {
        path: 'editSite/:id',
        getRoute: (siteId: string) => `editSite/${siteId}`
    },
    [routesName.POINT_MATRIX]: {
        path: '/demea/pointMatrix',
        getRoute: () => '/demea/pointMatrix'
    },
    [routesName.POINT_MATRIX_CREATE]: {
        path: 'create',
        getRoute: () => 'create'
    },
    [routesName.POINT_MATRIX_SET]: {
        path: 'set',
        getRoute: () => 'set'
    },
    [routesName.POINT_MATRIX_CONFIRM]: {
        path: 'confirm',
        getRoute: () => 'confirm'
    },
    [routesName.BYPASS]: {
        path: '/demea/bypass',
        getRoute: () => '/demea/bypass'
    },
    [routesName.BYPASS_QUARTILE]: {
        path: 'bypassQuartile',
        getRoute: () => 'bypassQuartile'
    },
    [routesName.BYPASS_ADD_QUARTILE]: {
        path: 'bypassAddQuartile',
        getRoute: () => 'bypassAddQuartile'
    },
    [routesName.BYPASS_EDIT_QUARTILE]: {
        path: 'bypassEditQuartile/:id',
        getRoute: (id: string) => `bypassEditQuartile/${id}`
    },
    [routesName.BYPASS_OVERRIDE]: {
        path: 'bypassOverride',
        getRoute: () => 'bypassOverride'
    },
    [routesName.BYPASS_ADD_OVERRIDE]: {
        path: 'bypassAddOverride',
        getRoute: () => 'bypassAddOverride'
    },
    [routesName.BYPASS_EDIT_OVERRIDE]: {
        path: 'bypassEditOverride/:id',
        getRoute: (id: string) => `bypassEditOverride/${id}`
    },
};
