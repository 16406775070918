export const BYPASS_OVERRIDE_CONSTANTS = {
    BERRY_TYPE: 'BerryType',
    WAREHOUSE_NBR: 'WarehouseNbr',
    RANCH_NUMBER: 'RanchNbr',
    RANCH_NAME: 'RanchName',
    INSPECTIONS: 'Inspections',
    PERCENTAGE: 'Percentage',
    VARIETY: 'Variety',
    ESTIMATE_GROUP: 'EstimateGroup',
    BUSINESS_UNIT: 'BusinessUnit',
    DEMEA: 'DEMEA',
    BERRY_TYPE_BLACK: 'BLACK',
    ALL: 'All',
    NONE: 'None',
    LOCATION: 'Location',
    QUARTILE: 'Quartile',
    OVERRIDE: 'Override',
    CANCEL: 'Cancel',
    SUBMIT: 'Submit',
    MODIFIED_BY: 'ModifiedBy',
    START_DATE: 'StartDate',
    END_DATE: 'EndDate',
    CREATED_BY: 'CreatedBy',
    CREATED_DATE_TIME: 'CreatedDateTime',
    IS_DELETED: 'IsDeleted',
    ADD_OVERRIDE: 'Add Override',
    EDIT_OVERRIDE: 'Edit Override',
    ISO_DATE_FORMAT: 'yyyy-MM-dd',
    SELECTED_RANCH: 'selectedRanch',
    SELECTED_DATE: 'selectedDate',
    SELECTED_CREATED_BY: 'selectedCreatedBy'
};
