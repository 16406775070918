import { DriscollsHContainer } from '@driscollsinc/style-guide-web2.0';
import styles from './index.module.scss';
import { DriscollsTranslatedValue } from '@driscollsinc/style-guide-web2.0';

type NoDataCardProps = {
    showCard: boolean;
    message: string;
    nameSpacing: string;
};

const NoDataCard = ({ showCard, message, nameSpacing }: NoDataCardProps) => {
    return (
        <>
            {showCard && (
                <DriscollsHContainer className={styles.noDataCardContainer}>
                    <DriscollsTranslatedValue value={message} nameSpacing={nameSpacing} />
                </DriscollsHContainer>
            )}
        </>
    );
};

export default NoDataCard;
