import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BypassLocationPost, BypassLocationResponse } from './Types';
import { useApplicationContext } from '../../hooks/useApplicationContext';
import { IBypassQuartileGridFilterOptions } from '../../components/bypass/BypassQuartileGrid';
import { BYPASS_QUARTILE_CONSTANTS } from '../../constants/bypassQuartile';
import { DriscollsAuthenticationUtilities, useAuth } from '@driscollsinc/login-module-ui';
import { useApplicationDataContext } from '../../hooks/useApplicationDataContext';
import { WarehouseData } from '../../components/userManagement/warehouse/Types';
import useCheckUserGroup from '../../hooks/useCheckUserGroup';
import { useHttp } from '../../hooks/useHttp';
import APIEndPoints from '../../api';
import { TUserManagementList } from '../../types/userManagement';
import { useGetStaticData } from '../../hooks/useGetStaticData';
import { convertToStringArr, sortAndRemoveDuplicatesFromArray } from '../../../utils/format';
import { BYPASS_LOCATION_CONSTANTS } from '../../constants/bypassConstants';
import { routes, routesName } from '../../routes/routes';
import useDebounce from '../../hooks/useDebounce';
import { BYPASS_OVERRIDE_CONSTANTS } from '../../constants/bypassOverride';
import { format } from 'date-fns';
import { EventConstants, logEvent } from '../../../utils/analytics';

const useBypassQuartile = () => {
    const navigate = useNavigate();
    const { isDemeaAdminUser } = useCheckUserGroup();
    const isDemeaAdminUserFlag = isDemeaAdminUser();
    const { showError, showSuccess } = useApplicationContext();
    const itemsPerPage = 10;
    const { getAccessToken } = useAuth();
    const { selectedBerryType, setSelectedBerryType, setBypassQuartileData, userEmail, setUserEmail } = useApplicationDataContext();
    const defaultPercentage = [BYPASS_QUARTILE_CONSTANTS.Q1, BYPASS_QUARTILE_CONSTANTS.Q2].join(',');
    const { getVarietyList, getEstimateGroupList } = useGetStaticData();
    const defaultValues = {
        BerryType: {
            label: selectedBerryType,
            value: selectedBerryType
        }
    };

    const [data, setData] = useState<BypassLocationResponse>();
    const [filterOptions, setFilterOptions] = useState<IBypassQuartileGridFilterOptions>();
    const [warehouseFilter, setWarehouseFilter] = useState<string>();
    const [page, setPage] = useState<number>(1);
    const [warehouseList, setWarehouseList] = useState<WarehouseData[]>();
    const [loading, setLoading] = useState<boolean>(false);
    const [warehouseLoading, setWarehouseLoading] = useState<boolean>(false);
    const [showTable, setShowTable] = useState<boolean>(false);
    const [warehouseNbr, setWarehouseNbr] = useState<string>();
    const [createdByFilter, setCreatedByFilter] = useState<string>('');
    const [estimateGroupFilter, setEstimateGroupFilter] = useState<string>('');
    const [varietyFilter, setVarietyFilter] = useState<string>('');
    const [createdDateFilter, setCreatedDateFilter] = useState<string>('');
    const [percentageFilter, setPercentageFilter] = useState<string>(defaultPercentage);

    const { postData } = useHttp<BypassLocationPost>(APIEndPoints.BYPASS_LOCATION_POST());
    const { getData: getUserWarehouseData } = useHttp<TUserManagementList>(
        APIEndPoints.USER_MANAGEMENT_GET(BYPASS_LOCATION_CONSTANTS.DEFAULT_PAGE, BYPASS_LOCATION_CONSTANTS.DEFAULT_PAGE_SIZE, '', userEmail)
    );
    const { getData } = useHttp<BypassLocationResponse>(
        APIEndPoints.BYPASS_QUARTILE_GET(
            warehouseFilter,
            selectedBerryType,
            estimateGroupFilter,
            varietyFilter,
            percentageFilter,
            createdByFilter,
            createdDateFilter && format(new Date(createdDateFilter), BYPASS_OVERRIDE_CONSTANTS.ISO_DATE_FORMAT),
            itemsPerPage,
            page
        )
    );
    const { getData: getWarehouseForAdmin } = useHttp<BypassLocationResponse>(
        APIEndPoints.BYPASS_QUARTILE_GET(
            warehouseNbr,
            selectedBerryType,
            '',
            '',
            BYPASS_QUARTILE_CONSTANTS.NONE,
            '',
            '',
            BYPASS_LOCATION_CONSTANTS.DEFAULT_PAGE_SIZE,
            BYPASS_LOCATION_CONSTANTS.DEFAULT_PAGE,
            'true'
        )
    );

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await getData();
            setData(response);
            setShowTable(true);
        } catch (error) {
            console.log(error.message);
            showError(error);
        } finally {
            setLoading(false);
        }
    };

    const fetchUserData = async () => {
        try {
            const response = await getUserWarehouseData();
            const stringArr = convertToStringArr(response?.Data, BYPASS_QUARTILE_CONSTANTS.WAREHOUSE_NBR);
            setWarehouseFilter(stringArr.join(','));
            setWarehouseNbr(stringArr.join(','));
        } catch (error) {
            console.log(error.message);
            showError(error);
        }
    };

    const fetchWarehouseList = async () => {
        setWarehouseLoading(true);
        try {
            const response = await getWarehouseForAdmin();
            setWarehouseList(response?.Data);
            const warehouseOptions = convertToStringArr(response?.Data, BYPASS_QUARTILE_CONSTANTS.WAREHOUSE_NBR);
            if (!isDemeaAdminUserFlag) {
                setFilterOptions({
                    warehouse: warehouseOptions && sortAndRemoveDuplicatesFromArray(warehouseOptions),
                    variety: convertToStringArr(getVarietyList(), BYPASS_QUARTILE_CONSTANTS.VARIETY_DESCRIPTION),
                    estimateGroup: getEstimateGroupList(),
                    percentage: BYPASS_QUARTILE_CONSTANTS.PERCENTAGE_OPTIONS
                });
            }
        } catch (error) {
            console.log(error.message);
            showError(error);
        } finally {
            setWarehouseLoading(false);
        }
    };

    useEffect(() => {
        logEvent(EventConstants.PAGE_VIEW, { page_title: `${EventConstants.DEMEA} - ${EventConstants.BYPASS_QUARTILE}` });
    }, []);

    useEffect(() => {
        if (!isDemeaAdminUserFlag) {
            const fetchAccessToken = async () => {
                const token = await getAccessToken();
                const user = DriscollsAuthenticationUtilities.GetEmail(token);
                setUserEmail(user);
            };
            fetchAccessToken();
        } else {
            setWarehouseNbr(BYPASS_QUARTILE_CONSTANTS.ALL);
        }
    }, [isDemeaAdminUserFlag]);

    useEffect(() => {
        if (warehouseNbr) {
            fetchWarehouseList();
        }
    }, [selectedBerryType, warehouseNbr, isDemeaAdminUserFlag]);

    useEffect(() => {
        if (userEmail && !isDemeaAdminUserFlag) {
            fetchUserData();
        }
    }, [userEmail, isDemeaAdminUserFlag]);

    useEffect(() => {
        if (warehouseFilter) {
            debouncedFetchData();
        }
    }, [
        page,
        warehouseFilter,
        selectedBerryType,
        varietyFilter,
        createdByFilter,
        createdDateFilter,
        percentageFilter,
        estimateGroupFilter,
        warehouseList
    ]);

    useEffect(() => {
        setPage(1);
    }, [warehouseFilter, varietyFilter, estimateGroupFilter, percentageFilter, createdByFilter, createdDateFilter]);

    const debouncedFetchData = useDebounce(fetchData, 500);

    const onDelete = async (row) => {
        const token = await getAccessToken();
        const user = DriscollsAuthenticationUtilities.GetEmail(token);
        const currentDate = new Date();
        const payload = {
            [BYPASS_LOCATION_CONSTANTS.INSPECTIONS]: [
                {
                    [BYPASS_LOCATION_CONSTANTS.BERRY_TYPE]: row?.BerryType,
                    [BYPASS_LOCATION_CONSTANTS.WAREHOUSE_NBR]: row?.WarehouseNbr,
                    [BYPASS_LOCATION_CONSTANTS.BYPASS]: false,
                    [BYPASS_LOCATION_CONSTANTS.PERCENTAGE]: BYPASS_QUARTILE_CONSTANTS.NONE,
                    [BYPASS_QUARTILE_CONSTANTS.VARIETY]: row?.Variety,
                    [BYPASS_QUARTILE_CONSTANTS.ESTIMATED_GROUP]: row?.EstimateGroup,
                    [BYPASS_LOCATION_CONSTANTS.BUSINESS_UNIT]: BYPASS_LOCATION_CONSTANTS.DEMEA
                }
            ],
            [BYPASS_LOCATION_CONSTANTS.LAST_UPDATED_BY]: user,
            [BYPASS_LOCATION_CONSTANTS.LAST_UPDATED_DATE]: currentDate.toISOString()
        };

        try {
            await postData(payload as BypassLocationPost);
            showSuccess('The Quartile has been deleted successfully!');
            logEvent(EventConstants.DELETE_ACTION, {
                event_info: `${EventConstants.DEMEA} - ${EventConstants.DELETE} ${EventConstants.BYPASS_QUARTILE}`
            });
            await fetchData();
        } catch (error) {
            showError(error?.message || 'Something went wrong!');
        }
    };

    const onEdit = (row) => {
        setBypassQuartileData(row);
        logEvent(EventConstants.EDIT_ACTION, { event_info: `${EventConstants.DEMEA} - ${EventConstants.EDIT} ${EventConstants.BYPASS_QUARTILE}` });
        navigate(routes[routesName.BYPASS_EDIT_QUARTILE].getRoute(row?.T_InspectionBypassId));
    };

    const goToAddBypassQuartile = () => {
        setBypassQuartileData((prev) => {
            return { ...prev, BerryType: selectedBerryType };
        });
        logEvent(EventConstants.ADD_ACTION, { event_info: `${EventConstants.DEMEA} - ${EventConstants.ADD} ${EventConstants.BYPASS_QUARTILE}` });
        navigate(`${routes[routesName.BYPASS_ADD_QUARTILE].path}`);
    };

    const goBack = () => {
        navigate(`../`);
    };

    useEffect(() => {
        if (isDemeaAdminUserFlag && warehouseList) {
            const warehouseOptions = convertToStringArr(warehouseList, BYPASS_QUARTILE_CONSTANTS.WAREHOUSE_NBR);
            setFilterOptions({
                warehouse: warehouseOptions && sortAndRemoveDuplicatesFromArray(warehouseOptions),
                variety: convertToStringArr(getVarietyList(), BYPASS_QUARTILE_CONSTANTS.VARIETY_DESCRIPTION),
                estimateGroup: getEstimateGroupList(),
                percentage: BYPASS_QUARTILE_CONSTANTS.PERCENTAGE_OPTIONS
            });
            if (!warehouseFilter) {
                setWarehouseFilter(convertToStringArr(warehouseList, BYPASS_QUARTILE_CONSTANTS.WAREHOUSE_NBR).join(','));
            }
        }
    }, [warehouseList, data]);

    const resetFilters = () => {
        const warehouseOptions = isDemeaAdminUserFlag
            ? convertToStringArr(warehouseList, BYPASS_QUARTILE_CONSTANTS.WAREHOUSE_NBR).join(',')
            : warehouseNbr;
        setWarehouseFilter(warehouseOptions);
        setVarietyFilter('');
        setPercentageFilter(defaultPercentage);
        setCreatedByFilter('');
        setCreatedDateFilter('');
        setEstimateGroupFilter('');
    };

    const onFilterChange = (filters) => {
        resetFilters();
        filters.forEach((filter) => {
            switch (filter.id) {
                case BYPASS_QUARTILE_CONSTANTS.WAREHOUSE_NBR:
                    if (filter.value !== warehouseFilter) {
                        setPage(1);
                    }
                    setWarehouseFilter(filter.value);
                    break;
                case BYPASS_QUARTILE_CONSTANTS.VARIETY:
                    if (filter.value !== varietyFilter) {
                        setPage(1);
                    }
                    setVarietyFilter(filter.value);

                    break;
                case BYPASS_QUARTILE_CONSTANTS.ESTIMATED_GROUP:
                    if (filter.value !== estimateGroupFilter) {
                        setPage(1);
                    }
                    setEstimateGroupFilter(filter.value);
                    break;
                case BYPASS_QUARTILE_CONSTANTS.PERCENTAGE:
                    if (filter.value !== percentageFilter) {
                        setPage(1);
                    }
                    const percentageValue = filter.value === BYPASS_QUARTILE_CONSTANTS.Q1_AND_Q2 ? BYPASS_QUARTILE_CONSTANTS.Q2 : filter.value;
                    setPercentageFilter(percentageValue);
                    break;
                case BYPASS_QUARTILE_CONSTANTS.CREATED_BY:
                    if (filter.value !== createdByFilter) {
                        setPage(1);
                    }
                    setCreatedByFilter(filter.value);
                    break;
                case BYPASS_QUARTILE_CONSTANTS.CREATED_DATE_TIME:
                    if (filter.value !== createdDateFilter) {
                        setPage(1);
                    }
                    setCreatedDateFilter(filter.value);
                    break;
                default:
                    break;
            }
        });
    };

    const handleBerryTypeChange = (selectedOption) => {
        setSelectedBerryType(selectedOption?.value);
        setShowTable(false);
    };

    return {
        data,
        page,
        setPage,
        itemsPerPage,
        loading,
        filterOptions,
        showTable,
        defaultValues,
        onDelete,
        onEdit,
        goBack,
        goToAddBypassQuartile,
        onFilterChange,
        handleBerryTypeChange,
        warehouseLoading
    };
};

export default useBypassQuartile;
