import { DrcTooltip, DrcIcons } from 'driscolls-react-components';
import { DuDateUtilities } from 'driscolls-react-utilities';
import { ReactComponent as SimulateIcon } from '../../images/simulate.svg';
import { ButtonSize, ButtonVariant, DriscollsButton, DriscollsDataTableExpander, DriscollsHContainer } from '@driscollsinc/style-guide-web2.0';
import {
    DISTRICT_GROUP,
    ESTIMATE_GROUP,
    LABEL,
    BERRY_TYPE,
    PRODUCT_ATTRIBUTE,
    POOL_WEEK,
    MIN_POINTS,
    MAX_POINTS,
    SPREAD,
    VARIETY_DESCRIPTION,
    STATUS,
    MODIFIED_BY,
    MODIFIED_DATE_TIME
} from '../../language/english';
import style from './PointMatrixTable.module.css';

const allBerries = (berryList) => (
    <>
        {(berryList === 'BLACK' || berryList === 1) && (
            <DrcTooltip tipText="BLACK">
                <span style={!(berryList.includes('BLACK') || berryList.includes(1)) ? { opacity: '0.1' } : null}>
                    {DrcIcons.GetSmallBerryIcon('BLACK')}
                </span>
            </DrcTooltip>
        )}
        {(berryList === 'BLUE' || berryList === 2) && (
            <DrcTooltip tipText="BLUE">
                <span style={!(berryList.includes('BLUE') || berryList.includes(2)) ? { opacity: '0.1' } : null}>
                    {DrcIcons.GetSmallBerryIcon('BLUE')}
                </span>
            </DrcTooltip>
        )}
        {(berryList === 'RASP' || berryList === 3) && (
            <DrcTooltip tipText="RASP">
                <span style={!(berryList.includes('RASP') || berryList.includes(3)) ? { opacity: '0.1' } : null}>
                    {DrcIcons.GetSmallBerryIcon('RASP')}
                </span>
            </DrcTooltip>
        )}
        {(berryList === 'STRAW' || berryList === 4) && (
            <DrcTooltip tipText="STRAW">
                <span style={!(berryList.includes('STRAW') || berryList.includes(4)) ? { opacity: '0.1' } : null}>
                    {DrcIcons.GetSmallBerryIcon('STRAW')}
                </span>
            </DrcTooltip>
        )}
    </>
);

const BerryFormatter = (data) => {
    const value = data.getValue();
    return value ? allBerries(value) : null;
};

const columnSize = { size: 80, minSize: 80 };

const ellipsisRender = (data) => {
    const value = data.getValue();
    return (
        <DrcTooltip tipText={value}>
            <span className={style.ellipsis}>{value}</span>
        </DrcTooltip>
    );
};

const getColumns = (handleSimulate) => {
    return [
        {
            id: 'expander',
            size: 60,
            minSize: 60,
            enablePinning: true,
            header: () => null,
            cell: ({ row }) => {
                return (
                    <DriscollsHContainer className={style.actionColumn} noPadding>
                        <DriscollsDataTableExpander row={row} />
                        {(window.config.ENV === 'dev' || window.config.ENV === 'localhost' || window.config.ENV === 'test') && (
                            <DrcTooltip tipText="Simulate">
                                <DriscollsButton
                                    variant={ButtonVariant.TEXT}
                                    buttonSize={ButtonSize.SMALL}
                                    style={{ cursor: row?.original?.Status === 'Active' && 'pointer' }}
                                    onClick={() => handleSimulate(row)}
                                    disabled={row?.original?.Status === 'Inactive'}
                                >
                                    <SimulateIcon height="15px" width="15px" fill={row?.original?.Status === 'Active' ? '#0272ec' : '#aaaaaa'} />
                                </DriscollsButton>
                            </DrcTooltip>
                        )}
                    </DriscollsHContainer>
                );
            }
        },
        {
            accessorKey: 'QMPDistrictGroup',
            header: (
                <DrcTooltip tipText="District Group">
                    <span>{DISTRICT_GROUP}</span>
                </DrcTooltip>
            ),
            ...columnSize
        },
        {
            accessorKey: 'Label',
            header: (
                <DrcTooltip tipText="Label">
                    <span>{LABEL}</span>
                </DrcTooltip>
            ),
            ...columnSize
        },
        {
            accessorKey: 'CommodityCode',
            header: (
                <DrcTooltip tipText="Berry Type">
                    <span>{BERRY_TYPE}</span>
                </DrcTooltip>
            ),
            ...columnSize,
            cell: BerryFormatter
        },
        {
            accessorKey: 'EstimateGroup',
            header: (
                <DrcTooltip tipText="Estimate Group">
                    <span>{ESTIMATE_GROUP}</span>
                </DrcTooltip>
            ),
            ...columnSize,
            cell: ellipsisRender
        },
        {
            accessorKey: 'AttributeType',
            header: (
                <DrcTooltip tipText="Product Attribute">
                    <span>{PRODUCT_ATTRIBUTE}</span>
                </DrcTooltip>
            ),
            ...columnSize
        },
        {
            accessorKey: 'Status',
            header: (
                <DrcTooltip tipText="Status">
                    <span>{STATUS}</span>
                </DrcTooltip>
            ),
            ...columnSize,
            cell: (data) => {
                const value = data.getValue();
                return (
                    <span className={`${style.actionRow} ${value === 'Active' ? style.activeStatus : style.inActiveStatus}`} title={value}>
                        {value}
                    </span>
                );
            }
        },
        {
            accessorKey: 'VarietyDescription',
            header: (
                <DrcTooltip tipText="Variety Description">
                    <span>{VARIETY_DESCRIPTION}</span>
                </DrcTooltip>
            ),
            ...columnSize
        },
        {
            accessorKey: 'PoolWeek',
            header: (
                <DrcTooltip tipText="PoolWeek">
                    <span>{POOL_WEEK}</span>
                </DrcTooltip>
            ),
            ...columnSize
        },
        {
            accessorKey: 'MinPoint',
            header: (
                <DrcTooltip tipText="Min Points">
                    <span>{MIN_POINTS}</span>
                </DrcTooltip>
            ),
            ...columnSize
        },
        {
            accessorKey: 'MaxPoint',
            header: (
                <DrcTooltip tipText="Max Points">
                    <span>{MAX_POINTS}</span>
                </DrcTooltip>
            ),
            ...columnSize
        },
        {
            accessorKey: 'Spread',
            header: (
                <DrcTooltip tipText="Spread">
                    <span>{SPREAD}</span>
                </DrcTooltip>
            ),
            ...columnSize
        },
        {
            accessorKey: 'CreatedBy',
            header: (
                <DrcTooltip tipText={MODIFIED_BY}>
                    <span>{MODIFIED_BY}</span>
                </DrcTooltip>
            ),
            ...columnSize,
            cell: (data) => {
                const value = data.getValue();
                return (
                    <a className={style.emailValue + style.ellipsis} href={'mailto:' + value} title={value}>
                        {value}
                    </a>
                );
            }
        },
        {
            accessorKey: 'CreatedDatetime',
            header: (
                <DrcTooltip tipText={MODIFIED_DATE_TIME}>
                    <span>{MODIFIED_DATE_TIME}</span>
                </DrcTooltip>
            ),
            ...columnSize,
            cell: (data) => {
                const value = data.getValue();
                return (
                    <span className={style.dateTime + style.ellipsis} title={DuDateUtilities.FormatDateTimeFromIso(value)}>
                        {DuDateUtilities.FormatDateTimeFromIso(value)}
                    </span>
                );
            }
        }
    ];
};

export default getColumns;
