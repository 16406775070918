import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { lookUpValues, resetSelectedIndex } from '../../actions/PointMatrixAction';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import APIEndPoints from '../../services/api';
import { Middleware } from 'one-ring';
import { DriscollsDataTable, DriscollsHContainer } from '@driscollsinc/style-guide-web2.0';
import style from './PointMatrixTable.module.css';
import getColumns from './PointMatrixTableUtility';
import PointMatrixScoreTable from './PointMatrixScoreTable';
import { withAuth } from '@driscollsinc/login-module-ui';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const PointMatrixTable = (props) => {
    const { previewMatrixData } = props;
    const [state, setState] = useState({
        selectedIndexes: '',
        showPreviewDialog: false,
        messageDialog: {
            showMessage: false,
            message: '',
            severity: 'success'
        }
    });

    useEffect(() => {
        if (props.isResetSelectedIndex) {
            setState({ ...state, selectedIndexes: '' });
            props.resetSelectedIndex(false);
        }
    }, [props.isResetSelectedIndex]);

    const handleSimulate = (row) => {
        let payload = {
            PointMatrixId: row.PointMatrixId,
            QMPDistrictGroup: row.QMPDistrictGroup,
            Label: row.Label,
            CommodityCode: row.CommodityCode,
            EstimateGroup: row.EstimateGroup,
            VarietyCode: (row.VarietyCode || '').toLowerCase() === 'default' ? null : row.VarietyCode,
            PoolWeek: (row.PoolWeek || '').toLowerCase() === 'default' ? null : row.PoolWeek,
            RequestBy: props.loggedInUser
        };
        //handle simulation i.e API call
        props.auth.getAccessToken().then((token) => {
            Middleware.Send('simulate', token, `${APIEndPoints.SIMULATE}`, 'POST', payload, {
                overrideRequestMapping: true,
                loadingMessage: 'Simulating ...'
            })
                .then((_res) => {
                    setState({
                        ...state,
                        messageDialog: {
                            showMessage: true,
                            message: 'Simulation request submitted successfully',
                            severity: 'success'
                        }
                    });
                })
                .catch((err) => {
                    setState({
                        ...state,
                        messageDialog: {
                            showMessage: true,
                            message: err.data.response.data.message,
                            severity: 'error'
                        }
                    });
                });
        });
    };

    const handleClose = (reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setState((state) => ({
            ...state,
            messageDialog: { ...state.messageDialog, showMessage: false }
        }));
    };

    const renderExpandedContent = (_id, row) => (
        <PointMatrixScoreTable preview={true} rows={row?.PointMatrixLookUpValues} className={style.scoreTableContainer} />
    );

    return (
        <DriscollsHContainer className={style.container}>
            <DriscollsDataTable
                data={previewMatrixData}
                columns={getColumns(handleSimulate)}
                hidePagination={true}
                renderExpandedContent={renderExpandedContent}
            />
            <Snackbar open={state.messageDialog.showMessage} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={state.messageDialog.severity}>
                    {state.messageDialog.message}
                </Alert>
            </Snackbar>
        </DriscollsHContainer>
    );
};

const mapStateToProps = (state) => {
    return {
        previewMatrixData: state.pointMatrixReducer.previewMatrixData,
        isResetSelectedIndex: state.pointMatrixReducer.isResetSelectedIndex,
        selectFirstRow: state.pointMatrixReducer.selectFirstRow,
        loggedInUser: state.masterReducer.loggedInUser
    };
};

export default connect(mapStateToProps, { lookUpValues, resetSelectedIndex })(withAuth(PointMatrixTable));
