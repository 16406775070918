import {
    yupResolver,
    DriscollsTranslatedValue,
    DriscollsButton,
    ApplicationPageTitle,
    useLang,
    DriscollsTextField,
    ApplicationPage,
    useForm,
    DriscollsHContainer,
    ButtonVariant
} from '@driscollsinc/style-guide-web2.0';
import WarehouseSelect from '../../components/userManagement/warehouse';
import { USER_MANAGEMENT_CONSTANTS } from '../../constants/userManagementConstants';
import { NAMESPACE } from '../../../i18n';
import { UserManagementSchema } from './FormSchema';
import styles from './index.module.scss';
import { useHttp } from '../../hooks/useHttp';
import { UserManagementPost } from './Types';
import APIEndPoints from '../../api';
import { useApplicationContext } from '../../hooks/useApplicationContext';
import { useNavigate } from 'react-router-dom';
import { routes, routesName } from '../../routes/routes';
import { DriscollsAuthenticationUtilities, useAuth } from '@driscollsinc/login-module-ui';

const NewUserManagement = () => {
    const {
        handleSubmit,
        setValue,
        formState: { errors, isValid }
    } = useForm({
        resolver: yupResolver(UserManagementSchema),
        mode: 'onTouched',
        defaultValues: { Warehouse: '', Email: '' }
    });
    const { showSuccess, showError, setShowLoading } = useApplicationContext();
    const { getAccessToken } = useAuth();
    const { getTextTranslated } = useLang([NAMESPACE.USER_MANAGEMENT]);
    const navigate = useNavigate();
    const { postData } = useHttp<UserManagementPost>(APIEndPoints.USER_MANAGEMENT_POST());

    const handleWarehouseChange = (selectedOptions: any) => {
        setValue(
            USER_MANAGEMENT_CONSTANTS.WAREHOUSE,
            selectedOptions?.map((option) => option.value),
            { shouldValidate: true }
        );
    };

    const handleEmailChange = (e: { target: { value: string } }) => {
        setValue(USER_MANAGEMENT_CONSTANTS.EMAIL, e.target.value, { shouldValidate: true });
    };

    const goToUserList = () => {
        navigate(`/demea/${routes[routesName.USER_MANAGEMENT].path}`);
    };

    const onSubmit = async (data) => {
        setShowLoading(true);
        const token = await getAccessToken();
        const user = DriscollsAuthenticationUtilities.GetEmail(token);
        const payload = {
            [USER_MANAGEMENT_CONSTANTS.WAREHOUSE_NBR]: data.Warehouse,
            [USER_MANAGEMENT_CONSTANTS.LOCAL_QA_MANAGER_EMAIL]: data.Email,
            [USER_MANAGEMENT_CONSTANTS.BUSINESS_UNIT]: USER_MANAGEMENT_CONSTANTS.DEMEA,
            [USER_MANAGEMENT_CONSTANTS.CREATED_BY]: user,
            [USER_MANAGEMENT_CONSTANTS.MODIFIED_BY]: user
        } as UserManagementPost;
        try {
            const response = await postData(payload);
            showSuccess('User has been assigned to the warehouses successfully!');
            if (response?.data) {
                goToUserList();
            }
        } catch (error) {
            showError(error?.message || 'Something went wrong!');
        } finally {
            setShowLoading(false);
        }
    };

    return (
        <ApplicationPage className={styles.userManagementPage}>
            <ApplicationPageTitle
                title={{ text: 'User Management', nameSpace: NAMESPACE.COMMON }}
                description={{ text: 'Assign Warehouse', nameSpace: NAMESPACE.USER_MANAGEMENT }}
            />
            <form onSubmit={handleSubmit(onSubmit)} className={styles.formWrapper}>
                <DriscollsHContainer className={styles.fieldWrapper}>
                    <WarehouseSelect defaultOptions={true} styles={styles} onChange={handleWarehouseChange} error={errors.Warehouse?.message} />
                    <DriscollsTextField
                        className={styles.textField}
                        label={getTextTranslated('Email', NAMESPACE.USER_MANAGEMENT)}
                        name="Email"
                        error={errors.Email?.message}
                        onChange={handleEmailChange}
                        onBlur={handleEmailChange}
                        type="text"
                    />
                </DriscollsHContainer>
                <DriscollsHContainer className={styles.rightAlign}>
                    <DriscollsButton
                        className={styles.btnLink}
                        label={<DriscollsTranslatedValue value="Cancel" />}
                        onClick={goToUserList}
                        variant={ButtonVariant.SECONDARY}
                    />
                    <DriscollsButton
                        className={styles.btnLink}
                        label={<DriscollsTranslatedValue value="Submit" />}
                        onClick={handleSubmit(onSubmit)}
                        disabled={!isValid}
                    />
                </DriscollsHContainer>
            </form>
        </ApplicationPage>
    );
};

export default NewUserManagement;
