import { useNavigate } from 'react-router-dom';
import useCheckUserGroup from '../../hooks/useCheckUserGroup';
import { useApplicationContext } from '../../hooks/useApplicationContext';
import { useApplicationDataContext } from '../../hooks/useApplicationDataContext';
import { useEffect, useState } from 'react';
import { IBypassOverrideGridFilterOptions } from '../../components/bypass/BypassOverrideGrid';
import { BypassLocationResponse, BypassOverridePost, BypassOverrideResponse } from './Types';
import { RanchData, RanchResponse } from '../../components/bypass/ranch/types';
import { useHttp } from '../../hooks/useHttp';
import { WarehouseResponse } from '../../components/userManagement/warehouse/Types';
import APIEndPoints from '../../api';
import { TUserManagementList } from '../../types/userManagement';
import { convertToStringArr } from '../../../utils/format';
import useDebounce from '../../hooks/useDebounce';
import { BYPASS_OVERRIDE_CONSTANTS } from '../../constants/bypassOverride';
import { routes, routesName } from '../../routes/routes';
import { BYPASS_LOCATION_CONSTANTS } from '../../constants/bypassConstants';
import { DriscollsAuthenticationUtilities, useAuth } from '@driscollsinc/login-module-ui';
import { format } from 'date-fns';
import { EventConstants, logEvent } from '../../../utils/analytics';

const useBypassOverride = () => {
    const navigate = useNavigate();
    const { isDemeaAdminUser } = useCheckUserGroup();
    const isDemeaAdminUserFlag = isDemeaAdminUser();
    const { showError, showSuccess } = useApplicationContext();
    const { setBypassOverrideData, setUserEmail, userEmail } = useApplicationDataContext();
    const itemsPerPage = 10;
    const { getAccessToken } = useAuth();

    const [page, setPage] = useState<number>(1);
    const [filterOptions, setFilterOptions] = useState<IBypassOverrideGridFilterOptions>();
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setDate] = useState<BypassOverrideResponse>();
    const [warehouseList, setWarehouseList] = useState<string>();
    const [warehouseFilter, setWarehouseFilter] = useState<string>();
    const [ranchFilter, setRanchFilter] = useState<string>();
    const [dateFilter, setDateFilter] = useState<string>();
    const [createdByFilter, setCreatedByFilter] = useState<string>();
    const [warehouseListForUsers, setWarehouseListForUsers] = useState<string>();
    const [ranchList, setRanchList] = useState<RanchData[]>();

    const { getData: getWarehouseListForAdmin } = useHttp<WarehouseResponse>(APIEndPoints.GET_WAREHOUSES());
    const { getData: getRanchList } = useHttp<RanchResponse>(APIEndPoints.GET_RANCHES(warehouseList));
    const { getData } = useHttp<BypassOverrideResponse>(
        APIEndPoints.BYPASS_OVERRIDE(itemsPerPage, page, warehouseFilter, '', ranchFilter, createdByFilter, dateFilter)
    );
    const { postData } = useHttp<BypassOverridePost>(APIEndPoints.BYPASS_OVERRIDE_POST());
    const { getData: getUserData } = useHttp<TUserManagementList>(APIEndPoints.USER_MANAGEMENT_GET(1, 50, '', userEmail));
    const { getData: getWarehouseListForUser } = useHttp<BypassLocationResponse>(
        APIEndPoints.BYPASS_LOCATION_GET(warehouseListForUsers, BYPASS_LOCATION_CONSTANTS.ALL, BYPASS_LOCATION_CONSTANTS.NONE)
    );

    // Common APIs for Admin and User
    const fetchRanchData = async () => {
        try {
            const response = await getRanchList();
            setRanchList(response?.Data);
        } catch (error) {
            console.log(error.message);
        }
    };

    const fetchDataForOverride = async () => {
        setLoading(true);
        try {
            const response = await getData();
            setDate(response);
        } catch (error) {
            console.log(error.message);
        } finally {
            setLoading(false);
        }
    };

    // API for admin
    const fetchWarehouseDataForAdmin = async () => {
        try {
            const response = await getWarehouseListForAdmin();
            const warehouseParam = convertToStringArr(response?.Data, 'WarehouseNbr').join(',');
            setWarehouseList(warehouseParam);
            setWarehouseFilter(warehouseParam);
        } catch (error) {
            console.log(error.message);
        }
    };

    //API for users/manager
    const fetchUserData = async () => {
        try {
            const response = await getUserData();
            const stringArr = convertToStringArr(response?.Data, BYPASS_OVERRIDE_CONSTANTS.WAREHOUSE_NBR).join(',');
            setWarehouseListForUsers(stringArr);
        } catch (error) {
            console.log(error.message);
        }
    };

    const fetchWarehouseForUser = async () => {
        try {
            const response = await getWarehouseListForUser();
            const stringArr = convertToStringArr(response?.Data, BYPASS_OVERRIDE_CONSTANTS.WAREHOUSE_NBR).join(',');
            setWarehouseFilter(stringArr);
            setWarehouseList(stringArr);
        } catch (error) {
            console.log(error.message);
        }
    };
    const debouncedFetchData = useDebounce(fetchDataForOverride, 500);

    useEffect(() => {
        logEvent(EventConstants.PAGE_VIEW, { page_title: `${EventConstants.DEMEA} - ${EventConstants.BYPASS_OVERRIDE}` });
    }, []);

    useEffect(() => {
        if (isDemeaAdminUserFlag) {
            fetchWarehouseDataForAdmin();
        } else {
            const fetchAccessToken = async () => {
                const token = await getAccessToken();
                const user = DriscollsAuthenticationUtilities.GetEmail(token);
                setUserEmail(user);
            };
            fetchAccessToken();
        }
    }, [isDemeaAdminUserFlag]);

    useEffect(() => {
        if (!isDemeaAdminUserFlag && userEmail) {
            fetchUserData();
        }
    }, [userEmail, isDemeaAdminUserFlag]);

    useEffect(() => {
        if (!isDemeaAdminUserFlag && warehouseListForUsers) {
            fetchWarehouseForUser();
        }
    }, [warehouseListForUsers, isDemeaAdminUserFlag]);

    useEffect(() => {
        if (warehouseList) {
            fetchRanchData();
        }
    }, [warehouseList]);

    useEffect(() => {
        if (warehouseFilter) {
            debouncedFetchData();
        }
    }, [page, warehouseFilter, ranchFilter, createdByFilter, dateFilter]);

    useEffect(() => {
        const warehouseOptions = warehouseList?.split(',');
        setFilterOptions({
            warehouse: warehouseOptions?.filter((item, index) => warehouseOptions.indexOf(item) === index),
            ranch: ranchList?.map((ranch) => `${ranch.RanchNbr} - ${ranch.RanchName}`)
        });
    }, [warehouseList, ranchList]);

    useEffect(() => {
        setPage(1);
    }, [warehouseFilter, ranchFilter, createdByFilter, dateFilter]);

    const resetFilters = () => {
        setWarehouseFilter(warehouseList);
        setRanchFilter('');
        setCreatedByFilter('');
        setDateFilter(null);
    };

    const onFilterChange = (filters) => {
        resetFilters();
        filters.forEach((filter) => {
            switch (filter.id) {
                case BYPASS_OVERRIDE_CONSTANTS.WAREHOUSE_NBR:
                    if (filter.value !== warehouseFilter) {
                        setPage(1);
                    }
                    setWarehouseFilter(filter.value);
                    break;
                case BYPASS_OVERRIDE_CONSTANTS.RANCH_NUMBER:
                    if (filter.value !== ranchFilter) {
                        setPage(1);
                    }
                    setRanchFilter(filter.value.split(' ')[0]);
                    break;
                case BYPASS_OVERRIDE_CONSTANTS.MODIFIED_BY:
                    if (filter.value !== createdByFilter) {
                        setPage(1);
                    }
                    setCreatedByFilter(filter.value);
                    break;
                case BYPASS_OVERRIDE_CONSTANTS.START_DATE:
                    if (filter.value !== dateFilter) {
                        setPage(1);
                    }
                    setDateFilter(filter.value);
                    break;
                default:
                    break;
            }
        });
    };

    const onDelete = async (row) => {
        const parsedStartDate = new Date(row.StartDate);
        const parsedEndDate = new Date(row.EndDate);

        const payload = {
            [BYPASS_OVERRIDE_CONSTANTS.RANCH_NUMBER]: row?.RanchNbr,
            [BYPASS_OVERRIDE_CONSTANTS.RANCH_NAME]: row?.RanchName,
            [BYPASS_OVERRIDE_CONSTANTS.IS_DELETED]: true,
            [BYPASS_OVERRIDE_CONSTANTS.START_DATE]: format(parsedStartDate, BYPASS_OVERRIDE_CONSTANTS.ISO_DATE_FORMAT),
            [BYPASS_OVERRIDE_CONSTANTS.END_DATE]: format(parsedEndDate, BYPASS_OVERRIDE_CONSTANTS.ISO_DATE_FORMAT),
            [BYPASS_OVERRIDE_CONSTANTS.BUSINESS_UNIT]: BYPASS_OVERRIDE_CONSTANTS.DEMEA,
            [BYPASS_OVERRIDE_CONSTANTS.WAREHOUSE_NBR]: row.WarehouseNbr
        };
        try {
            await postData(payload as BypassOverridePost);
            showSuccess('Ranch Override deleted successfully!');
            logEvent(EventConstants.DELETE_ACTION, {
                event_info: `${EventConstants.DEMEA} - ${EventConstants.DELETE} ${EventConstants.BYPASS_OVERRIDE}`
            });
            await fetchDataForOverride();
        } catch (error) {
            showError(error?.message || 'Something went wrong!');
        }
    };

    const goToAddOverride = () => {
        logEvent(EventConstants.ADD_ACTION, { event_info: `${EventConstants.DEMEA} - ${EventConstants.ADD} ${EventConstants.BYPASS_OVERRIDE}` });
        navigate(routes[routesName.BYPASS_ADD_OVERRIDE].path);
    };

    const onEdit = (row) => {
        setBypassOverrideData(row);
        logEvent(EventConstants.EDIT_ACTION, { event_info: `${EventConstants.DEMEA} - ${EventConstants.EDIT} ${EventConstants.BYPASS_OVERRIDE}` });
        navigate(routes[routesName.BYPASS_EDIT_OVERRIDE].getRoute(row?.T_RanchBypassOverrideId));
    };

    const goBack = () => {
        navigate('../');
    };

    return {
        onEdit,
        goToAddOverride,
        onDelete,
        onFilterChange,
        loading,
        data,
        setPage,
        page,
        itemsPerPage,
        filterOptions,
        goBack
    };
};

export default useBypassOverride;
