import { DriscollsAuthenticationUtilities } from "@driscollsinc/login-module-ui";
import { getDemeaGroups, getAdminGroups, getDemeaAdminGroups, getDemeaManagerGroups, getAllDotaGroups } from "./getConfigData";

type AccessGroups = string[];

const hasUserGroups = (userGroups: AccessGroups, allowedGroups: AccessGroups): boolean =>
    userGroups.some(group => allowedGroups.includes(group));

const getUserGroups = (accessToken: string): AccessGroups =>
    DriscollsAuthenticationUtilities.GetGroups(accessToken) || [];

export const isAdminUserFn = (accessToken: string): boolean =>
    hasUserGroups(
        getUserGroups(accessToken),
        getAdminGroups()
    );

export const isDotaUserFn = (accessToken: string): boolean =>
    hasUserGroups(
        getUserGroups(accessToken),
        getAllDotaGroups()
    );

export const isDemeaAdminFn = (accessToken: string): boolean =>
    hasUserGroups(
        getUserGroups(accessToken),
        getDemeaAdminGroups()
    );

export const isDemeaManagerFn = (accessToken: string): boolean =>
    hasUserGroups(
        getUserGroups(accessToken),
        getDemeaManagerGroups()
    );

export const isDemeaUserFn = (accessToken: string): boolean =>
    hasUserGroups(
        getUserGroups(accessToken),
        getDemeaGroups()
    );