import {
    DirectionEnum,
    DriscollsComponentSwitcher,
    DriscollsDataTable,
    DriscollsTrashButton,
    DriscollsEditButton,
    DriscollsMobileList,
    useLang
} from '@driscollsinc/style-guide-web2.0';
import { DuDateUtilities } from '../../../../legacy/DriscollsReactUtilities';
import { KEY_MAPPING, USER_MANAGEMENT_CONSTANTS } from '../../../constants/userManagementConstants';
import { NAMESPACE } from '../../../../i18n';
import { useGetStaticData } from '../../../hooks/useGetStaticData';
import { BYPASS_QUARTILE_CONSTANTS } from '../../../constants/bypassQuartile';
import { BypassOverrideData } from '../../../pages/Bypass/Types';
import { BYPASS_OVERRIDE_CONSTANTS } from '../../../constants/bypassOverride';

export interface IBypassOverrideGridFilterOptions {
    warehouse: string[];
    ranch: string[];
}

interface IBypassOverrideGrid {
    loading: boolean;
    data: BypassOverrideData[];
    totalItem: number;
    itemPerPage: number;
    onPageChanged: (pageNumber: number) => void;
    selectedPage: number;
    filterOptions?: IBypassOverrideGridFilterOptions;
    onFilterChange: (filters: { id: string; value: string }[]) => void;
    onDelete: (row: any) => void;
    onEdit: (id: string) => void;
}

const BypassOverrideGrid = (props: IBypassOverrideGrid) => {
    const { loading, data, totalItem, itemPerPage, onPageChanged, selectedPage, filterOptions, onFilterChange, onDelete, onEdit } = props;
    const { getTextTranslated } = useLang([NAMESPACE.BYPASS]);
    const { transformData } = useGetStaticData();

    const formatData = (data: BypassOverrideData[]) =>
        data.map((row) => ({
            ...row,
            [BYPASS_OVERRIDE_CONSTANTS.START_DATE]: DuDateUtilities.ToPrettyDate(row[BYPASS_OVERRIDE_CONSTANTS.START_DATE]),
            [BYPASS_OVERRIDE_CONSTANTS.END_DATE]: DuDateUtilities.ToPrettyDate(row[BYPASS_OVERRIDE_CONSTANTS.END_DATE])
        }));

    const columns = [
        {
            id: 'action',
            size: 90,
            enablePinning: true,
            header: () => null,
            cell: ({ row }: any) => {
                return (
                    <>
                        <DriscollsEditButton onClick={() => onEdit(row.original)} />
                        <DriscollsTrashButton onClick={() => onDelete(row.original)} />
                    </>
                );
            }
        },
        {
            id: BYPASS_OVERRIDE_CONSTANTS.WAREHOUSE_NBR,
            accessorKey: BYPASS_QUARTILE_CONSTANTS.WAREHOUSE_NBR,
            header: getTextTranslated('Warehouse', NAMESPACE.BYPASS),
            enableColumnFilter: true,
            enablePinning: false,
            selectFilterOptions: filterOptions?.warehouse
        },
        {
            id: BYPASS_OVERRIDE_CONSTANTS.RANCH_NUMBER,
            accessorKey: BYPASS_OVERRIDE_CONSTANTS.RANCH_NUMBER,
            cell: ({ row }) => `${row.original.RanchNbr} - ${row.original.RanchName}`,
            header: getTextTranslated('Ranch', NAMESPACE.BYPASS),
            enableColumnFilter: true,
            enablePinning: false,
            selectFilterOptions: filterOptions?.ranch
        },
        {
            id: BYPASS_OVERRIDE_CONSTANTS.START_DATE,
            accessorKey: BYPASS_OVERRIDE_CONSTANTS.START_DATE,
            cell: ({ row }) => `${row.original.StartDate} - ${row.original.EndDate}`,
            header: getTextTranslated('Start Date - End Date', NAMESPACE.BYPASS),
            enableColumnFilter: true,
            dateFormatForFilter: 'MMM-dd-yyyy',
            enablePinning: false
        },

        {
            id: BYPASS_OVERRIDE_CONSTANTS.MODIFIED_BY,
            accessorKey: BYPASS_OVERRIDE_CONSTANTS.MODIFIED_BY,
            header: getTextTranslated('Created By', NAMESPACE.BYPASS),
            enableColumnFilter: true,
            enablePinning: false
        }
    ];

    const mobileListData = (data) => {
        const formattedData = formatData(data);
        const transformedData = formattedData.map((item) => transformData(item, KEY_MAPPING));
        return transformedData;
    };

    return (
        <DriscollsComponentSwitcher
            direction={DirectionEnum.width}
            componentList={[
                {
                    breakPoint: 799,
                    component: (
                        <DriscollsMobileList
                            isLoading={loading}
                            data={mobileListData(data)}
                            selectedPage={selectedPage}
                            onPageChange={onPageChanged}
                            itemsPerPage={itemPerPage}
                            totalItems={totalItem}
                            hideKeys={[USER_MANAGEMENT_CONSTANTS.MODIFIED_BY, USER_MANAGEMENT_CONSTANTS.MODIFIED_DATE_TIME]}
                            paginationText={`Total No of Records: ${totalItem}`}
                        />
                    )
                },
                {
                    breakPoint: 800,
                    component: (
                        <DriscollsDataTable
                            isLoading={loading}
                            manualPagination={true}
                            selectedPage={selectedPage}
                            columns={columns}
                            showHover={false}
                            data={formatData(data)}
                            onPageChange={onPageChanged}
                            itemsPerPage={itemPerPage}
                            totalItems={totalItem}
                            textFilterPlaceholder={false}
                            enableDragAndDrop={false}
                            showColumnVisibility={false}
                            paginationText={`Total No of Records: ${totalItem}`}
                            showDownloadButton={false}
                            noRowMessage={'No records available'}
                            enableServerSideFiltering={true}
                            onFilterChange={onFilterChange}
                        />
                    )
                }
            ]}
        />
    );
};

export default BypassOverrideGrid;
