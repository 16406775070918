import ApplicationContextProvider from './demea/context/ApplicationContextProvider';
import ApplicationUserContextProvider from './demea/context/ApplicationUserContextProvider';
import App from './App';
import './i18n';
import { sessionStorageEnum } from './utils/languageHelper';
import { useEffect } from 'react';
import { useLang, Language } from '@driscollsinc/style-guide-web2.0';
import { NAMESPACE } from './i18n';
import ApplicationDataProvider from './demea/context/ApplicationDataProvider';

const Application = () => {
    const { changeLanguage } = useLang([NAMESPACE.COMMON]);
    useEffect(() => {
        const langCode = sessionStorage.getItem(sessionStorageEnum.QMP_USER_LANG) ?? Language.ENGLISH;
        changeLanguage(langCode as typeof Language);
    }, []);

    return (
        <ApplicationUserContextProvider>
            <ApplicationContextProvider>
                <ApplicationDataProvider>
                    <App />
                </ApplicationDataProvider>
            </ApplicationContextProvider>
        </ApplicationUserContextProvider>
    );
};
export default Application;
