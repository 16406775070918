import {
    ApplicationPageTitle,
    useForm,
    ApplicationPage,
    yupResolver,
    DriscollsHContainer,
    DriscollsButton,
    DriscollsTranslatedValue,
    ButtonVariant
} from '@driscollsinc/style-guide-web2.0';
import { NAMESPACE } from '../../../i18n';
import styles from './index.module.scss';
import BerryTypesSelect from '../../components/pointMatrix/berryType';
import WarehouseSelect from '../../components/userManagement/warehouse';
import VarietySelect from '../../components/pointMatrix/variety';
import { useEffect, useState } from 'react';
import EstimateGroupSelect from '../../components/pointMatrix/estimateGroup';
import PercentageSelect from '../../components/bypass/percentage';
import { useNavigate, useParams } from 'react-router-dom';
import { BypassQuartileSchema } from './FormSchema';
import { BYPASS_QUARTILE_CONSTANTS } from '../../constants/bypassQuartile';
import { DriscollsAuthenticationUtilities, useAuth } from '@driscollsinc/login-module-ui';
import { BYPASS_LOCATION_CONSTANTS } from '../../constants/bypassConstants';
import { BypassLocationPost, BypassLocationResponse } from './Types';
import { useHttp } from '../../hooks/useHttp';
import APIEndPoints from '../../api';
import { useApplicationContext } from '../../hooks/useApplicationContext';
import { useApplicationDataContext } from '../../hooks/useApplicationDataContext';
import { convertToStringArr, sortAndRemoveDuplicatesFromArray } from '../../../utils/format';
import useCheckUserGroup from '../../hooks/useCheckUserGroup';
import { TUserManagementList } from '../../types/userManagement';
import useGetPayloadForBypassQuartile from '../../hooks/useGetPayloadForBypassQuartile';

export const initialBypassQuartileData = {
    BerryType: '',
    BusinessUnit: '',
    Bypass: null,
    CreatedBy: '',
    CreatedDateTime: '',
    EndDate: null,
    EstimateGroup: '',
    ModifiedBy: '',
    ModifiedDateTime: '',
    OverallStatus: null,
    Percentage: '',
    StartDate: '',
    T_InspectionBypassId: null,
    Variety: '',
    WarehouseNbr: ''
};

type SelectedFilters = {
    berryType: string;
    warehouse: string;
};

const AddBypassQuartile = () => {
    const navigate = useNavigate();
    const {
        handleSubmit,
        setValue,
        getValues,
        formState: { errors, isValid }
    } = useForm({
        resolver: yupResolver(BypassQuartileSchema),
        mode: 'onTouched'
    });
    const { id } = useParams();
    const { showSuccess, showError } = useApplicationContext();
    const { getAccessToken } = useAuth();
    const { bypassQuartileData, setBypassQuartileData, selectedBerryType, setSelectedBerryType, userEmail } = useApplicationDataContext();
    const defaultSelectedFilters = {
        berryType: selectedBerryType,
        warehouse: ''
    };
    const { isDemeaAdminUser } = useCheckUserGroup();
    const isDemeaAdminUserFlag = isDemeaAdminUser();
    const { createPayload } = useGetPayloadForBypassQuartile();

    const [warehouseOptions, setWarehouseOptions] = useState<string[]>();
    const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>(defaultSelectedFilters);
    const [loadingWarehouseOptions, setLoadingWarehouseOptions] = useState<boolean>(false);

    const { postData } = useHttp<BypassLocationPost>(APIEndPoints.BYPASS_LOCATION_POST());
    const { getData: getWarehouseOptions } = useHttp<BypassLocationResponse>(
        APIEndPoints.BYPASS_LOCATION_GET(selectedFilters.warehouse, selectedFilters.berryType, BYPASS_LOCATION_CONSTANTS.NONE)
    );
    const { getData: getUserWarehouseOptions } = useHttp<TUserManagementList>(
        APIEndPoints.USER_MANAGEMENT_GET(BYPASS_LOCATION_CONSTANTS.DEFAULT_PAGE, BYPASS_LOCATION_CONSTANTS.DEFAULT_PAGE_SIZE, '', userEmail)
    );

    useEffect(() => {
        if (bypassQuartileData && id) {
            setValue(BYPASS_QUARTILE_CONSTANTS.BERRY_TYPE, bypassQuartileData?.BerryType);
            setValue(BYPASS_QUARTILE_CONSTANTS.WAREHOUSE_NBR, bypassQuartileData?.WarehouseNbr);
            setValue(BYPASS_QUARTILE_CONSTANTS.VARIETY, bypassQuartileData?.Variety);
            setValue(BYPASS_QUARTILE_CONSTANTS.ESTIMATED_GROUP, bypassQuartileData?.EstimateGroup);
            setValue(
                BYPASS_QUARTILE_CONSTANTS.PERCENTAGE,
                bypassQuartileData?.Percentage.toUpperCase() === BYPASS_QUARTILE_CONSTANTS.Q1_AND_Q2
                    ? BYPASS_QUARTILE_CONSTANTS.Q2
                    : bypassQuartileData?.Percentage.toUpperCase()
            );
            setSelectedFilters((prevVal) => ({
                ...prevVal,
                berryType: bypassQuartileData?.BerryType
            }));
        } else {
            setValue(BYPASS_QUARTILE_CONSTANTS.BERRY_TYPE, selectedBerryType);
        }
    }, [bypassQuartileData, id]);

    const fetchData = async () => {
        setLoadingWarehouseOptions(true);
        try {
            const response = await getWarehouseOptions();
            const options = convertToStringArr(response.Data, BYPASS_QUARTILE_CONSTANTS.WAREHOUSE_NBR);
            setWarehouseOptions(options);
        } catch (error) {
            console.log(error.message);
        } finally {
            setLoadingWarehouseOptions(false);
        }
    };

    const fetchUserData = async () => {
        setLoadingWarehouseOptions(true);
        try {
            const response = await getUserWarehouseOptions();
            const stringArr = convertToStringArr(response?.Data, BYPASS_LOCATION_CONSTANTS.WAREHOUSE_NBR);
            setSelectedFilters((prevVal) => ({
                ...prevVal,
                warehouse: stringArr.join(',')
            }));
        } catch (error) {
            console.log(error.message);
        } finally {
            setLoadingWarehouseOptions(false);
        }
    };

    useEffect(() => {
        if (isDemeaAdminUserFlag) {
            setSelectedFilters((prevVal) => ({
                ...prevVal,
                berryType: selectedBerryType,
                warehouse: BYPASS_QUARTILE_CONSTANTS.ALL
            }));
        }
    }, [isDemeaAdminUserFlag]);

    useEffect(() => {
        if (userEmail && !isDemeaAdminUserFlag) {
            fetchUserData();
        }
    }, [userEmail, isDemeaAdminUserFlag]);

    useEffect(() => {
        if (selectedFilters.warehouse) {
            fetchData();
        }
    }, [selectedFilters.warehouse, selectedFilters.berryType, selectedBerryType]);

    useEffect(() => {
        return () => setBypassQuartileData(initialBypassQuartileData);
    }, []);

    const goToBypassQuartile = () => {
        setBypassQuartileData(initialBypassQuartileData);
        navigate(`../`);
    };

    const handleBerryTypeChange = (selectedOption) => {
        setValue(BYPASS_QUARTILE_CONSTANTS.BERRY_TYPE, selectedOption?.value, { shouldValidate: true });
        setSelectedFilters((prevVal) => ({
            ...prevVal,
            berryType: selectedOption?.value
        }));
        setSelectedBerryType(selectedOption?.value);
    };

    const handleWarehouseChange = (selectedOption) => {
        setValue(BYPASS_QUARTILE_CONSTANTS.WAREHOUSE_NBR, selectedOption?.value, { shouldValidate: true });
    };

    const handleVarietyChange = (selectedOption) => {
        setValue(BYPASS_QUARTILE_CONSTANTS.VARIETY, selectedOption?.value, { shouldValidate: true });
    };

    const handleEstimateGroupChange = (selectedOption) => {
        setValue(BYPASS_QUARTILE_CONSTANTS.ESTIMATED_GROUP, selectedOption?.value, { shouldValidate: true });
    };

    const handlePercentageChange = (selectedOption) => {
        setValue(
            BYPASS_QUARTILE_CONSTANTS.PERCENTAGE,
            selectedOption?.value === BYPASS_QUARTILE_CONSTANTS.Q1_AND_Q2 ? BYPASS_QUARTILE_CONSTANTS.Q2 : selectedOption?.value,
            { shouldValidate: true }
        );
    };

    const defaultValue = (label: string, value: string) => {
        return bypassQuartileData && bypassQuartileData[label] ? { label: bypassQuartileData[label], id: bypassQuartileData[value] } : null;
    };

    const onSubmit = async (data) => {
        const token = await getAccessToken();
        const user = DriscollsAuthenticationUtilities.GetEmail(token);
        const currentDate = new Date();
        const payload = createPayload(data, user, currentDate);

        try {
            await postData(payload as BypassLocationPost);
            showSuccess('Request processed successfully!');
            goToBypassQuartile();
        } catch (error) {
            showError(error?.message || 'Something went wrong!');
        }
    };

    return (
        <ApplicationPage className={styles.bypassPage}>
            <ApplicationPageTitle
                title={{ text: BYPASS_QUARTILE_CONSTANTS.BYPASS, nameSpace: NAMESPACE.BYPASS }}
                description={{
                    text: id ? BYPASS_QUARTILE_CONSTANTS.EDIT_QUARTILE : BYPASS_QUARTILE_CONSTANTS.ADD_QUARTILE,
                    nameSpace: NAMESPACE.BYPASS
                }}
            />
            <form onSubmit={handleSubmit(onSubmit)} className={styles.formWrapper}>
                <DriscollsHContainer className={styles.fieldWrapper}>
                    <BerryTypesSelect
                        defaultValue={defaultValue(BYPASS_QUARTILE_CONSTANTS.BERRY_TYPE, BYPASS_QUARTILE_CONSTANTS.BERRY_TYPE)}
                        styles={styles}
                        onChange={handleBerryTypeChange}
                        error={errors.BerryType?.message}
                        isDisabled={Boolean(id)}
                    />

                    <WarehouseSelect
                        defaultOptions={false}
                        loadingWarehouseOptions={loadingWarehouseOptions}
                        defaultValue={defaultValue(BYPASS_QUARTILE_CONSTANTS.WAREHOUSE_NBR, BYPASS_QUARTILE_CONSTANTS.WAREHOUSE_NBR)}
                        isMulti={false}
                        styles={styles}
                        onChange={handleWarehouseChange}
                        error={errors.WarehouseNbr?.message}
                        options={warehouseOptions && sortAndRemoveDuplicatesFromArray(warehouseOptions)}
                        isDisabled={Boolean(id)}
                    />
                </DriscollsHContainer>

                <DriscollsHContainer className={styles.fieldWrapper}>
                    <VarietySelect
                        styles={styles}
                        defaultValue={defaultValue(BYPASS_QUARTILE_CONSTANTS.VARIETY, BYPASS_QUARTILE_CONSTANTS.VARIETY)}
                        onChange={handleVarietyChange}
                        isDisabled={Boolean(id)}
                        error={errors.Variety?.message}
                        showAll={true}
                        commodityCode={getValues(BYPASS_QUARTILE_CONSTANTS.BERRY_TYPE)}
                    />
                    <EstimateGroupSelect
                        styles={styles}
                        isDisabled={Boolean(id)}
                        withAllOption={true}
                        onChange={handleEstimateGroupChange}
                        error={errors.EstimateGroup?.message}
                        defaultValue={defaultValue(BYPASS_QUARTILE_CONSTANTS.ESTIMATED_GROUP, BYPASS_QUARTILE_CONSTANTS.ESTIMATED_GROUP)}
                    />
                </DriscollsHContainer>

                <DriscollsHContainer className={styles.fieldWrapper}>
                    <PercentageSelect
                        defaultValue={defaultValue(BYPASS_QUARTILE_CONSTANTS.PERCENTAGE, BYPASS_QUARTILE_CONSTANTS.PERCENTAGE)}
                        styles={styles}
                        onChange={handlePercentageChange}
                        error={errors.Percentage?.message}
                    />
                </DriscollsHContainer>

                <DriscollsHContainer className={styles.rightAlign}>
                    <DriscollsButton
                        className={styles.btnLink}
                        label={<DriscollsTranslatedValue value={BYPASS_QUARTILE_CONSTANTS.CANCEL} />}
                        onClick={goToBypassQuartile}
                        variant={ButtonVariant.SECONDARY}
                    />
                    <DriscollsButton
                        className={styles.btnLink}
                        label={<DriscollsTranslatedValue value={id ? BYPASS_QUARTILE_CONSTANTS.UPDATE : BYPASS_QUARTILE_CONSTANTS.SUBMIT} />}
                        onClick={handleSubmit(onSubmit)}
                        disabled={!isValid}
                    />
                </DriscollsHContainer>
            </form>
        </ApplicationPage>
    );
};

export default AddBypassQuartile;
