export const EventConstants = {
    EXCEPTION: 'exception',
    PAGE_VIEW: 'page_view',
    EDIT_ACTION: 'edit_action',
    DELETE_ACTION: 'delete_action',
    ADD_ACTION: 'add_action',
    NEW_ACTION: 'new_action',
    USER_MANAGEMENT: 'User Management',
    POINT_MATRIX: 'Point Matrix',
    BYPASS_LOCATIONS: 'Bypass Locations',
    BYPASS_QUARTILE: 'Bypass Quartile',
    BYPASS_OVERRIDE: 'Bypass Override',
    NOTIFICATION: 'Notification',
    PRODUCING_AREA_REGION: 'Producing Area Region',
    REJECTION_THRESHOLD: 'Rejection Threshold',
    QA_MANAGER_MAINTENANCE: 'QA Manager Maintenance',
    DISTRICT_GROUP: 'District Group',
    DISPOSITION: 'Disposition',
    SUMMARY_STATISTICS: 'Summary Statistics',
    DEMEA: 'DEMEA',
    DOTA: 'DOTA',
    NEW: 'New',
    ADD: 'Add',
    EDIT: 'Edit',
    DELETE: 'Delete'
};

export function logEvent(eventName, eventInfo) {
    window.config.logEvent(eventName, eventInfo);
}

export function logError(errorInfo) {
    window.config.logEvent(EventConstants.EXCEPTION, errorInfo);
}