import { ErrorBoundary } from 'react-error-boundary';
import { DrcLoading } from '../../../legacy/DriscollsReactComponents';
import ErrorFallback from '../errorFallback';
import { useApplicationContext } from '../../hooks/useApplicationContext';

const PageContainer = ({ children }) => {
    const { showLoading } = useApplicationContext();

    const logError = (error: Error, info: { componentStack: string }) => {
        console.info(`Error: ${error}`, info);
    };
    return (
        <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
            {showLoading ? <DrcLoading /> : <>{children}</>}
        </ErrorBoundary>
    );
};

export default PageContainer;
