import { Outlet, Route, Routes } from 'react-router-dom';
import Bypass from './Bypass';
import BypassQuartile from './BypassQuartile';
import BypassOverride from './BypassOverride';
import { routes, routesName } from '../../routes/routes';
import AddBypassQuartile from './AddBypassQuartile';
import AddBypassOverride from './AddBypassOverride';

const BypassRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Bypass />} />
            <Route path={routes[routesName.BYPASS_QUARTILE].path} element={<Outlet />}>
                <Route index element={<BypassQuartile />} />
                <Route path={routes[routesName.BYPASS_ADD_QUARTILE].path} element={<AddBypassQuartile />} />
                <Route path={routes[routesName.BYPASS_EDIT_QUARTILE].path} element={<AddBypassQuartile />} />
            </Route>
            <Route path={routes[routesName.BYPASS_OVERRIDE].path} element={<Outlet />}>
                <Route index element={<BypassOverride />}></Route>
                <Route path={routes[routesName.BYPASS_ADD_OVERRIDE].path} element={<AddBypassOverride />}></Route>
                <Route path={routes[routesName.BYPASS_EDIT_OVERRIDE].path} element={<AddBypassOverride />}></Route>
            </Route>
        </Routes>
    );
};

export default BypassRoutes;
