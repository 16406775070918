import { FC, memo } from 'react';
import { DriscollsSelect, DriscollsTranslatedValue } from '@driscollsinc/style-guide-web2.0';
import { convertLabelValueObj } from '../../../../utils/format';
import { NAMESPACE } from '../../../../i18n';
import { useGetStaticData } from '../../../hooks/useGetStaticData';

interface VarietySelectProps {
    error?: any;
    styles: any;
    isDisabled?: boolean;
    defaultValue?: any;
    commodityCode: string;
    value?: { label: string; value: string };
    onChange?: (selectedOption: any) => void;
    showAll?: boolean | undefined;
}

const VarietySelect: FC<VarietySelectProps> = memo(function VarietySelect({ styles, commodityCode, isDisabled, showAll, ...rest }) {
    const { getVarietyListFilteredByCommodity, getVarietyDefaultOption } = useGetStaticData();

    const varietyList = !isDisabled ? getVarietyListFilteredByCommodity(commodityCode, showAll) : getVarietyDefaultOption();

    return (
        <DriscollsSelect
            name="variety"
            className={styles.selectField}
            containerClassName={styles.selectContainer}
            options={convertLabelValueObj(varietyList, 'VarietyCode', 'VarietyDescription')}
            placeholder={<DriscollsTranslatedValue nameSpacing={NAMESPACE.POINT_MATRIX} value="Variety" />}
            isDisabled={isDisabled}
            {...rest}
        />
    );
});

export default VarietySelect;
