import {
    ApplicationPageTitle,
    ApplicationPage,
    DriscollsHContainer,
    DriscollsButton,
    DriscollsTranslatedValue,
    useForm,
    yupResolver,
    DriscollsDatePicker,
    ButtonVariant
} from '@driscollsinc/style-guide-web2.0';
import { BYPASS_QUARTILE_CONSTANTS } from '../../constants/bypassQuartile';
import { NAMESPACE } from '../../../i18n';
import WarehouseSelect from '../../components/userManagement/warehouse';
import styles from './index.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { BypassOverrideSchema } from './FormSchema';
import { useHttp } from '../../hooks/useHttp';
import { WarehouseResponse } from '../../components/userManagement/warehouse/Types';
import APIEndPoints from '../../api';
import { convertToStringArr } from '../../../utils/format';
import { useEffect, useState } from 'react';
import RanchSelect from '../../components/bypass/ranch';
import { BYPASS_OVERRIDE_CONSTANTS } from '../../constants/bypassOverride';
import { BypassLocationResponse, BypassOverridePost } from './Types';
import { useApplicationContext } from '../../hooks/useApplicationContext';
import { useApplicationDataContext } from '../../hooks/useApplicationDataContext';
import useCheckUserGroup from '../../hooks/useCheckUserGroup';
import { format } from 'date-fns';
import { DriscollsAuthenticationUtilities, useAuth } from '@driscollsinc/login-module-ui';
import { TUserManagementList } from '../../types/userManagement';
import { BYPASS_LOCATION_CONSTANTS } from '../../constants/bypassConstants';

export const initialBypassOverrideData = {
    T_RanchBypassOverrideId: null,
    RanchNbr: '',
    RanchName: '',
    WarehouseNbr: '',
    StartDate: '',
    EndDate: '',
    CreatedBy: '',
    ModifiedBy: '',
    ModifiedDateTime: ''
};

const AddBypassOverride = () => {
    const navigate = useNavigate();
    const {
        handleSubmit,
        setValue,
        getValues,
        trigger,
        formState: { errors, isValid }
    } = useForm({
        resolver: yupResolver(BypassOverrideSchema),
        mode: 'onBlur'
    });
    const { id } = useParams();
    const { bypassOverrideData, setBypassOverrideData, userEmail } = useApplicationDataContext();
    const { showError, showSuccess } = useApplicationContext();

    const [loadingWarehouseOptions, setLoadingWarehouseOptions] = useState<boolean>();
    const [warehouseOptions, setWarehouseOptions] = useState<string>();
    const [userWarehouseData, setUserWarehouseData] = useState<string>();

    const { postData } = useHttp<BypassOverridePost>(APIEndPoints.BYPASS_OVERRIDE_POST());
    const { getData: getWarehouseDataForAdmin } = useHttp<WarehouseResponse>(APIEndPoints.GET_WAREHOUSES());
    const { isDemeaAdminUser } = useCheckUserGroup();
    const isDemeaAdminUserFlag = isDemeaAdminUser();
    const { getAccessToken } = useAuth();
    const { getData: getUserData } = useHttp<TUserManagementList>(APIEndPoints.USER_MANAGEMENT_GET(null, null, '', userEmail));
    const { getData: getWarehouseListForUser } = useHttp<BypassLocationResponse>(
        APIEndPoints.BYPASS_LOCATION_GET(userWarehouseData, BYPASS_LOCATION_CONSTANTS.ALL, BYPASS_LOCATION_CONSTANTS.NONE)
    );

    // API calls for admin
    const fetchWarehouseDataForAdmin = async () => {
        setLoadingWarehouseOptions(true);
        try {
            const response = await getWarehouseDataForAdmin();
            const options = convertToStringArr(response.Data, BYPASS_QUARTILE_CONSTANTS.WAREHOUSE_NBR).join(',');
            setWarehouseOptions(options);
        } catch (error) {
            console.log(error.message);
        } finally {
            setLoadingWarehouseOptions(false);
        }
    };
    // api calls for users
    const fetchUserData = async () => {
        try {
            const response = await getUserData();
            const stringArr = convertToStringArr(response?.Data, BYPASS_OVERRIDE_CONSTANTS.WAREHOUSE_NBR).join(',');
            setUserWarehouseData(stringArr);
        } catch (error) {
            console.log(error.message);
        }
    };

    const fetchWarehouseForUser = async () => {
        try {
            const response = await getWarehouseListForUser();
            const stringArr = convertToStringArr(response?.Data, BYPASS_OVERRIDE_CONSTANTS.WAREHOUSE_NBR).join(',');
            setWarehouseOptions(stringArr);
        } catch (error) {
            console.log(error.message);
        }
    };

    useEffect(() => {
        if (isDemeaAdminUserFlag) {
            fetchWarehouseDataForAdmin();
        } else {
            fetchUserData();
        }
    }, [isDemeaAdminUserFlag]);

    useEffect(() => {
        const formattedDate = format(new Date(), BYPASS_OVERRIDE_CONSTANTS.ISO_DATE_FORMAT);
        setValue(BYPASS_OVERRIDE_CONSTANTS.START_DATE, formattedDate, { shouldValidate: true });
    }, [setValue]);

    useEffect(() => {
        if (userWarehouseData) {
            fetchWarehouseForUser();
        }
    }, [userWarehouseData]);

    useEffect(() => {
        if (bypassOverrideData && id) {
            setValue(BYPASS_OVERRIDE_CONSTANTS.WAREHOUSE_NBR, bypassOverrideData?.WarehouseNbr);
            setValue(BYPASS_OVERRIDE_CONSTANTS.RANCH_NUMBER, bypassOverrideData?.RanchNbr);
            setValue(BYPASS_OVERRIDE_CONSTANTS.RANCH_NAME, bypassOverrideData?.RanchName);
            setValue(BYPASS_OVERRIDE_CONSTANTS.START_DATE, bypassOverrideData?.StartDate);
            setValue(BYPASS_OVERRIDE_CONSTANTS.END_DATE, bypassOverrideData?.EndDate);
        }
    }, [bypassOverrideData, id]);

    const defaultValue = (label: string, value: string) => {
        if (label === BYPASS_OVERRIDE_CONSTANTS.RANCH_NUMBER) {
            return bypassOverrideData && bypassOverrideData[label]
                ? {
                      label: `${bypassOverrideData[label]} - ${bypassOverrideData[BYPASS_OVERRIDE_CONSTANTS.RANCH_NAME]}`,
                      id: `${bypassOverrideData[value]}${bypassOverrideData[BYPASS_OVERRIDE_CONSTANTS.RANCH_NAME]}`
                  }
                : null;
        } else {
            return bypassOverrideData && bypassOverrideData[label] ? { label: bypassOverrideData[label], id: bypassOverrideData[value] } : null;
        }
    };

    const onSubmit = async (data) => {
        const token = await getAccessToken();
        const user = DriscollsAuthenticationUtilities.GetEmail(token);
        const currentDate = new Date().toISOString();

        const payload = {
            [BYPASS_OVERRIDE_CONSTANTS.WAREHOUSE_NBR]: data.WarehouseNbr,
            [BYPASS_OVERRIDE_CONSTANTS.RANCH_NUMBER]: data.RanchNbr,
            [BYPASS_OVERRIDE_CONSTANTS.RANCH_NAME]: data.RanchName,
            [BYPASS_OVERRIDE_CONSTANTS.IS_DELETED]: false,
            [BYPASS_OVERRIDE_CONSTANTS.START_DATE]: format(data.StartDate, BYPASS_OVERRIDE_CONSTANTS.ISO_DATE_FORMAT),
            [BYPASS_OVERRIDE_CONSTANTS.END_DATE]: format(data.EndDate, BYPASS_OVERRIDE_CONSTANTS.ISO_DATE_FORMAT),
            [BYPASS_OVERRIDE_CONSTANTS.BUSINESS_UNIT]: BYPASS_OVERRIDE_CONSTANTS.DEMEA,
            [BYPASS_OVERRIDE_CONSTANTS.CREATED_BY]: user,
            [BYPASS_OVERRIDE_CONSTANTS.CREATED_DATE_TIME]: currentDate
        };
        try {
            await postData(payload as BypassOverridePost);
            if (id) {
                showSuccess('Ranch Override updated successfully!');
            } else {
                showSuccess('Request processed successfully!');
            }
            goToBypassOverride();
        } catch (error) {
            showError(error?.message || 'Something went wrong!');
        }
    };

    const handleWarehouseChange = (selectedOption) => {
        setValue(BYPASS_OVERRIDE_CONSTANTS.WAREHOUSE_NBR, selectedOption?.value, { shouldValidate: true });
    };

    const handleRanchChange = (selectedOption) => {
        const ranchNbr = selectedOption?.value.split(' ')[0];
        const ranchName = selectedOption?.value.split(' - ').slice(1).join(' - ');
        setValue(BYPASS_OVERRIDE_CONSTANTS.RANCH_NUMBER, ranchNbr, { shouldValidate: true });
        setValue(BYPASS_OVERRIDE_CONSTANTS.RANCH_NAME, ranchName);
    };

    const handleStartDateChange = (selectedOption) => {
        const formattedDate = format(selectedOption, BYPASS_OVERRIDE_CONSTANTS.ISO_DATE_FORMAT);
        setValue(BYPASS_OVERRIDE_CONSTANTS.START_DATE, formattedDate, { shouldValidate: true });
        trigger(BYPASS_OVERRIDE_CONSTANTS.END_DATE);
    };

    const handleEndDateChange = (selectedOption) => {
        const formattedDate = format(selectedOption, BYPASS_OVERRIDE_CONSTANTS.ISO_DATE_FORMAT);
        setValue(BYPASS_OVERRIDE_CONSTANTS.END_DATE, formattedDate, { shouldValidate: true });
        trigger(BYPASS_OVERRIDE_CONSTANTS.START_DATE);
    };

    const goToBypassOverride = () => {
        setBypassOverrideData(initialBypassOverrideData);
        navigate(`../`);
    };

    useEffect(() => {
        return () => setBypassOverrideData(initialBypassOverrideData);
    }, []);

    return (
        <ApplicationPage className={styles.bypassPage}>
            <ApplicationPageTitle
                title={{ text: BYPASS_QUARTILE_CONSTANTS.BYPASS, nameSpace: NAMESPACE.BYPASS }}
                description={{
                    text: id ? BYPASS_OVERRIDE_CONSTANTS.EDIT_OVERRIDE : BYPASS_OVERRIDE_CONSTANTS.ADD_OVERRIDE,
                    nameSpace: NAMESPACE.BYPASS
                }}
            />
            <form onSubmit={handleSubmit(onSubmit)} className={styles.formWrapper}>
                <DriscollsHContainer className={styles.fieldWrapper}>
                    <WarehouseSelect
                        loadingWarehouseOptions={loadingWarehouseOptions}
                        defaultValue={defaultValue(BYPASS_OVERRIDE_CONSTANTS.WAREHOUSE_NBR, BYPASS_OVERRIDE_CONSTANTS.WAREHOUSE_NBR)}
                        isMulti={false}
                        styles={styles}
                        onChange={handleWarehouseChange}
                        error={errors.WarehouseNbr?.message}
                        defaultOptions={false}
                        options={warehouseOptions?.split(',').filter((warehouse, index, self) => self.indexOf(warehouse) === index)}
                        isDisabled={Boolean(id)}
                    />
                    <RanchSelect
                        styles={styles}
                        onChange={handleRanchChange}
                        error={errors.RanchNbr?.message}
                        defaultValue={defaultValue(BYPASS_OVERRIDE_CONSTANTS.RANCH_NUMBER, BYPASS_OVERRIDE_CONSTANTS.RANCH_NUMBER)}
                        warehouseList={getValues('WarehouseNbr')}
                        isDisabled={Boolean(id)}
                    />
                </DriscollsHContainer>

                <DriscollsHContainer className={styles.fieldWrapper}>
                    <DriscollsDatePicker
                        label="Start Date"
                        onChange={handleStartDateChange}
                        containerClassName={styles.datePickerContainer}
                        className={styles.datePicker}
                        format="MMM-dd-yyyy"
                        error={errors.StartDate?.message}
                        selectedDate={id ? new Date(bypassOverrideData.StartDate) : new Date()}
                        minDate={new Date()}
                    />
                    <DriscollsDatePicker
                        label="End Date"
                        onChange={handleEndDateChange}
                        containerClassName={styles.datePickerContainer}
                        className={styles.datePicker}
                        format="MMM-dd-yyyy"
                        selectedDate={id ? new Date(bypassOverrideData.EndDate) : null}
                        error={errors.EndDate?.message}
                        minDate={new Date()}
                    />
                </DriscollsHContainer>

                <DriscollsHContainer className={styles.rightAlign}>
                    <DriscollsButton
                        className={styles.btnLink}
                        label={<DriscollsTranslatedValue value={BYPASS_QUARTILE_CONSTANTS.CANCEL} />}
                        onClick={goToBypassOverride}
                        variant={ButtonVariant.SECONDARY}
                    />
                    <DriscollsButton
                        className={styles.btnLink}
                        label={<DriscollsTranslatedValue value={id ? BYPASS_QUARTILE_CONSTANTS.UPDATE : BYPASS_QUARTILE_CONSTANTS.SUBMIT} />}
                        onClick={handleSubmit(onSubmit)}
                        disabled={!isValid}
                    />
                </DriscollsHContainer>
            </form>
        </ApplicationPage>
    );
};

export default AddBypassOverride;
