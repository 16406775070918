import { DriscollsSelect } from '@driscollsinc/style-guide-web2.0';
import { convertToLabelValue } from '../../../../utils/format';
import { useGetStaticData } from '../../../hooks/useGetStaticData';
import { DriscollsTranslatedValue } from '@driscollsinc/style-guide-web2.0';
import { NAMESPACE } from '../../../../i18n';
import { FC, memo } from 'react';
import { useApplicationDataContext } from '../../../hooks/useApplicationDataContext';
import { BYPASS_QUARTILE_CONSTANTS } from '../../../constants/bypassQuartile';

interface PercentageSelectProps {
    styles: any;
    onChange?: (selectedOption: any) => void;
    error?: any;
    defaultValue?: any;
}
const PercentageSelect: FC<PercentageSelectProps> = memo(function PercentageSelect({ styles, ...rest }) {
    return (
        <DriscollsSelect
            name="percentage"
            className={styles.selectField}
            containerClassName={styles.selectContainer}
            options={convertToLabelValue(BYPASS_QUARTILE_CONSTANTS.PERCENTAGE_OPTIONS_WITH_NONE)}
            placeholder={<DriscollsTranslatedValue nameSpacing={NAMESPACE.BYPASS} value="Percentage" />}
            {...rest}
        />
    );
});

export default PercentageSelect;
