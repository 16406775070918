import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { DrcMain, DrcImage } from 'driscolls-react-components';
import { setPageTitleAction } from '../actions/actions';
import styles from './Login.module.css';
import { DriscollsLogin, useAuth, withAuth } from '@driscollsinc/login-module-ui';

import { routes, routesName } from '../../demea/routes/routes';

const Login = (props) => {
    const { authState } = useAuth();

    const onSuccess = (res) => {
        if (res.status === 'SUCCESS') {
            return props.auth.redirect({
                sessionToken: res.session.token
            });
        } else {
            console.log('error logging in', res);
        }
    };

    const onError = (err) => {
        console.log('error logging in', err);
    };

    return authState?.isAuthenticated ? (
        (
            <Navigate to={routes[routesName.HOME].path} />
        )
    ) : (
        <DrcMain transparent>
            <DrcImage
                src={require('../images/Wood_Rasp_Full_Hd.png')}
                webp={require('../images/Wood_Rasp_Full_Hd.webp')}
                style={{
                    position: 'absolute',
                    height: '100vh',
                    width: '100vw',
                    top: 0,
                    left: 0,
                    objectFit: 'cover',
                    zIndex: -1
                }}
                alt=""
            />
            <DriscollsLogin
                containerClass={styles.loginContainer}
                baseUrl={window.config.OKTA_URL}
                onSuccess={onSuccess}
                onError={onError}
                appVersion={process.env.REACT_APP_VERSION}
            />
        </DrcMain>
    );
};

const mapDispatchToProps = (dispatch) => ({
    setPageTitle: (title) => dispatch(setPageTitleAction(title))
});

export default connect(null, mapDispatchToProps)(withAuth(Login));
