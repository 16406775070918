import { Navigate } from 'react-router-dom';
import { useAuth } from '@driscollsinc/login-module-ui';
import { routes, routesName } from '../../demea/routes/routes';
import useCheckUserGroup from '../../demea/hooks/useCheckUserGroup';

const Home = () => {
    const { isOnlyDemeaUser } = useCheckUserGroup();
    const { authState } = useAuth();

    return authState?.isAuthenticated ? (
        isOnlyDemeaUser() ? (
            <RedirectDemea />
        ) : (
            <Navigate to={routes[routesName.INITIALIZE_APPLICATION].path} />
        )
    ) : (
        <Navigate to={routes[routesName.LOGIN].path} />
    );
};


export default Home;

const RedirectDemea = () => {
    const { isDemeaAdminUser } = useCheckUserGroup();

    return (
        isDemeaAdminUser() ? <Navigate to={(routes[routesName.POINT_MATRIX].path)} /> : <Navigate to={(routes[routesName.BYPASS].path)} />
    );

}
