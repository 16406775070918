import { DriscollsSelect } from '@driscollsinc/style-guide-web2.0';
import { convertToLabelValue, convertToStringArr } from '../../../../utils/format';
import { useGetStaticData } from '../../../hooks/useGetStaticData';
import { DriscollsTranslatedValue } from '@driscollsinc/style-guide-web2.0';
import { NAMESPACE } from '../../../../i18n';
import { FC, memo, useEffect, useState } from 'react';
import { useEagerHttp, useHttp } from '../../../hooks/useHttp';
import APIEndPoints from '../../../api';
import { RanchResponse } from './types';
import { DriscollsHContainer } from '@driscollsinc/style-guide-web2.0';

interface RanchSelectProps {
    styles: any;
    onChange?: (selectedOption: any) => void;
    error?: any;
    defaultValue?: any;
    warehouseList?: string;
    isDisabled?: boolean;
}
const RanchSelect: FC<RanchSelectProps> = memo(function RanchSelect({ styles, warehouseList, ...rest }) {
    const { getData } = useHttp<RanchResponse>(APIEndPoints.GET_RANCHES(warehouseList));
    const [ranchOptions, setRanchOptions] = useState<string[]>();
    const [isLoading, setIsLoading] = useState<boolean>();

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const response = await getData();
            const ranches = response?.Data?.map((ranch) => `${ranch.RanchNbr} - ${ranch.RanchName}`);
            setRanchOptions(ranches);
        } catch (error) {
            console.log(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (warehouseList) {
            fetchData();
        }
    }, [warehouseList]);

    if (isLoading) return <DriscollsHContainer>Loading Ranches...</DriscollsHContainer>;

    return (
        <DriscollsSelect
            name="ranch"
            className={styles.selectField}
            containerClassName={styles.selectContainer}
            options={convertToLabelValue(ranchOptions ?? [])}
            placeholder={<DriscollsTranslatedValue nameSpacing={NAMESPACE.BYPASS} value="Ranch" />}
            {...rest}
        />
    );
});

export default RanchSelect;
