import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { setPageTitleAction } from '../actions/actions';
import { DrcMain, DrcPanel, DrcLoading, DrcButton, DrcImage } from 'driscolls-react-components';
import MasterDataUtilities from '../data/MasterDataUtilities';
import {
    setMasterData,
    setMasterDataInitialized,
    setWarehouses,
    setOverrideData,
    selectDisplayTabs,
    setUserGroup,
    setLoggedInUser
} from '../actions/MasterActions';
import { setMLookUpData } from '../actions/RejectionThresholdActions';
import { setProducingAreaList } from '../actions/OverrideActions';
import { setBerryType, setLabel } from '../actions/PointMatrixAction';
import { setEstimatedGroup } from '../actions/QuartileActions';
import { DuAuthenticationUtilities } from 'driscolls-react-utilities';
import { ManagerTabs, AdminTabs, AnalystTabs } from '../Constant/Tabs';
import { Middleware } from 'one-ring';
import APIEndPoints from '../services/api';
import { withAuth } from '@driscollsinc/login-module-ui';
import { getAdminGroups, getAnalystGroups } from '../../utils/getConfigData';
import { EventConstants, logEvent } from '../../utils/analytics';

const pageTitle = 'Initializing';

class InitializeApplication extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            message: '',
            successCount: 0,
            errorCount: 0,
            totalCount: 0,
            canWrite: null,
            redirect_Url: ''
        };

        this.statusUpdate = this.statusUpdate.bind(this);
        this.loadMasterData = this.loadMasterData.bind(this);
    }

    statusUpdate(message, successCount, errorCount, totalCount) {
        this.setState({ message, successCount, errorCount, totalCount });
    }

    loadMasterData() {
        this.setState({
            message: '',
            successCount: 0,
            errorCount: 0,
            totalCount: 0
        });
        this.props.auth.getAccessToken().then((token) => {
            MasterDataUtilities.Load(
                this.props,
                token,
                this.statusUpdate,
                (_message) => {
                    this.props.setMasterDataInitialized(true);
                },
                (error) => {
                    console.error(error);
                }
            );
        });
    }
    async componentWillMount() {
        this.props.auth.getAccessToken().then((token) => {
            const loggedInUser = DuAuthenticationUtilities.GetEmail(token);
            this.props.setLoggedInUser(loggedInUser);
            if (DuAuthenticationUtilities.IsInGroup(token, getAdminGroups())) {
                this.setState({ redirect_Url: this.props.redirectUrl });
                this.props.selectDisplayTabs(AdminTabs);
                this.props.setUserGroup('Admin');
            } else if (DuAuthenticationUtilities.IsInGroup(token, getAnalystGroups())) {
                let redirect_Url = '';
                if (this.props.redirectUrl.includes('PointMatrix')) {
                    redirect_Url = this.props.redirectUrl;
                } else {
                    redirect_Url = '/PointMatrix/Create/';
                }
                this.setState({ redirect_Url: redirect_Url });
                this.props.selectDisplayTabs(AnalystTabs);
                this.props.setUserGroup('Analyst');
            } else {
                this.setState({ redirect_Url: this.props.redirectUrl });
                this.props.selectDisplayTabs(ManagerTabs);
                this.props.setUserGroup('Manager');
            }
        });
    }
    async componentDidMount() {
        //TODO: Need way to turn off header navigation so we can stay here till we have the master data
        if (this.props.pageTitle !== pageTitle) {
            this.props.setPageTitleAction(pageTitle);
        }
        logEvent(EventConstants.PAGE_VIEW, {page_title: pageTitle});

        this.loadMasterData();
        this.props.auth.getAccessToken().then((token) => {
            Middleware.Send('berryType', token, APIEndPoints.GET_BERRY_TYPE, 'GET', { Type: 'berryType' }, null).then((res) => {
                if (res.Results.length) {
                    this.props.setBerryType(res.Results);
                }
            });
        });

        this.props.auth.getAccessToken().then((token) => {
            Middleware.Send(
                'maintaince',
                token,
                APIEndPoints.GET_M_LOOKUP('Fruit Condition'),
                'GET',
                { Type: 'maintaince' },
                { loadingMessage: '' }
            ).then((data) => {
                if (data) {
                    this.props.setMLookUpData(data);
                }
            });
        });

        if (!localStorage.getItem('user')) {
            let user = await this.props.auth.getUser();
            localStorage.setItem('user', JSON.stringify(user));
        }
    }

    render() {
        console.log(this.props);
        const { isMasterDataInitialized } = this.props;
        return isMasterDataInitialized ? (
            <Navigate to={this.state.redirect_Url} />
        ) : (
            <DrcMain transparent>
                <DrcPanel maxWidth="500px">
                    {this.state.successCount + this.state.errorCount === this.state.totalCount && this.state.errorCount > 0 ? (
                        <DrcImage
                            src={require('../images/fail.png')}
                            webp={require('../images/fail.webp')}
                            style={{ position: 'absolute', height: 100, marginTop: -10, left: 'calc(50vw - 87px)' }}
                            alt="Thor and Callie are sorry they couldn't get the Data you needed"
                        />
                    ) : null}
                    <DrcLoading
                        text={<h1>{this.state.message}</h1>}
                        hidden={this.state.successCount + this.state.errorCount === this.state.totalCount}
                    />
                    {this.state.successCount + this.state.errorCount >= this.state.totalCount && this.state.errorCount > 0 ? (
                        <React.Fragment>
                            <hr />
                            <p>
                                We were unable to load {this.state.errorCount} out of {this.state.totalCount} important components for the site to
                                load properly. You may want to refresh the website or you can proceed with using the application knowing that some
                                aspects may not be working.
                            </p>
                            <hr />
                            <div className="row">
                                <div className="col-xs-6">
                                    <DrcButton
                                        isPrimary
                                        fullWidth
                                        style={{ margin: '8px 0' }}
                                        onClick={() => {
                                            window.location.reload();
                                        }}
                                    >
                                        Try, Try Again
                                    </DrcButton>
                                </div>
                                <div className="col-xs-6">
                                    <DrcButton
                                        isSecondary
                                        fullWidth
                                        style={{ margin: '8px 0' }}
                                        onClick={() => {
                                            this.props.setMasterDataInitialized(true);
                                        }}
                                    >
                                        Proceed to Website
                                    </DrcButton>
                                </div>
                            </div>
                        </React.Fragment>
                    ) : null}
                </DrcPanel>
            </DrcMain>
        );
    }
}

function mapStateToProps(state) {
    return {
        showLoadingScreen: state.rootReducer.showLoadingScreen,
        errorDialog: state.rootReducer.errorDialog,
        pageTitle: state.rootReducer.pageTitle,
        redirectUrl: state.masterReducer.redirectUrl,
        isMasterDataInitialized: state.masterReducer.isInitialized
    };
}
export default withAuth(
    connect(mapStateToProps, {
        setPageTitleAction,
        setMasterData,
        setMasterDataInitialized,
        setWarehouses,
        setOverrideData,
        setProducingAreaList,
        setLabel,
        setBerryType,
        selectDisplayTabs,
        setEstimatedGroup,
        setUserGroup,
        setLoggedInUser,
        setMLookUpData
    })(InitializeApplication)
);
