export const BYPASS_QUARTILE_CONSTANTS = {
    BERRY_TYPE: 'BerryType',
    WAREHOUSE_NBR: 'WarehouseNbr',
    VARIETY: 'Variety',
    ESTIMATED_GROUP: 'EstimateGroup',
    PERCENTAGE: 'Percentage',
    CREATED_BY: 'CreatedBy',
    CREATED_DATE_TIME: 'CreatedDateTime',
    ALL: 'All',
    NONE: 'NONE',
    BLACK_BERRY_TYPE: 'BLACK',
    Q1: 'Q1',
    Q2: 'Q2',
    Q1_AND_Q2: 'Q1&Q2',
    VARIETY_DESCRIPTION: 'VarietyDescription',
    BYPASS: 'Bypass',
    ADD_QUARTILE: 'Add Quartile',
    EDIT_QUARTILE: 'Edit Quartile',
    CANCEL: 'Cancel',
    SUBMIT: 'Submit',
    UPDATE: 'Update',
    PERCENTAGE_OPTIONS: ['Q1', 'Q1&Q2'],
    PERCENTAGE_OPTIONS_WITH_NONE: ['None', 'Q1', 'Q1&Q2'],
    SELECTED_VARIETY: 'selectedVariety',
    SELECTED_ESTIMATE_GROUP: 'selectedEstimateGroup',
    SELECTED_PERCENTAGE: 'selectedPercentage',
    SELECTED_CREATED_BY: 'selectedCreatedBy',
    SELECTED_CREATED_DATE: 'selectedCreatedDate',
    NO_WAREHOUSES_ENANLED_MESSAGE: 'No warehouses are enabled for the selected berry type.'
};
