import { yup } from '@driscollsinc/style-guide-web2.0';

export const BypassLocationSchema = yup.object().shape({
    BerryType: yup.string().required('Berry Type is required'),
    WarehouseNbr: yup.array().min(1, 'Warehouse is required')
});

export const BypassQuartileSchema = yup.object().shape({
    BerryType: yup.string().required('Berry Type is required'),
    WarehouseNbr: yup.string().required('Warehouse is required'),
    Variety: yup.string().required('Variety is required'),
    EstimateGroup: yup.string().required('Growing Method is required'),
    Percentage: yup.string().required('Percentage is required')
});

export const BypassOverrideSchema = yup.object().shape({
    WarehouseNbr: yup.string().required('Warehouse is required'),
    RanchNbr: yup.string().required('Ranch is required'),
    StartDate: yup
        .date()
        .required('Start Date is required')
        .test('is-less-or-equal', 'Start date must be less than or equal to end date', function (value) {
            const { EndDate } = this.parent;
            return value && EndDate ? value <= EndDate : true;
        }),
    EndDate: yup
        .date()
        .required('End Date is required')
        .test('is-greater-or-equal', 'End date must be greater than or equal start date', function (value) {
            const { StartDate } = this.parent;
            return StartDate && value ? value >= StartDate : true;
        })
});
