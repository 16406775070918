import axios, { AxiosRequestConfig, Method } from 'axios';
import useGetApiTokens from './useGetApiTokens';
import { DependencyList, useEffect, useState } from 'react';

const DEMEA = 'DEMEA';

export interface IHttpResponse<T> {
    getData: () => Promise<any>;
    putData: (payload: T, id: string) => Promise<any>;
    postData: (payload: T) => Promise<any>;
    deleteData: (id: string) => Promise<any>;
}

export interface IEagerHttpResponse<T> {
    data: T | null;
    error: string;
    loading: boolean;
}

const axiosInstance = axios.create({
    baseURL: window.config.API_BASE_ADDRESS
});

export const useHttp = <T>(url: string): IHttpResponse<T> => {
    const { getAuthToken } = useGetApiTokens();

    const request = async (method: Method, payload?: any, path?: string | null): Promise<any> => {
        const config: AxiosRequestConfig = {
            headers: {
                Accept: '*/*',
                Source: DEMEA,
                'Content-Type': 'application/json',
                Authorization: getAuthToken()
            },
            data: method === 'POST' || method === 'PUT' ? payload : undefined
        };

        return axiosInstance.request<T>({
            url: path ? `${url}/${path}` : url,
            method,
            ...config
        });
    };

    const getData = async () => {
        const response = await request('GET');
        return response.data;
    };

    const putData = async (payload: T) => {
        return request('PUT', payload);
    };

    const postData = async (payload: T) => {
        return request('POST', payload);
    };

    const deleteData = async (id: string): Promise<any> => {
        return request('DELETE', undefined, id);
    };

    return {
        getData,
        putData,
        postData,
        deleteData
    };
};

export const useEagerHttp = <T>(url: string, defaultValue?: T, dependencies?: DependencyList): IEagerHttpResponse<T> => {
    const [data, setData] = useState<T | null>(defaultValue);
    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const { getData } = useHttp(url);

    const fetchData = async () => {
        try {
            setLoading(true);
            const response: any = await getData();
            setData(response);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, dependencies ?? []);

    return { data, error, loading };
};
