import React, { useState, useEffect } from 'react';
import { Middleware } from 'one-ring';
import { connect } from 'react-redux';
import APIEndPoints from '../../services/api';
import { DrcPageDataMaintenance, DrcTooltip, DrcPanel, DrcSelect, DrcButton, DrcDialog } from 'driscolls-react-components';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { getDistrictGroupMaintenancePayload } from '../../util/utils';
import { DuDateUtilities } from 'driscolls-react-utilities';
import {
    DISTRICT_GROUP,
    GROWING_DISTRICT,
    DISTRICT_GROUP_PLACEHOLDER,
    PRODUCING_AREA,
    PRODUCING_AREA_PLACEHOLDER,
    STATUS,
    MODIFIED_BY,
    MODIFIED_DATE_TIME,
    CREATED_BY,
    CREATED_DATE_TIME,
    DISTRICTGROUP_MAPPING_ADDED,
    DISTRICTGROUP_MAPPING_DELETED
} from '../../language/english';
import { withAuth } from '@driscollsinc/login-module-ui';
import { EventConstants, logEvent } from '../../../utils/analytics';

const PAGE_TITLE = 'District Group - Producing Area Mapping';
const PAGE_TYPE = 'districtGroupMaintenance';

const styles = (theme) => ({
    main: {
        margin: 0,
        paddingBottom: '0px !important'
    },
    grid: {
        '& .selected > div': {
            backgroundColor: '#cfc3deba !important'
        },
        '& .nonSelected > div': {
            backgroundColor: '#d9d0e4 !important'
        },
        '& .react-grid-Toolbar .btn:after': {
            background: 'none !important',
            backgroundColor: '#6f5091 !important',
            height: '27px',
            color: 'white'
        },
        '& .react-grid-Header': {
            background: 'none !important',
            backgroundColor: '#6f5091 !important'
        }
    },
    success: {
        color: theme.palette.primary.main
    },
    gridContainer: {
        paddingLeft: '24px'
    },
    layout: {
        width: '97%'
    },
    maintenance: {
        '& .MuiDialog-paperWidthSm': {
            maxWidth: '600px',
            width: '100%'
        },
        '& .p-datatable-thead': {
            background: 'none !important',
            backgroundColor: '#6f5091 !important',
            color: '#fff'
        },
        '& .p-datatable-row': {
            fontWeight: 600
        }
    },
    wrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        '&>div': {
            width: '48%'
        }
    },
    dialog: {
        '& .MuiDialog-paper': {
            maxWidth: '800px',
            width: '100%'
        }
    }
});

const producingAreaFormatter = (value) => {
    return (
        <DrcTooltip tipText={value ? value : ''}>
            <span>{value ? value : ''}</span>
        </DrcTooltip>
    );
};

const dateTimeFormatter = (value) => {
    return (
        <DrcTooltip tipText={value ? DuDateUtilities.ToPrettyDateTime(value) : ''}>
            <span>{value ? DuDateUtilities.ToPrettyDateTime(value) : ''}</span>
        </DrcTooltip>
    );
};

const cellFormatter = (value) => {
    return (
        <DrcTooltip tipText={value || ''}>
            <span>{value || ''}</span>
        </DrcTooltip>
    );
};

function DistrictGroup(props) {
    const { classes } = props;
    const [messageDialog, setMessageDialog] = useState({
        showMessage: false,
        message: '',
        severity: 'success'
    });
    const [districtGroup, setDistrictGroup] = useState({});
    const [districtGroupOptions, setDistrictGroupOptions] = useState([]);
    const [data, setData] = useState([]);
    const [selectedProducingArea, setSelectedProducingArea] = useState([]);
    const [producingAreas, setProducingAreas] = useState([]);
    const [showDialog, setDialog] = useState(false);
    const columns = [
        {
            key: 'QMPDistrictGroup',
            name: <span>{DISTRICT_GROUP}</span>,
            inputType: 'Select',
            defaultValue: '',
            isCreatable: true,
            selectOptions: districtGroupOptions,
            isRequired: true,
            filter: true,
            columnTemplate: (row) => cellFormatter(row.QMPDistrictGroup)
        },
        {
            key: 'GrowingDistrictName',
            name: <span>{GROWING_DISTRICT}</span>,
            isDisabled: true,
            isHidden: true,
            filter: true,
            columnTemplate: (row) => cellFormatter(row.GrowingDistrictName)
        },
        {
            key: 'ProducingAreaCode',
            name: <span>{PRODUCING_AREA}</span>,
            shortName: 'Producing Area',
            inputType: 'Select',
            defaultValue: '',
            selectOptions: producingAreas,
            isRequired: true,
            filter: true,
            width: '225px',
            columnTemplate: (row) => producingAreaFormatter(row.ProducingAreaCode + '-' + row.ProducingAreaDescription)
        },
        {
            key: 'Active',
            name: <span>{STATUS}</span>,
            isDisabled: true,
            isHidden: true,
            filter: true,
            width: '50px',
            columnTemplate: (row) => cellFormatter(row.Active)
        },
        {
            key: 'CreatedBy',
            name: <span>{CREATED_BY}</span>,
            isDisabled: true,
            isHidden: true,
            filter: true,
            columnTemplate: (row) => cellFormatter(row.CreatedBy)
        },
        {
            key: 'CreatedDateTime',
            name: <span>{CREATED_DATE_TIME}</span>,
            isDisabled: true,
            isHidden: true,
            filter: true,
            filterElement: 'datePicker',
            columnTemplate: (row) => dateTimeFormatter(row.CreatedDateTime)
        },
        {
            key: 'ModifiedBy',
            name: <span>{MODIFIED_BY}</span>,
            isDisabled: true,
            isHidden: true,
            filter: true,
            columnTemplate: (row) => cellFormatter(row.ModifiedBy)
        },
        {
            key: 'ModifiedDateTime',
            name: <span>{MODIFIED_DATE_TIME}</span>,
            isDisabled: true,
            isHidden: true,
            filter: true,
            filterElement: 'datePicker',
            columnTemplate: (row) => dateTimeFormatter(row.ModifiedDateTime)
        }
    ];
    const getData = () => {
        props.auth.getAccessToken().then((token) => {
            Middleware.Send(
                'districtProduingAreaMapping',
                token,
                `${APIEndPoints.DISTRICT_GROUPS}?screenname=districtgroup`,
                'GET',
                { Type: 'districtProduingAreaMapping' },
                null
            )
                .then((resp) => {
                    if (resp.Data.length) {
                        let i = 0;
                        let producingAreaMapping = resp.Data.map((statusData) => {
                            statusData.Active = statusData.Active ? 'Active' : 'Inactive';
                            statusData.key = i++;
                            return statusData;
                        });
                        setData(producingAreaMapping);
                    }
                })
                .catch((err) => {
                    let message = err.data.response.data.message;
                    setMessageDialog({
                        showMessage: true,
                        message: message,
                        severity: 'error'
                    });
                });
            Middleware.Send('districtGroups', token, APIEndPoints.DISTRICT_GROUPS, 'GET', { Type: 'districtGroups' }, null)
                .then((res) => {
                    if (res.Data.length) {
                        let producingArea = [...props.producingArea];
                        let districtGroupOption = res.Data.map((group) => {
                            producingArea = producingArea.filter((pa) => !group.ProducingAreaCode.split(',').includes(pa.value));
                            return { value: group.ProducingAreaCode, label: group.QMPDistrictGroup, ...group };
                        });
                        setDistrictGroupOptions(districtGroupOption);
                        setProducingAreas(producingArea);
                    }
                })
                .catch((err) => {
                    let message = err.data.response.data.message;
                    setMessageDialog({
                        showMessage: true,
                        message: message,
                        severity: 'error'
                    });
                });
        });
    };
    useEffect(() => {
        logEvent(EventConstants.PAGE_VIEW, { page_title : `${EventConstants.DOTA} - ${EventConstants.DISTRICT_GROUP}`});
        getData();
    }, []);

    const handleDistrictGroupChange = (optn) => {
        if (!optn) return setDistrictGroup({});
        setDistrictGroup(optn);
    };
    const handleOnChangeOfProducingArea = (option) => {
        if (!option) return setSelectedProducingArea([]);
        setSelectedProducingArea(option);
    };
    const handleClose = () => {
        setMessageDialog({ showMessage: false });
    };
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const onDelete = (deletedRow) => {
        logEvent(EventConstants.DELETE_ACTION, {event_info: `${EventConstants.DOTA} - ${EventConstants.DELETE} ${EventConstants.DISTRICT_GROUP}`});
        let payload = {
            QMPDistrictGroup: deletedRow.QMPDistrictGroup,
            GrowingDistrictName: deletedRow.GrowingDistrictName,
            ProducingAreaCode: deletedRow.ProducingAreaCode,
            ProducingAreaDescription: deletedRow.ProducingAreaDescription,
            Active: false,
            CreatedBy: deletedRow.CreatedBy,
            CreatedDateTime: deletedRow.CreatedDateTime,
            ModifiedBy: props.loggedInUser,
            ModifiedDateTime: new Date().toISOString()
        };
        props.auth.getAccessToken().then((token) => {
            Middleware.Send(PAGE_TYPE, token, `${APIEndPoints.DISTRICT_GROUPS}`, 'POST', payload, {
                overrideRequestMapping: true,
                loadingMessage: 'Deleting ...'
            })
                .then(() => {
                    setMessageDialog({
                        showMessage: true,
                        message: DISTRICTGROUP_MAPPING_DELETED,
                        severity: 'success'
                    });
                    getData();
                    setDistrictGroup({});
                    setSelectedProducingArea([]);
                })
                .catch((error) => {
                    let message = error.data.response.data.message;
                    setDistrictGroup({});
                    setSelectedProducingArea([]);
                    setMessageDialog({
                        showMessage: true,
                        message: message,
                        severity: 'error'
                    });
                });
        });
    };
    const handleAdd = () => {
        setDialog(true);
        logEvent(EventConstants.ADD_ACTION, {event_info: `${EventConstants.DOTA} - ${EventConstants.ADD} ${EventConstants.DISTRICT_GROUP}`});
    };
    const handleSave = () => {
        props.auth.getAccessToken().then((token) => {
            let apiCalls = [];

            selectedProducingArea.forEach((producingArea) => {
                let middlewareCall = Middleware.CreateSendCall(
                    PAGE_TYPE,
                    token,
                    `${APIEndPoints.DISTRICT_GROUPS}`,
                    'POST',
                    getDistrictGroupMaintenancePayload(districtGroup, producingArea, props.loggedInUser),
                    {
                        overrideRequestMapping: true,
                        loadingMessage: 'Adding new district group...',
                        overrideResponseMapping: true
                    }
                );

                apiCalls.push(
                    Middleware.AttachReturnFunction(middlewareCall, 0, () => {
                        console.log('done');
                    })
                );
            });

            Middleware.SendMultiple(PAGE_TYPE, apiCalls, () => {})
                .then(() => {
                    setMessageDialog({
                        showMessage: true,
                        message: DISTRICTGROUP_MAPPING_ADDED,
                        severity: 'success'
                    });
                    getData();
                    setDistrictGroup({});
                    setSelectedProducingArea([]);
                })
                .catch((error) => {
                    let message = error.data.response.data.message;
                    setMessageDialog({
                        showMessage: true,
                        message: message,
                        severity: 'error'
                    });
                    setDistrictGroup({});
                    setSelectedProducingArea([]);
                });
        });
        setDialog(false);
    };
    const handleCancel = () => {
        setDistrictGroup({});
        setSelectedProducingArea([]);
        setDialog(false);
    };
    return (
        <DrcPanel
            style={{ maxWidth: '100%', border: 'none', boxShadow: '0 2px 4px 0 #C3C3C3', marginBottom: '0px' }}
            className={props.classes.layout}
        >
            <DrcPageDataMaintenance
                className={classes.maintenance}
                columns={columns}
                classes={classes}
                data={data}
                onDelete={onDelete}
                settings={{
                    EnableExport: false,
                    EnableAdd: props.userGroup === 'Admin' ? true : false,
                    EnableDelete: props.userGroup === 'Admin' ? true : false,
                    EnableEdit: false,
                    OverrideAdd: handleAdd
                }}
                textOptions={{ AddBtn: 'Add New' }}
                type={PAGE_TITLE}
                resultCount={data.length}
            />
            <Snackbar open={messageDialog.showMessage} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={messageDialog.severity}>
                    {messageDialog.message}
                </Alert>
            </Snackbar>
            {showDialog && (
                <DrcDialog
                    className={classes.dialog}
                    open={showDialog}
                    buttons={
                        <React.Fragment>
                            <DrcButton isSecondary floatRight onClick={handleCancel}>
                                Cancel
                            </DrcButton>
                            <DrcButton isPrimary floatRight onClick={handleSave} disabled={!districtGroup.value || !selectedProducingArea.length}>
                                Save
                            </DrcButton>
                        </React.Fragment>
                    }
                    title="Add New District Group - Mapping"
                >
                    <div className={classes.wrapper}>
                        <DrcSelect
                            isClearable={true}
                            label={DISTRICT_GROUP}
                            isCreatable
                            fullWidth
                            options={districtGroupOptions}
                            value={districtGroup}
                            placeholder={DISTRICT_GROUP_PLACEHOLDER}
                            onChange={handleDistrictGroupChange}
                        />
                        <DrcSelect
                            isClearable={true}
                            closeMenuOnSelect={false}
                            label={PRODUCING_AREA}
                            options={producingAreas}
                            value={selectedProducingArea}
                            placeholder={PRODUCING_AREA_PLACEHOLDER}
                            onChange={handleOnChangeOfProducingArea}
                            isMulti
                        />
                    </div>
                </DrcDialog>
            )}
        </DrcPanel>
    );
}
function mapStateToProps(state) {
    return {
        pageTitle: state.rootReducer.pageTitle,
        producingArea: state.overrideReducer.producingArea,
        userGroup: state.masterReducer.userGroup,
        loggedInUser: state.masterReducer.loggedInUser,
        managerMaintenanceGrid: state.managerMaintenanceReducer.managerMaintenanceGrid
    };
}

export default connect(mapStateToProps, null)(withAuth(withStyles(styles)(DistrictGroup)));
