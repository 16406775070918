import { mapOptions } from '../../utils/format';
import { POINT_MATRIX_SET_CONSTANTS } from '../constants/pointMatrixConstants';

export const useGetStaticData = () => {
    const getCommodityList = () => [...JSON.parse(window.config.BERRY_TYPE)].sort();

    const getDistrictGroupList = () => {
        return [...JSON.parse(window.config.COUNTRY_OF_ORIGIN)].sort((a, b) => {
            if (a.Description < b.Description) {
                return -1;
            }
            if (a.Description > b.Description) {
                return 1;
            }
            return 0;
        });
    };

    const getDistrictGroupDescription = (districtCode: string) => {
        const districtGroupList = getDistrictGroupList();
        const foundDistrictGroupItem = districtGroupList.find((item) => {
            return item[POINT_MATRIX_SET_CONSTANTS.INTRASTAT_CODE] === districtCode;
        });
        return foundDistrictGroupItem ? foundDistrictGroupItem[POINT_MATRIX_SET_CONSTANTS.DESCRIPTION] : districtCode;
    };

    const getDistrictGroupCode = (description: string) => {
        const districtGroupList = getDistrictGroupList();
        const foundDistrictGroupItem = districtGroupList.find((item) => {
            return item[POINT_MATRIX_SET_CONSTANTS.DESCRIPTION] === description;
        });
        return foundDistrictGroupItem ? foundDistrictGroupItem[POINT_MATRIX_SET_CONSTANTS.INTRASTAT_CODE] : description;
    };

    const getEstimateGroupList = () => [...JSON.parse(window.config.GROWING_METHOD)].sort();

    const getVarietyDefaultOption = () => [{ CommodityCode: 'All', VarietyCode: 'Default', VarietyDescription: 'Default' }];
    const getVarietyAllOption = () => [{ CommodityCode: 'All', VarietyCode: 'All', VarietyDescription: 'All' }];

    const getVarietyList = () => {
        const varietyList = JSON.parse(window.config.VARIETY);
        const sortedVarietyList = varietyList.sort((a, b) => {
            if (a.VarietyDescription < b.VarietyDescription) {
                return -1;
            }
            if (a.VarietyDescription > b.VarietyDescription) {
                return 1;
            }
            return 0;
        });

        return sortedVarietyList;
    };

    const getVarietyListWithDefaultOption = () => [...getVarietyDefaultOption(), ...getVarietyList()];
    const getVarietyWithAllOption = () => [...getVarietyAllOption(), ...getVarietyList()];

    const getVarietyListFilteredByCommodity = (commodityCode: string, showAll: boolean) => {
        let varietyList;
        if (showAll) {
            varietyList = getVarietyWithAllOption();
        } else {
            varietyList = getVarietyListWithDefaultOption();
        }
        const filteredVarietyList = varietyList.filter((item) => item.CommodityCode === commodityCode || item.CommodityCode === 'All');
        return filteredVarietyList;
    };

    const getDistrictGroupDescriptionList = () => mapOptions(getDistrictGroupList(), POINT_MATRIX_SET_CONSTANTS.DESCRIPTION);

    const getVarietyDescriptionList = () => mapOptions(getVarietyList(), POINT_MATRIX_SET_CONSTANTS.VARIETY_DESCRIPTION);

    const setupFilterOptions = () => {
        const commodityCode = getCommodityList();
        const districtGroup = getDistrictGroupDescriptionList();
        const estimateGroup = getEstimateGroupList();
        const variety = getVarietyDescriptionList();
        const status = [POINT_MATRIX_SET_CONSTANTS.ACTIVE, POINT_MATRIX_SET_CONSTANTS.INACTIVE];

        return {
            commodityCode,
            districtGroup,
            estimateGroup,
            variety,
            status
        };
    };

    const transformData = (obj, mapping) => {
        return Object.entries(obj).reduce((acc, [key, value]) => {
            const newKey = mapping[key] || key; // Use mapped key or fallback to the original key
            acc[newKey] = Array.isArray(value)
                ? value.map((item) => (typeof item === 'object' ? transformData(item, mapping) : item))
                : typeof value === 'object' && value !== null
                ? transformData(value, mapping)
                : value;
            return acc;
        }, {});
    };

    return {
        transformData,
        getVarietyList,
        getCommodityList,
        setupFilterOptions,
        getEstimateGroupList,
        getDistrictGroupList,
        getDistrictGroupCode,
        getVarietyDefaultOption,
        getVarietyDescriptionList,
        getDistrictGroupDescription,
        getVarietyListWithDefaultOption,
        getDistrictGroupDescriptionList,
        getVarietyListFilteredByCommodity
    };
};
