import {
    FaIcons,
    IconSize,
    DriscollsTypographyTranslated,
    DriscollsMenu,
    DriscollsMenuItem,
    DriscollsTypographyEnum,
    DriscollsTypographyStyles
} from '@driscollsinc/style-guide-web2.0';
import style from './index.module.scss';
import { NAMESPACE } from '../../../i18n';
import { useNavigate } from 'react-router-dom';
import { routes, routesName } from '../../routes/routes';
import { BusinessUnitEnum } from '../../types/common';

function BusinessUnit() {
    const navigate = useNavigate();

    const currentBusinessUnit = BusinessUnitEnum.DEMEA;
    const businessUnits = [BusinessUnitEnum.DEMEA, BusinessUnitEnum.DOTA];

    const onBusinessUnitChange = (bu: BusinessUnitEnum) => {
        bu === BusinessUnitEnum.DOTA ? navigate(routes[routesName.DOTA_BYPASS_LOCATION].path) : false;
    };

    return (
        <DriscollsMenu className={style.menuIcon} labelIcon={<FaIcons.FaLocationDot size={IconSize.SMALL} />}>
            {businessUnits.map((bu) => {
                return (
                    <>
                        <DriscollsMenuItem
                            value={bu}
                            onClick={() => onBusinessUnitChange(bu)}
                            checked={currentBusinessUnit.startsWith(bu)}
                            type="checkbox"
                        >
                            <DriscollsTypographyTranslated
                                type={DriscollsTypographyEnum.H6}
                                typographyStyle={DriscollsTypographyStyles.SUBTITLE5}
                                nameSpacing={NAMESPACE.COMMON}
                            >
                                {Object.keys(BusinessUnitEnum)[Object.values(BusinessUnitEnum).indexOf(bu)]}
                            </DriscollsTypographyTranslated>
                        </DriscollsMenuItem>
                    </>
                );
            })}
        </DriscollsMenu>
    );
}

export default BusinessUnit;
