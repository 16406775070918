import {
    ApplicationPageTitle,
    ApplicationPage,
    DriscollsVContainer,
    DriscollsHContainer,
    DriscollsButton,
    DriscollsTranslatedValue,
    ButtonVariant
} from '@driscollsinc/style-guide-web2.0';
import { NAMESPACE } from '../../../i18n';
import PageContainer from '../../components/container/PageContainer';
import styles from './index.module.scss';
import BypassQuartileGrid from '../../components/bypass/BypassQuartileGrid';
import BerryTypesSelect from '../../components/pointMatrix/berryType';
import useBypassQuartile from './useBypassQuartile';
import { DrcLoading } from '../../../legacy/DriscollsReactComponents';
import NoDataCard from '../../components/noDataCard';
import { BYPASS_QUARTILE_CONSTANTS } from '../../constants/bypassQuartile';

const BypassQuartile = () => {
    const {
        data,
        page,
        setPage,
        itemsPerPage,
        loading,
        filterOptions,
        showTable,
        defaultValues,
        onDelete,
        onEdit,
        goBack,
        goToAddBypassQuartile,
        onFilterChange,
        handleBerryTypeChange,
        warehouseLoading
    } = useBypassQuartile();

    if ((loading || warehouseLoading) && !showTable) {
        return <DrcLoading />;
    }

    return (
        <PageContainer>
            <ApplicationPage className={styles.bypassPage}>
                <ApplicationPageTitle
                    title={{ text: 'Bypass', nameSpace: NAMESPACE.BYPASS }}
                    description={{ text: 'Quartile', nameSpace: NAMESPACE.BYPASS }}
                />
                <DriscollsVContainer noPadding>
                    <DriscollsHContainer className={styles.buttonSelectWrapper}>
                        <BerryTypesSelect
                            isClearable={false}
                            defaultValue={defaultValues.BerryType}
                            styles={styles}
                            isCompact={true}
                            onChange={handleBerryTypeChange}
                        />
                        <DriscollsHContainer>
                            <DriscollsButton
                                className={styles.btnLink}
                                label={<DriscollsTranslatedValue value="Back" nameSpacing={NAMESPACE.BYPASS} />}
                                onClick={goBack}
                                variant={ButtonVariant.SECONDARY}
                            />
                            <DriscollsButton
                                className={styles.btnLink}
                                label={<DriscollsTranslatedValue value="Add" nameSpacing={NAMESPACE.BYPASS} />}
                                onClick={goToAddBypassQuartile}
                            />
                        </DriscollsHContainer>
                    </DriscollsHContainer>
                </DriscollsVContainer>
                {showTable && (
                    <BypassQuartileGrid
                        loading={loading || warehouseLoading}
                        data={data?.Data || []}
                        selectedPage={page}
                        totalItem={Number(data?.TotalResults) || 0}
                        onPageChanged={(page) => setPage(page)}
                        itemPerPage={itemsPerPage}
                        filterOptions={filterOptions}
                        onDelete={(row: any) => {
                            onDelete(row);
                        }}
                        onFilterChange={onFilterChange}
                        onEdit={(row) => onEdit(row)}
                    />
                )}
                {
                    <NoDataCard
                        showCard={!showTable}
                        message={BYPASS_QUARTILE_CONSTANTS.NO_WAREHOUSES_ENANLED_MESSAGE}
                        nameSpacing={NAMESPACE.BYPASS}
                    />
                }
            </ApplicationPage>
        </PageContainer>
    );
};

export default BypassQuartile;
