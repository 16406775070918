import {
    ApplicationPageTitle,
    ApplicationPage,
    DriscollsVContainer,
    DriscollsHContainer,
    DriscollsButton,
    ButtonVariant,
    DriscollsTranslatedValue
} from '@driscollsinc/style-guide-web2.0';
import { NAMESPACE } from '../../../i18n';
import PageContainer from '../../components/container/PageContainer';
import styles from './index.module.scss';
import BypassOverrideGrid from '../../components/bypass/BypassOverrideGrid';
import useBypassOverride from './useBypassOverride';

const BypassOverride = () => {
    const { onEdit, goToAddOverride, onDelete, onFilterChange, loading, data, setPage, page, itemsPerPage, filterOptions, goBack } =
        useBypassOverride();

    return (
        <PageContainer>
            <ApplicationPage className={styles.bypassPage}>
                <ApplicationPageTitle
                    title={{ text: 'Bypass', nameSpace: NAMESPACE.BYPASS }}
                    description={{ text: 'Override', nameSpace: NAMESPACE.BYPASS }}
                />
                <DriscollsVContainer noPadding>
                    <DriscollsHContainer className={styles.rightAlign} noPadding>
                        <DriscollsButton
                            className={styles.btnLink}
                            label={<DriscollsTranslatedValue value="Back" nameSpacing={NAMESPACE.BYPASS} />}
                            onClick={goBack}
                            variant={ButtonVariant.SECONDARY}
                        />
                        <DriscollsButton
                            className={styles.btnLink}
                            label={<DriscollsTranslatedValue value="Add" nameSpacing={NAMESPACE.BYPASS} />}
                            onClick={goToAddOverride}
                        />
                    </DriscollsHContainer>
                </DriscollsVContainer>
                <BypassOverrideGrid
                    loading={loading}
                    data={data?.Data || []}
                    selectedPage={page}
                    totalItem={Number(data?.TotalResults) || 0}
                    onPageChanged={(page) => setPage(page)}
                    itemPerPage={itemsPerPage}
                    filterOptions={filterOptions}
                    onDelete={(row: any) => {
                        onDelete(row);
                    }}
                    onFilterChange={onFilterChange}
                    onEdit={(row) => onEdit(row)}
                />
            </ApplicationPage>
        </PageContainer>
    );
};

export default BypassOverride;
