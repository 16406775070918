import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import DrcMain from '../Components/DrcMain';
import DrcPanel from '../Components/DrcPanel';
import Warning from '@material-ui/icons/Warning';
import { NavLink } from 'react-router-dom';


const iconStyle = {
    fontSize: '4rem'
};

const labelStyle = {
    display: 'inline-block',
    margin: '20px 20px 0 20px',
    position: 'relative',
    top: '-15px'
};

class DrcPageNotFound extends Component {
    render() {
        return (
            <DrcMain transparent>
                <Helmet>
                    <title>Not Found</title>
                </Helmet>
                <DrcPanel error maxWidth="400px" style={{ textAlign: 'center' }}>
                    <Warning style={iconStyle} />
                    <h1 style={labelStyle}>404 Page not found</h1>
                    <Warning style={iconStyle} />
                    <p>
                        Please go back to the <NavLink to={'/'}>home</NavLink> page and try again.
                    </p>
                </DrcPanel>
            </DrcMain>
        );
    }
}

export default DrcPageNotFound;
