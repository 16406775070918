import {
    DirectionEnum,
    DriscollsComponentSwitcher,
    DriscollsDataTable,
    DriscollsTrashButton,
    DriscollsEditButton,
    DriscollsMobileList,
    useLang
} from '@driscollsinc/style-guide-web2.0';
import { DuDateUtilities } from '../../../../legacy/DriscollsReactUtilities';

import { KEY_MAPPING, USER_MANAGEMENT_CONSTANTS } from '../../../constants/userManagementConstants';
import { NAMESPACE } from '../../../../i18n';
import { useGetStaticData } from '../../../hooks/useGetStaticData';
import { BYPASS_QUARTILE_CONSTANTS } from '../../../constants/bypassQuartile';
import { BypassLocationData } from '../../../pages/Bypass/Types';

export interface IBypassQuartileGridFilterOptions {
    warehouse: string[];
    variety: string[];
    estimateGroup: string[];
    percentage: string[];
}

interface IBypassQuartileGrid {
    loading: boolean;
    data: BypassLocationData[];
    totalItem: number;
    itemPerPage: number;
    onPageChanged: (pageNumber: number) => void;
    selectedPage: number;
    filterOptions?: IBypassQuartileGridFilterOptions;
    onFilterChange: (filters: { id: string; value: string }[]) => void;
    onDelete: (row: any) => void;
    onEdit: (id: string) => void;
}

const BypassQuartileGrid = (props: IBypassQuartileGrid) => {
    const { loading, data, totalItem, itemPerPage, onPageChanged, selectedPage, filterOptions, onFilterChange, onDelete, onEdit } = props;
    const { getTextTranslated } = useLang([NAMESPACE.BYPASS]);
    const { transformData } = useGetStaticData();
    const formatData = (data: BypassLocationData[]) =>
        data.map((row) => ({
            ...row,
            [BYPASS_QUARTILE_CONSTANTS.CREATED_DATE_TIME]: DuDateUtilities.ToPrettyDateTime(row[BYPASS_QUARTILE_CONSTANTS.CREATED_DATE_TIME])
        }));

    const columns = [
        {
            id: 'action',
            size: 90,
            enablePinning: true,
            header: () => null,
            cell: ({ row }: any) => {
                return (
                    <>
                        <DriscollsEditButton onClick={() => onEdit(row.original)} />
                        <DriscollsTrashButton onClick={() => onDelete(row.original)} />
                    </>
                );
            }
        },
        {
            id: BYPASS_QUARTILE_CONSTANTS.BERRY_TYPE,
            accessorKey: BYPASS_QUARTILE_CONSTANTS.BERRY_TYPE,
            header: getTextTranslated('Berry Type', NAMESPACE.COMMON),
            enableColumnFilter: false,
            enablePinning: false
        },
        {
            id: BYPASS_QUARTILE_CONSTANTS.WAREHOUSE_NBR,
            accessorKey: BYPASS_QUARTILE_CONSTANTS.WAREHOUSE_NBR,
            header: getTextTranslated('Warehouse', NAMESPACE.COMMON),
            enableColumnFilter: true,
            enablePinning: false,
            selectFilterOptions: filterOptions?.warehouse
        },
        {
            id: BYPASS_QUARTILE_CONSTANTS.VARIETY,
            accessorKey: BYPASS_QUARTILE_CONSTANTS.VARIETY,
            header: getTextTranslated('Variety', NAMESPACE.COMMON),
            enableColumnFilter: true,
            enablePinning: false,
            selectFilterOptions: filterOptions?.variety
        },
        {
            id: BYPASS_QUARTILE_CONSTANTS.ESTIMATED_GROUP,
            accessorKey: BYPASS_QUARTILE_CONSTANTS.ESTIMATED_GROUP,
            header: getTextTranslated('Growing Method', NAMESPACE.COMMON),
            enableColumnFilter: true,
            enablePinning: false,
            selectFilterOptions: filterOptions?.estimateGroup
        },
        {
            id: BYPASS_QUARTILE_CONSTANTS.PERCENTAGE,
            accessorKey: BYPASS_QUARTILE_CONSTANTS.PERCENTAGE,
            header: getTextTranslated('Percentage', NAMESPACE.COMMON),
            enableColumnFilter: true,
            enablePinning: false,
            selectFilterOptions: filterOptions?.percentage
        },
        {
            id: BYPASS_QUARTILE_CONSTANTS.CREATED_BY,
            accessorKey: BYPASS_QUARTILE_CONSTANTS.CREATED_BY,
            header: getTextTranslated('Created By', NAMESPACE.COMMON),
            enableColumnFilter: true,
            enablePinning: false
        },
        {
            id: BYPASS_QUARTILE_CONSTANTS.CREATED_DATE_TIME,
            accessorKey: BYPASS_QUARTILE_CONSTANTS.CREATED_DATE_TIME,
            header: getTextTranslated('Created Date', NAMESPACE.COMMON),
            enableColumnFilter: true,
            enablePinning: false,
            dateFormatForFilter: 'MMM-dd-yyyy'
        }
    ];

    const mobileListData = (data) => {
        const formattedData = formatData(data);
        const transformedData = formattedData.map((item) => transformData(item, KEY_MAPPING));
        return transformedData;
    };

    return (
        <DriscollsComponentSwitcher
            direction={DirectionEnum.width}
            componentList={[
                {
                    breakPoint: 799,
                    component: (
                        <DriscollsMobileList
                            isLoading={loading}
                            data={mobileListData(data)}
                            selectedPage={selectedPage}
                            onPageChange={onPageChanged}
                            itemsPerPage={itemPerPage}
                            totalItems={totalItem}
                            hideKeys={[USER_MANAGEMENT_CONSTANTS.MODIFIED_BY, USER_MANAGEMENT_CONSTANTS.MODIFIED_DATE_TIME]}
                            paginationText={`Total No of Records: ${totalItem}`}
                        />
                    )
                },
                {
                    breakPoint: 800,
                    component: (
                        <DriscollsDataTable
                            isLoading={loading}
                            manualPagination={true}
                            selectedPage={selectedPage}
                            columns={columns}
                            showHover={false}
                            data={formatData(data)}
                            onPageChange={onPageChanged}
                            itemsPerPage={itemPerPage}
                            totalItems={totalItem}
                            textFilterPlaceholder={false}
                            enableDragAndDrop={false}
                            showColumnVisibility={false}
                            paginationText={`Total No of Records: ${totalItem}`}
                            showDownloadButton={false}
                            noRowMessage={'No records available'}
                            enableServerSideFiltering={true}
                            onFilterChange={onFilterChange}
                        />
                    )
                }
            ]}
        />
    );
};

export default BypassQuartileGrid;
